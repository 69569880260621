import { authenticatedApiFetch } from "./FetchHelper";

export const getGithubOrgs = async () => {
    return await getGithubOrgsApiCall();
}

const getGithubOrgsApiCall = () => {
    return new Promise((res, rej) => {
        authenticatedApiFetch('api/Github/GetGitOrganization', {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            }).then((data) => {
                let githubOrgList = [];
                data.map((githubOrg) => githubOrgList.push(githubOrg.login));
                res(githubOrgList);
            });
    })
}


export const getGithubRepoByOrgs = async (org) => {
    return await getGithubRepoByOrgsApiCall(org);
}

const getGithubRepoByOrgsApiCall = (org) => {
    return new Promise((res, rej) => {
        authenticatedApiFetch('api/Github/GetGitRepoByOrg?org=' + org, {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            }).then((data) => {
                let githubRepoOrgList = [];
                data.map((githubRepoOrg) => githubRepoOrgList.push(githubRepoOrg.name));
                res(githubRepoOrgList);
            });
    })
}