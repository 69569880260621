import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import { Dropdown } from "../Dropdown";
import "../../css/RequestDetails.css"
import { ChipButton } from '../ChipButton'
import { auth } from '../../adalConfig';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class KubernetesDetailsContent extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {

            requestDetails: this.props.requestDetails,
            spnName: null,
            spnApplicationId: null,
            spnObjectId: null
        };

        this.updateText = this.updateText.bind(this);
        this.saveRequest = this.saveRequest.bind(this);

    }
    async getDevopsProjects() {
        try {
            let response = await authenticatedApiFetch('api/AzureDevOps/GetAllProjectNames', {});
            if (!response.ok) {
                throw new Error(response);
            }
            this.setState({ devopsProjects: await response.json() });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    async getKubernetesClusters() {
        try {
            let response = await authenticatedApiFetch('api/Kubernetes', {});
            if (!response.ok) {
                throw new Error(response);
            }
            this.setState({ kubernetesClusters: await response.json() });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }
    async componentDidMount() {
        this.getDevopsProjects();
        this.getKubernetesClusters();
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    saveRequest() {
        var updatedRequest = this.constructEditedRequestDetails();
        this.props.updateRequest(updatedRequest);
    }

    constructEditedRequestDetails() {
        var updatedRequestDetails = this.state.requestDetails;
        updatedRequestDetails.clusterId = this.state.cluster;
        updatedRequestDetails.clusterNamespace = this.state.namespace;
        updatedRequestDetails.devopsProject = this.state.devopsProject;
        updatedRequestDetails.requester.contactInfo.name = this.state.requesterName;
        updatedRequestDetails.requester.contactInfo.email = this.state.requesterEmail;
        updatedRequestDetails.requester.manager.contactInfo.name = this.state.requesterManagerName;
        updatedRequestDetails.requester.manager.contactInfo.email = this.state.requesterManagerEmail;
        updatedRequestDetails.requester.groupName = this.state.requesterTeamName;
        return updatedRequestDetails;
    }

    render() {
        let projectValues = [];
        let clusterValues = [];
        if(this.state && this.state.devopsProjects)
        {
            projectValues =  this.state.devopsProjects.map(project => project.name);
        }
        if(this.state && this.state.kubernetesClusters)
        {
            clusterValues = {
                texts: this.state.kubernetesClusters.map(cluster => cluster.clusterName),
                values: this.state.kubernetesClusters.map((cluster) => cluster.id),
            };
        }
        return (
            <div className="request-details-container">
                {this.props.requestDetails &&
                    <div>                        
                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Type"
                            update={this.updateText}
                            default={this.props.requestDetails.requestTypeName}
                            disabled={true}
                        />

                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Status"
                            update={this.updateText}
                            default={this.props.requestDetails.requestStatusName}
                            disabled={true}
                        />

                        <Dropdown
                            name="cluster"
                            label="Kubernetes Cluster"
                            tooltip="Kubernetes cluster to setup namespace in."
                            values={clusterValues.values}
                            texts={clusterValues.texts}
                            required="true"
                            errorMessage="Cluster is required"
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}
                            default={this.props.requestDetails.clusterId}
                        />   
                        <Textbox
                            name="namespace"
                            type="text"
                            label="Namespace"
                            tooltip="The name of the namespace. Do NOT include environment (test,qa,prod)"
                            maxlength="1000"
                            pattern="^[a-zA-Z0-9]([-_a-zA-Z0-9]*[a-zA-Z0-9])?$"
                            required="true"
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            errorMessage="The name can contain only letters, numbers, underscores and hyphens. The name must start and end with letter or number."
                            update={this.updateText}
                            default={this.props.requestDetails.clusterNamespace}
                        />
                        <Dropdown
                            name="devopsProject"
                            label="Devops Project"
                            tooltip="Azure Devops project to create environment in."
                            values={projectValues}
                            texts={projectValues}
                            required="true"
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            errorMessage="Devops project is required"
                            update={this.updateText}
                            default={this.props.requestDetails.devopsProject}
                        />
                        {this.props.requestDetails.cherwellInformation &&
                            <Textbox
                                name="cherwellTicketNumber"
                                type="text"
                                label="Cherwell Ticket Number"
                                default={this.props.requestDetails.cherwellInformation.cherwellTicketNumber}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="creationDate"
                            type="text"
                            label="Creation Date"
                            default={new Date(this.props.requestDetails.creationDate).toLocaleDateString()}
                            disabled={true}
                        />

                        {this.props.requestDetails.requestStatus === 3 &&
                            <Textbox
                                name="completionDate"
                                type="text"
                                label="Completion Date"
                                default={new Date(this.props.requestDetails.completionDate).toLocaleDateString()}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="requesterName"
                            type="text"
                            label="Requester Name"
                            default={this.props.requestDetails.requester.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}
                        />
                        <Textbox
                            name="requesterEmail"
                            type="email"
                            label="Requester Email"
                            default={this.props.requestDetails.requester.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterManagerName"
                            type="text"
                            label="Requester's Manager Name"
                            default={this.props.requestDetails.requester.manager.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}



                        />
                        <Textbox
                            name="requesterManagerEmail"
                            type="email"
                            label="Requester's Manager Email"
                            default={this.props.requestDetails.requester.manager.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterTeamName"
                            type="text"
                            label="Requester's Team Name"
                            default={this.props.requestDetails.requester.groupName}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        {this.props.editing &&
                            <ChipButton
                                buttonClassName="save-button"
                                buttonText="Save"
                                action={this.saveRequest}
                                color='btn-success'
                            />
                        }
                    </div>

                }
            </div>
        );
    }
}