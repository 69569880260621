import React, { Component } from 'react';
import EditIcon from '../../img/pencil-icon.png';
import AddIcon from '../../img/CHIPv1/AddIcon.png';
import { SubscriptionDetailsModal } from './SubscriptionDetailsModal';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class SubscriptionDetailsReport extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            emailRecipientGroupOne: [],
            emailRecipientGroupTwo: [],
            emailRecipients: this.props.recipients,
            isSubscriptionDetailModalOpen: false,
            subscriptionDetailModalType: '',
            subscriptionDetailsRequestObj: this.props.subscriptionDetailsRequestObj
        };

        this.toggleSubscriptionDetailModal = this.toggleSubscriptionDetailModal.bind(this);
        this.updateRecipientsSubmit = this.updateRecipientsSubmit.bind(this);
        this.deleteEmailsSubmit = this.deleteEmailsSubmit.bind(this);
        this.openAddEmailModal = this.openAddEmailModal.bind(this);
        this.openDeleteEmailModal = this.openDeleteEmailModal.bind(this);
    }

    toggleSubscriptionDetailModal() {
        this.setState({ isSubscriptionDetailModalOpen: !this.state.isSubscriptionDetailModalOpen });
    }

    componentDidMount() {
        this.splitRecipientsAndCreateGroups();
    }

    splitRecipientsAndCreateGroups() {
        let fullRecipientsArray = this.state.emailRecipients;
        let halfWayThough = Math.floor(fullRecipientsArray.length / 2)

        this.setState({
            emailRecipientGroupOne: fullRecipientsArray.slice(0, halfWayThough),
            emailRecipientGroupTwo: fullRecipientsArray.slice(halfWayThough, fullRecipientsArray.length)
        });
    }

    updateRecipientsInDB() {
        var updatedRequestObj = this.state.subscriptionDetailsRequestObj;
        updatedRequestObj.emails = this.state.emailRecipients;
        authenticatedApiFetch('api/Report/UpdateEmailRecipients', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedRequestObj)
        }).then((response) => {
            if (response.ok) {
                this.toggleSubscriptionDetailModal();
            } else {
                throw new Error(response);
            }
        }).catch((error) => {
            this.setState({
                submitting: false,
                error: true
            });
            this.context.handleError(error);
            throw error;
        });
    }

    updateRecipientsSubmit(newEmails) {
        var updatedRecipients = this.state.emailRecipients;
        updatedRecipients = updatedRecipients.concat(newEmails);

        this.setState({
            emailRecipients: updatedRecipients
        }, () => {
            this.splitRecipientsAndCreateGroups();
            this.updateRecipientsInDB();

        });
    }

    deleteEmailsSubmit(deletedEmails) {
        var updatedRecipients = this.state.emailRecipients;
        updatedRecipients = updatedRecipients.filter((email) => !deletedEmails.includes(email));

        this.setState({ emailRecipients: updatedRecipients }, () => {
            this.splitRecipientsAndCreateGroups();
            this.updateRecipientsInDB();
        });
    }

    openAddEmailModal() {
        this.setState({
            isSubscriptionDetailModalOpen: true,
            subscriptionDetailModalType: 'addEmails'
        });
    }

    openDeleteEmailModal() {
        this.setState({
            isSubscriptionDetailModalOpen: true,
            subscriptionDetailModalType: 'deleteEmails'
        });
    }
    render() {
        return (
            <div>
                <div className="reportTitle">
                    <h2>Subscription Details</h2>
                    <h4>{this.props.subscriptionName}</h4>
                </div>

                <div className="subscriptionWrapper">
                    <div className="wrapper-title">
                        AAD Group Name:
                </div>
                    <div className="wrapper-item">
                        {this.props.AADGroupName}
                </div>
                    <div className="wrapper-title">
                        AAD Group Owner Name:
                </div>
                    <div className="wrapper-item">
                        {this.props.AADGroupOwnerName}
                </div>
                    <div className="wrapper-title">
                        AAD Group Owner Email:
                </div>
                    <div className="wrapper-item">
                        {this.props.AADGroupOwnerEmail}
                </div>
                    <div className="wrapper-title">
                        Recipients: 
                        <div className="iconPadding" onClick={this.openAddEmailModal}>
                            <img src={AddIcon} alt="add emails"/>
                        </div>
                    </div>

                    <div id="emailListRecipients" className="container-fluid wrapper-item">
                        <div className="row">
                            <div id="emailListcol1" className="col-sm-6">
                                {this.state.emailRecipientGroupOne.map(x => (
                                    <div className="emailRecipient"> {x} </div>
                                ))}
                            </div>
                            <div id="emailListcol2" className="col-sm-6">
                                {this.state.emailRecipientGroupTwo.map(x => (
                                    <div className="emailRecipient"> {x} </div>
                                ))}
                                <div id="edit-icon" className="iconPadding" onClick={this.openDeleteEmailModal}>
                                    <img src={EditIcon} alt="delete emails" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <SubscriptionDetailsModal
                    isSubscriptionDetailModalOpen={this.state.isSubscriptionDetailModalOpen}
                    toggleSubscriptionDetailModal={this.toggleSubscriptionDetailModal}
                    recipients={this.state.emailRecipients}
                    updateRecipientsSubmit={this.updateRecipientsSubmit}
                    deleteEmailsSubmit={this.deleteEmailsSubmit}
                    subscriptionDetailModalType={this.state.subscriptionDetailModalType}
                />
            </div>
        );
    }
}