import React, { Component } from 'react';
import { ComplianceRating } from './ComplianceRating';

export class DetailsAppInfo extends Component {

    render() {

        return (
            <div>
                <div className="lgmod-group-appserviceplan">
                    <div className="row lgmod-row">
                        <div className="col-12 lgmod-section-title">
                            <span>{this.props.resourceName}</span>
                        </div>
                    </div>
                </div>
                <div className="row lgmod-row">
                    <div className="col-12 lgmod-inner-group row">

                        <div className="inline-block-div col-4">
                            <div className="lgmod-group-text lgmod-title">Resource Name:</div>
                            <div className="lgmod-group-text lgmod-title">Resource Group:</div>
                            <div className="lgmod-group-text lgmod-title">Subscription:</div>
                            {this.props.capacityMin &&
                                <div className="lgmod-group-text lgmod-title">Capacity Minimum: </div>
                            }
                            {this.props.capacityDefault &&
                                <div className="lgmod-group-text lgmod-title">Capacity Default: </div>
                            }
                            <div className="lgmod-group-text lgmod-title">Alerts Compliance:</div>
                            <div className="lgmod-group-text lgmod-title">Report Execution Date:</div>
                            
                        </div>

                        <div className="inline-block-div col">
                            <div className="lgmod-group-text lgmod-text-content">{this.props.resourceName}</div>
                            <div className="lgmod-group-text lgmod-text-content">{this.props.resourceGroupName}</div>
                            <div className="lgmod-group-text lgmod-text-content">{this.props.subscriptionName}</div>
                            {this.props.capacityMin &&
                                <div className="lgmod-group-text lgmod-title lgmod-text-content">{this.props.capacityMin.value} ({this.props.capacityMin.grade})</div>
                            }
                            {this.props.capacityDefault &&
                                <div className="lgmod-group-text lgmod-title lgmod-text-content">{this.props.capacityDefault.value} ({this.props.capacityMin.grade})</div>
                            }
                            <div className="lgmod-group-text high-alert lgmod-text-content"><b> <ComplianceRating alertComplianceValue={this.props.alertCompliance && this.props.alertCompliance}/></b></div>
                            <div className="lgmod-group-text lgmod-text-content">{this.props.reportCollectionDate}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}