import React, { Component } from 'react';
import '../css/Textbox.css';
import { Textbox } from './Textbox'; 

export class IPAddressInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            currentInputType: 0,
            currentPlaceholder: "10.10.10.10"
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.changeToSingleIP = this.changeToSingleIP.bind(this);
        this.changeToIPRange = this.changeToIPRange.bind(this);
        this.changeToCIDR = this.changeToCIDR.bind(this);
    }

    componentDidMount() {
        if (this.props.default) {
            if (this.props.default.includes("-")) {
                this.setState({ currentInputType: 1 });
            }
            else if (this.props.default.includes("/")) {
                this.setState({ currentInputType: 2 });
            }
        }
    }

    toggleDropdown() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    changeToSingleIP() {
        this.setState({ currentInputType: "Single IP", currentPlaceholder: "10.10.10.10" });
    }

    changeToIPRange() {
        this.setState({ currentInputType: "IP Range", currentPlaceholder: "10.10.10.10 - 10.10.10.20" });
    }

    changeToCIDR() {
        this.setState({ currentInputType: "CIDR", currentPlaceholder: "10.0.0.0/24"});
    }

    render() {

        let sideDropdownOptions = [
            {
                text: "Single IP",
                placeholder: "10.10.10.10",
                pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
            },
            {
                text: "IP Range",
                placeholder: "10.10.10.10 - 10.10.10.20",
                pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\s?-\\s?(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
            },
            {
                text: "CIDR",
                placeholder: "10.0.0.0/24",
                pattern: "^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2])){1}$"
            }
        ]
        return (

                <Textbox
                name={this.props.name}
                type="ip"
                label={this.props.label}
                tooltip={this.props.tooltip}
                maxlength="40"
                required={this.props.required}
                errorMessage="Must not be empty and must be a valid IP address or address range."
                update={this.props.updateText}
                default={this.props.default}
                disabled={!this.props.editing}
                editing={this.props.editing}
                sideDropdownOptions={sideDropdownOptions}
                currentSidebarOption={this.state.currentInputType}
                />
        );
    }
}