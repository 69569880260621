import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import { auth } from '../../adalConfig';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class KeyvaultNameInput extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
       
        this.state = {
            
        };
    }

    componentDidMount() {
        auth.then((response) => {
            response.authContext.getUser(this.setContext);
        });
    }

    setContext = (err, context) => {
        this.setState({ userContext: context }, this.setUserID);
    }

    checkKeyvaultName = async () => {
        if (this.props.selectedSubscriptions && this.props.selectedSubscriptions.length > 0 && this.props.keyvaultName != "") {

            let keyvaultValidRequestBody = {
                subscriptionNames: this.props.selectedSubscriptions,
                keyvaultName: this.props.keyvaultName
            }

            try {
                let keyvaultValidResponse = await authenticatedApiFetch('api/ResourceManagement/CheckKeyVaultNameAvailability', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(keyvaultValidRequestBody)
                });

                let keyvaultCheck = await keyvaultValidResponse.json();


                if (keyvaultCheck.keyvaultNameAvailable) {
                    this.setState({ keyVaultErrorMessage: null });
                    this.props.updateText("keyvaultChecked", true);
                } else {
                    this.setState({ keyVaultErrorMessage: `'${this.props.keyvaultName}' already exists, please choose a unique keyvault name.` });
                    this.props.updateText("keyVaultName", "");
                    this.props.updateText("keyvaultChecked", false);
                }
            } catch (err) {
                this.context.handleError(err);
                throw err;
            }

        }

    }


    render() {
        
        return (
            <Textbox
                name="keyVaultName"
                type="text"
                label="Keyvault Name"
                tooltip="The keyvault name will be the application name concat with -{environment}-kv. Max character for keyvault name can only be 24"
                maxlength="12"
                required={this.props.required}
                errorMessage={"Must not be empty."}
                update={this.props.updateText}
                default={this.props.keyvaultName}
                onBlur={this.checkKeyvaultName}
                alert={this.state.keyVaultErrorMessage && { color: "danger", text: this.state.keyVaultErrorMessage }}
                disabled={this.props.disabled}
            />
        );
    }
}