import React, { Component } from 'react';
import '../css/Button.css';


export class ChipButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        
    }
    render() {
        return (
            <div className={`text-center ${this.props.buttonClassName}`}>
                <button
                    className={`button ${this.props.color}`}
                    onClick={() => this.props.action()}
                    disabled={this.props.disabled}
                >
                    {this.props.buttonText}
                </button>
            </div>
        )
    }
}