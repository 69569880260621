import { authenticatedApiFetch } from "./FetchHelper";

export const getListAzureSubscriptionsBudget = (subscriptions) => {
    return new Promise((res, rej) => {
        authenticatedApiFetch('api/AzureBudget',
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(subscriptions)
            })
            .then((response) => {
                if (response.ok) {
                    res(response.json());
                } else {
                    rej(response);
                }
            })
    })
}

export const isBudgetHealthy = (budget) => {
    let isBudgetHealthy = false;

    budget.forEach(function (item) {
        let percentage = (item.lastMonthCost / item.budget) * 100;
        if (item.isLastMonthOverBudget || percentage > 80) {
            isBudgetHealthy = true;
        }
    });
    return isBudgetHealthy;
}

export const subscriptionColor = (budget, selectedSub) => {
    let color = "grey";
    if (budget) {
        let percentage;
        budget.forEach(function (item) {
            percentage = (item.lastMonthCost / item.budget) * 100;
            if (selectedSub.value == item.subscriptionName) {
                if (item.isLastMonthOverBudget) {
                    color = "red";
                } else if (percentage > 80) {
                    color = "yellow";
                } else {
                    color = "green";
                }
            }
        });
    }
    return color;
}
