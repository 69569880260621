import { getToken } from "../adalConfig";

export const fetch_retry = (url, options, n = 3, pause = 2000, exponential = false) => {
    return new Promise(function (resolve, reject) {
        getToken()
            .then(token => {
                if (!options.headers) {
                    options.headers = {
                        "Authorization": `Bearer ${token}`
                    }
                } else {
                    options["headers"]["Authorization"] = `Bearer ${token}`;
                }
                fetch(url, options)
                    .then(function (result) {
                        resolve(result);
                    })
                    .catch(async function (error) {
                        if (n === 1) return reject(error);
                        await new Promise(r => setTimeout(r, pause));
                        fetch_retry(url, options, n - 1, exponential ? pause * 2 : pause, exponential)
                            .then(resolve)
                            .catch(reject);
                    })
            });
    });
}

export const authenticatedApiFetch = (url, options) => {
    return new Promise(function (resolve, reject) {
        getToken()
            .then(token => {
                if (!options.headers) {
                    options.headers = {
                        "Authorization": `Bearer ${token}`
                    }
                } else {
                    options["headers"]["Authorization"] = `Bearer ${token}`;
                }

                fetch(url, options)
                    .then(function (result) {
                        resolve(result);
                    })
                    .catch(async function (error) {
                        reject(error);
                    })
            });
    });
}