import React, { Component } from 'react';
import '../css/Loading.css';
import LoadingAnimation from '../img/loading.gif';

export class LoadingCloud extends Component {

    render() {
        return (

            <div className="loading-image-container">
                <img src={LoadingAnimation} alt="loading" className={this.props.insideModal ? "loading-center-modal" : "loading-center"} />
            </div>
        )
    }
}