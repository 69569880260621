import React, { Component } from 'react';

export class AnalyticsModalContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataCapCompliance: "",
            dataCapValue: "",
            dataRetentionCompliance: "",
            dataRetentionValue: ""
        };
    }

    componentDidMount() {
        this.findAndSetAlertValues(this.props.resourceDetailsData);
    }


    findAndSetAlertValues(resourceDetailsData) {

        var dataCap =
            resourceDetailsData.find(x => x.resourceDetailName === "DataDailyCap");
        var dataRetention =
            resourceDetailsData.find(x => x.resourceDetailName === "DataRetentionRate");

        this.setState({
            dataCapCompliance: dataCap.resourceDetailGrade,
            dataCapValue: dataCap.resourceDetailValue,
            dataRetentionCompliance: dataRetention.resourceDetailGrade,
            dataRetentionValue: dataRetention.resourceDetailValue
        });

    }

    render() {
        return (
            <div>
                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Daily Data Cap Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Daily Data Cap Value:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.dataCapCompliance}</div>
                        </div>
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.dataCapValue}</div>
                        </div>
                    </div>
                </div>

                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Data Retention Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Data Retention Value:</h3>
                            </div>

                        </div>


                    </div>

                    <div className="row lgmod-row lgmod-padded-row">
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.dataRetentionCompliance}</div>
                        </div>
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.dataRetentionValue}</div>
                        </div>
                    </div>
                </div>

                <div className="lgmod-group" />
            </div>
        );
    }
}