import React, { Component } from 'react';
import '../css/Checkbox.css';


export class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.default,
        };
    }

    componentDidMount() {
        this.updateValue(this.props.default);
    }

    updateValue(value) {
        this.setState({
            value: value
        });

        this.props.update(this.props.name, value);
    }

    handleChange(event) {
        let value = event.target.checked;
        this.updateValue(value);
    }

    toggleCheckbox() {
        let currentValue = this.state.value;
        this.checkbox.checked = !currentValue;

        this.updateValue(!currentValue);
    }

    toggleTooltip(show, event) {
        let targetRect = event.target.getBoundingClientRect();
        let parentRect = event.target.offsetParent.offsetParent.getBoundingClientRect()
        this.setState({
            tooltipVisible: show,
            tooltipLocation: {
                top: targetRect.top - parentRect.top - 8,
                left: targetRect.left - parentRect.left + 18
            }
        });
    }

    componentWillReceiveProps() {
        if (this.props.disabled) {
            this.setState({ value: this.props.default });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.default !== prevProps.default) {
            this.setState({ value: this.props.default });
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.props.tooltip &&
                    <div
                        className={`input-tooltip ${this.state.tooltipVisible ? 'visible' : ''}`}
                        style={this.state.tooltipLocation}
                    >
                        {this.props.tooltip}
                    </div>
                }
                <input
                    className="checkbox"
                    type="checkbox"
                    disabled={this.props.disabled}
                    name={this.props.name}
                    onChange={(evt) => this.handleChange(evt)}
                    checked={this.state.value}
                    ref={(input) => { this.checkbox = input; }}
                />
                <label
                    className='input-label checkbox-label'
                    htmlFor={this.props.name}
                    onClick={() => this.toggleCheckbox()}
                >
                    {this.props.label}
                    <span
                        className={`input-required ${this.props.required !== 'true' ? 'invisible' : ''}`}
                    >
                        *
                    </span>
                    {this.props.tooltip &&
                        <span
                            className='input-information'
                            name={this.props.name}
                            onMouseOver={(evt) => this.toggleTooltip(true, evt)}
                            onMouseOut={(evt) => this.toggleTooltip(false, evt)}
                        >
                            i
                    </span>
                    }
                </label>
            </div>
        )
    }
}