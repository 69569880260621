import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import "../../css/RequestDetails.css"
import { Dropdown } from '../Dropdown';
import { Checkbox } from '../Checkbox';
import { getAllSubscriptions } from '../../helpers/SubscriptionHelper';
import { ChipButton } from '../ChipButton'
import { KeyvaultNameInput } from './KeyvaultNameInput';

export class CreateSpnPlusDetailsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provisionedSpnNames: "fill this in",
            requestDetails: this.props.requestDetails,
            subscriptions: null,
            selectedSubscriptions: this.props.requestDetails.requestedSubscriptions,
            region: null,
            keyVaultEnabled: null,
            keyVaultaadGroupName: this.props.requestDetails.keyVault ? this.props.requestDetails.keyVault.aadGroupName : null,
            keyVaultName: this.props.requestDetails.keyVault ? this.props.requestDetails.keyVault.keyVaultName : null,
            requesterManagerName: null,
            requesterManagerEmail: null,
            requesterTeamName: null
        }

        this.updateText = this.updateText.bind(this);
        this.saveRequest = this.saveRequest.bind(this);
        this.setProvisionedSpnNames = this.setProvisionedSpnNames.bind(this);
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    async updateSubscriptionList() {
        var subscriptionList = await getAllSubscriptions();
        this.setState({
            subscriptions: subscriptionList
        });
    }

    componentDidMount() {

        this.updateSubscriptionList();
        this.setProvisionedSpnNames();
    }

    saveRequest() {
        var updatedRequest = this.constructEditedRequestDetails();
        this.props.updateRequest(updatedRequest);
    }

    constructEditedRequestDetails() {
        var updatedRequestDetails = this.state.requestDetails;
        updatedRequestDetails.requestedSubscriptions = this.state.selectedSubscriptions;
        updatedRequestDetails.region = this.state.region;
        updatedRequestDetails.keyVault =
            this.state.keyVaultEnabled ?
                {
                    keyVaultName: this.state.keyVaultName,
                    isKeyVaultCreated: false,
                    aadGroupName: this.state.keyVaultaadGroupName
                }
                : null;
        updatedRequestDetails.requester.contactInfo.name = this.state.requesterName;
        updatedRequestDetails.requester.contactInfo.email = this.state.requesterEmail;
        updatedRequestDetails.requester.manager.contactInfo.name = this.state.requesterManagerName;
        updatedRequestDetails.requester.manager.contactInfo.email = this.state.requesterManagerEmail;
        updatedRequestDetails.requester.groupName = this.state.requesterTeamName;
        return updatedRequestDetails;
    }

    componentWillUpdate(prevProps) {
        if (this.props.requestDetails !== prevProps.requestDetails) {
            this.setState({ requestDetails: this.props.requestDetails, keyvaultName: this.props.requestDetails.keyVault.keyVaultName });
        }
    }

    setProvisionedSpnNames() {
        let spnNames = [];
        if (this.state.requestDetails.provisionedSpns) {
            this.state.requestDetails.provisionedSpns.map((spn) => spnNames.push(spn.name));
            this.setState({ provisionedSpnNames: spnNames.join(", ") });
        }
    }

    isEditRequestFormValid() {
        let baseRequest = this.state.requesterManagerName &&
            this.state.requesterManagerEmail &&
            this.state.requesterTeamName;

        let keyvaultValid = this.state.keyVaultEnabled ?
            this.state.keyvaultChecked && this.state.keyVaultName != "" && this.state.keyVaultaadGroupName : true;

        let spnPlusForm = this.state.region &&
            (this.state.selectedSubscriptions &&
                this.state.selectedSubscriptions.length > 0);

        return spnPlusForm && keyvaultValid && baseRequest;

    }

    render() {
        let regions = ["WEST US 2", "WEST US", "CENTRAL US", "EAST US", "EAST US 2", "NORTH CENTRAL US", "SOUTH CENTRAL US", "WEST CENTRAL US"];
        return (
            <div className="request-details-container">
                {this.props.requestDetails &&
                    <div>
                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Type"
                            update={this.updateText}
                            default={this.props.requestDetails.requestTypeName}
                            disabled={true}
                        />

                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Status"
                            update={this.updateText}
                            default={this.props.requestDetails.requestStatusName}
                            disabled={true}
                        />

                        {this.props.requestDetails.approvalComments &&
                            <Textbox
                                name="approvalComments"
                                type="text"
                                label="Approval Comments"
                                update={this.updateText}
                                default={this.props.requestDetails.approvalComments}
                                disabled={true}
                            />
                        }
                        <Dropdown
                            label="Subscriptions"
                            values={this.state.subscriptions && this.state.subscriptions}
                            texts={this.state.subscriptions && this.state.subscriptions}
                            default={this.props.requestDetails.requestedSubscriptions}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            multiple={true}
                            update={this.updateText}
                            name="selectedSubscriptions"
                        />

                        <Dropdown
                            name="region"
                            label="Region"
                            values={regions}
                            texts={regions}
                            default={this.props.requestDetails.region}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}
                            required="true"
                        />

                        {this.props.requestDetails.requestStatus === 3 &&
                            <Textbox
                                name="spnNames"
                                type="text"
                                label="SPN Names"
                                update={this.updateText}
                                default={this.state.provisionedSpnNames}
                                disabled={true}

                            />
                        }

                        <Checkbox
                            name="keyVaultEnabled"
                            label="Key Vault"
                            className="report-details-checkbox"
                            default={this.props.requestDetails.keyVault && this.props.requestDetails.keyVault.keyVaultName ? true : false}
                            update={this.updateText}
                            disabled={!this.props.editing}
                        />

                        {((!this.props.editing && this.props.requestDetails.keyVault && this.props.requestDetails.keyVault.keyVaultName)
                            || (this.props.editing && this.state.keyVaultEnabled)) &&
                            <div>
                                <KeyvaultNameInput
                                    required={this.state.display}
                                    updateText={this.updateText}
                                    selectedSubscriptions={this.state.selectedSubscriptions}
                                    keyvaultName={this.state.keyVaultName}
                                    disabled={!this.props.editing}
                                    editing={this.props.editing}
                                />
                                <Dropdown
                                    name="keyVaultaadGroupName"
                                    label="Keyvault Permission"
                                    tooltip="The active directory group that will be granted contributor in test and qa and only writer in prod."
                                    values={this.props.aadGroups}
                                    texts={this.props.aadGroups}
                                    default={this.state.keyVaultaadGroupName}
                                    required={this.state.display}
                                    errorMessage="Active directory group is required"
                                    update={this.updateText}
                                    disabled={!this.props.editing}
                                    editing={this.props.editing}

                                />
                            </div>
                    }

                    <Checkbox
                        name="isAppConfigEnabled"
                        label="App Config Service"
                        className="report-details-checkbox"
                        default={this.props.requestDetails.appConfigurationService && this.props.requestDetails.appConfigurationService.appConfigurationServiceName }
                        update={this.updateText}
                        disabled={!this.props.editing}
                    />

                    {
                        this.props.requestDetails.appConfigurationService && this.props.requestDetails.appConfigurationService.appConfigurationServiceName &&
                        <div>
                            <Textbox
                                name="App Config Service AAD Group Name"
                                type="text"
                                label="App Config Service AAD Group Name"
                                default={this.props.requestDetails.appConfigurationService.aadGroupName}
                                disabled={true}
                            />
                            <Textbox
                                name="App Config Service Name"
                                type="text"
                                label="App Config Service Name"
                                default={this.props.requestDetails.appConfigurationService.appConfigurationServiceName}
                                disabled={true}
                            />
                        </div>

                    }
                    {((!this.props.editing && this.props.requestDetails.appConfigService && this.props.requestDetails.appConfigService.appConfigName)
                        || (this.props.editing && this.state.appConfigEnabled)) &&
                        <div>
                            <KeyvaultNameInput
                                required={this.state.display}
                                updateText={this.updateText}
                                selectedSubscriptions={this.state.selectedSubscriptions}
                                keyvaultName={this.state.keyVaultName}
                                disabled={!this.props.editing}
                                editing={this.props.editing}
                            />
                            <Dropdown
                                name="keyVaultaadGroupName"
                                label="Keyvault Permission"
                                tooltip="The active directory group that will be granted contributor in test and qa and only writer in prod."
                                values={this.props.aadGroups}
                                texts={this.props.aadGroups}
                                default={this.state.keyVaultaadGroupName}
                                required={this.state.display}
                                errorMessage="Active directory group is required"
                                update={this.updateText}
                                disabled={!this.props.editing}
                                editing={this.props.editing}

                            />
                        </div>
                    }

                        {this.props.requestDetails.cherwellInformation &&
                            <Textbox
                                name="cherwellTicketNumber"
                                type="text"
                                label="Cherwell Ticket Number"
                                default={this.props.requestDetails.cherwellInformation.cherwellTicketNumber}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="creationDate"
                            type="text"
                            label="Creation Date"
                            default={new Date(this.props.requestDetails.creationDate).toLocaleDateString()}
                            disabled={true}
                        />

                        {this.props.requestDetails.requestStatus === 3 &&
                            <Textbox
                                name="completionDate"
                                type="text"
                                label="Completion Date"
                                default={new Date(this.props.requestDetails.completionDate).toLocaleDateString()}
                                disabled={true}
                            />
                        }

                        {(this.props.requestDetails.requestStatus == 3 || this.props.requestDetails.requestStatus == 1) && this.props.requestDetails.approver &&
                            <>
                                <Textbox
                                    name="approverName"
                                    type="text"
                                    label="Approver Name"
                                    default={this.props.requestDetails.approver.contactInfo.name}
                                    disabled={true}
                                />

                                <Textbox
                                    name="approverEmail"
                                    type="text"
                                    label="Approver Email"
                                    default={this.props.requestDetails.approver.contactInfo.email}
                                    disabled={true}
                                />

                            </>
                        }

                        <Textbox
                            name="requesterName"
                            type="text"
                            label="Requester Name"
                            default={this.props.requestDetails.requester.contactInfo.name}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterEmail"
                            type="email"
                            label="Requester Email"
                            default={this.props.requestDetails.requester.contactInfo.email}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterManagerName"
                            type="text"
                            label="Requester's Manager Name"
                            default={this.props.requestDetails.requester.manager.contactInfo.name}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterManagerEmail"
                            type="email"
                            label="Requester's Manager Email"
                            default={this.props.requestDetails.requester.manager.contactInfo.email}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterTeamName"
                            type="text"
                            label="Requester's Team Name"
                            default={this.props.requestDetails.requester.groupName}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        {this.props.editing &&
                            <ChipButton
                                buttonClassName="save-button"
                                buttonText="Save"
                                action={this.saveRequest}
                                color='btn-success'
                                disabled={!this.isEditRequestFormValid()}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}