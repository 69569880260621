import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import MinusSign from '../../img/CHIPv1/minus-sign.png';
import PlusSign from '../../img/CHIPv1/plus-sign.svg';
import ExpandIcon from '../../img/CHIPv1/expand.svg';

export class ReportChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardStatus: [
                true, false, false
            ]
        };

        this.toggleCardSwitch = this.toggleCardSwitch.bind(this);

    }

    toggleCardSwitch(cardIndex) {
        var newCardStatus = [false, false, false];

        if (this.state.cardStatus[cardIndex]) {
            newCardStatus = this.state.cardStatus;
            newCardStatus[cardIndex] = !newCardStatus[cardIndex];
        } else {
            newCardStatus[cardIndex] = true;
        }
        this.setState({ cardStatus: newCardStatus });

    }

    whatDoesThisReportMean() {
        if (this.props.reportTitle == "Azure SQL Cost Efficiency") {
            return <ul>
                <li>Red: Over Provisioned - Databases listed on this report are considered over-provisioned based on their low average and maximum DTU utilization. Action plan: 1) lower Database pricing tier 2) delete database if no longer needed</li>
                <li>Yellow: Needs Review - Databases listed on this report need to be reviewed by DBA or knowledgeable developer to determine root cause for high average and maximum DTU utilization. Possible reasons for high DTU utilization: 1) Poor SQL execution plan 2) Missing Indexes 3) Datatype mismatches in where clause variable</li>
                <li>Green:  Healthy – No action required</li>
            </ul>
        }
        else if (this.props.reportTitle == "Cosmos Cost") {
            return <ul>
                <li>Red: Over Provisioned - Collections listed on this report are considered over-provisioned based on their average RU utilization is less than 40 percent. Action plan:
                    <ul>1) Lower collection RU provisioning</ul><ul> 2) delete collection if no longer needed</ul> </li>
                <li>Yellow: Needs Review - Collections listed on this report need to be reviewed by DBA or knowledgeable developer to determine root cause for high average and maximum RU utilization.</li>
                <li>Green: Healthy – No action required </li>
            </ul>
        }
        else if (this.props.reportTitle == "App Server Plan Cost") 
        {
            return <ul>
                <li>Red: Over Provisioned - App Server Plan listed on this report are considered over-provisioned based on their average CPU utilization is less than 40 percent. Action plan:
                    <ul>1) Lower sku tier</ul><ul> 2) Delete app service plan if no longer needed</ul> </li>
                <li>Yellow: Needs Review - Collections listed on this report need to be reviewed by Cloud Team or knowledgeable developer to determine root cause for high average and maximum CPU utilization.</li>
                <li>Green: Healthy – No action required </li>
            </ul>
        }
        else {
            return <ul>
                <li>Fully Compliant - Meets requirements and no change is needed.</li>
                <li>Partially Compliant - Has rule but value does not meet requirement.</li>
                <li>Non-Compliant - Does not meet requirement and change is needed.</li>
            </ul>
        }
    }

    expectedThreshold() {
        switch (this.props.reportTitle) {
            case "App Service Plan Alerts":
                return <ul>
                    <li>Cpu Percentage Alert Threshold : > 75 over 10 minutes</li>
                    <li>Disk Queue Length Alert Threshold : > 100 over 10 minutes </li>
                    <li>Memory Percentage Alert Threshold : > 90 over 10 minutes</li>
                    <li>Http Queue Length Alert Threshold : > 100 over 10 minutes</li>
                </ul>
                break;
            case "App Service Alerts":
                return <ul>
                    <li>Http 403 Alert Threshold : > 3 errors over 5 minutes</li>
                    <li>Http 5XX Alert Threshold : > 5 errors over 5 minutes</li>
                </ul>
                break;
            case "App Service Plan Autoscale":
                return <ul>
                    <li>CPU Percentage Autoscale Settings:</li>
                    <ul>
                        <li> > 95% - Increase instance count by 5</li>
                        <li> > 65% - Increase instance count by 2</li>
                        <li> &lt; 50% - Decrease instance count by 1</li>
                    </ul>
                    <li>Disk Queue Length Autoscale Settings:</li>
                    <ul>
                        <li> > 100 - Increase instance count by 1</li>
                        <li> &lt;= 50 - Decrease instance count by 1</li>
                    </ul>
                    <li>Memory Percentage Autoscale Settings:</li>
                    <ul>
                        <li> > 85% - Increase instance count by 1</li>
                        <li> &lt;= 70% - Decrease instance count by 1</li>
                    </ul>
                    <li>Http Queue Length Autoscale Settings:</li>
                    <ul>
                        <li> > 35 - Increase instance count by 3</li>
                        <li> > 5 - Increase instance count by 1</li>
                        <li> = 0 - Decrease instance count by 1</li>
                    </ul>
                    <li>Please refer to <a href="https://itsals.visualstudio.com/CloudHub/_git/ARMTemplates?path=%2FBestPractice%2FAutoScaleSetting" target="_blank">CloudHub</a>  for additional expectations on instance count, time windows, and cooldown times.</li>
                </ul>
                break;
            case "Azure SQL Cost":
                return<ul>
                    <li>
                        To achieve Green status, your resources should be scaled appropriately to meet your workload.
                                    </li>

                    <li>
                        Grading Methodology:
                                        <ul>
                            <li>
                                If DB pricing tier is not a Basic, S0 or S1 and the DB average DTU &lt;15% and the DB max DTU is &gt;30%
                                Then the DB is considered over-provisioned.
                                            </li>
                            <li>
                                If the difference between the average DTU and the DB max DTU is &gt;50%
                                Then the db needs review.
                                            </li>
                            <li>Otherwise, the DB is considered healthy.</li>
                        </ul>
                    </li>
                </ul>
            case "Cosmos Cost":
                return <div>
                        <p>To achieve Green status, your resources should be scaled appropriately to meet your workload. 
                                Grading Methodology: </p>
                                <p>If the average RU is less than 40% then the collection is considered over provisioned 
                                If the average RU is between than 40% and 85 % then the collection is considered healthy 
                                if the average RU is over 85% then the collection needs review </p>
                                <p>NOTE: 
                                If Collection RU tier is set to 400 and is marked as Over Provisioned, there's nothing you can do other than considering a less expensive solution to persist data. A 400 RU collection costs approximately $276/year, $23/month </p>
                    </div>
             
             case "App Server Plan Cost" :
                 return <div>
                 <p>To achieve Green status, your resources should be scaled appropriately to meet your workload. 
                         Grading Methodology: </p>
                         <p>If the average CPU is less than 40% then the app server plan is considered over provisioned. If the average CPU is between than 40% and 75% then the app server plan is considered healthy. If the average CPU is over 75% then the app server plan needs review. </p>
                         <p>NOTE: 
                         Consider using Free or Basic tier for test environment to save on cost.</p>
                </div>

            case "Azure Analytics":
                return <div>
                    <p>To achieve Green status, your resources should be configured to protect against overflow of data and increased costs. </p>
                    <p>App Insights & Log Analytics Grading Methodology:</p>
                    <ul>
                        <li> Daily Data Cap &lt;= 10GB</li>
                        <li> Data Retention Rate = 30 Days</li>
                    </ul>
                </div>
        }
 
    }
    

    takeActionsToApplyProperRules() {
        switch (this.props.reportTitle) {
            case "Azure Analytics":
                return <div className="card-body report-card-body">
                        If you have Reds and Yellows, please implement remediation steps for required alerts health:
                                <ol>
                            <li>
                                As a Leader, please reach out to your Engineering Team to take action to apply data cap and retention rules as soon as possible.
                                    </li>
                            <li>
                                As an Engineer, please take action to set a daily data cap lower than 10GB and data rention rate of 30 days for all log analytics and application insights resources under your projects.
                            </li>
                            <li>
                                For additional assistance, reach out to <a href="mailto:cloudrequests@alaskaair.com">Cloud Requests.</a>
                            </li>
                        </ol>
                </div>

            default: 
                return <div className="card-body report-card-body">
                        If you have Reds and Yellows, please implement remediation steps for required alerts health:
                                <ol>
                            <li>
                                As a Leader, please reach out to your Engineering Team to take action to apply Alerting rules as soon as possible.  This will allow your app to meet alerting requirements for Production apps.
                                    </li>
                            <li>
                                As an Engineer, please take action to get the sample template from
                                        <a target="_blank" href="https://itsals.visualstudio.com/CloudHub/CloudHub%20Team/_git/ARMTemplates"> CloudHub</a>.
                                                                                                                                                                                                        Then, apply the ARM template to your pipeline and deploy as soon as possible. Alerting is required for Production App Services.
                                    </li>
                            <li>
                                For additional assistance, reach out to <a href="mailto:cloudrequests@alaskaair.com">Cloud Requests.</a>
                            </li>
                        </ol>
                    </div>
                
        }
    }
    render() {

        return (
            <div>

                <div className="chart-container">
                    <div className={this.props.chartSideClass} id="chartSide">
                        <div id="accordion">
                            <div className="card report-card">
                                <div className="card-header report-card-header" onClick={() => this.toggleCardSwitch(0)} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0">
                                        <img src={this.state.cardStatus[0] ? MinusSign : PlusSign} alt="minimize" />
                                        <span>Please Take These Actions To Apply Proper Rules:</span>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    {this.takeActionsToApplyProperRules()}
                                </div>
                            </div>
                            <div className="card report-card">
                                <div className="card-header report-card-header btn btn-link collapsed" onClick={() => this.toggleCardSwitch(1)} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <h5 className="mb-0">
                                        <img src={this.state.cardStatus[1] ? MinusSign : PlusSign} alt="expand" />
                                        <span>What do these results mean?</span>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="card-body report-card-body">
                                        What do these results mean:
                                        {this.whatDoesThisReportMean()}
                                    </div>
                                </div>
                            </div>
                            <div className="card report-card">
                                <div className="card-header report-card-header btn btn-link collapsed" onClick={() => this.toggleCardSwitch(2)} data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <h5 className="mb-0">
                                        <img src={this.state.cardStatus[2] ? MinusSign : PlusSign} alt="expand" />
                                        <span>Expected Thresholds</span>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="card-body report-card-body">
                                        {this.expectedThreshold()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.chartDiagramClass} id="chartDiagram">
                        <a className={this.props.menuToggleClass} id="menu-toggle" onClick={() => this.props.toggleChartInfoSwitch()}>

                            <img src={ExpandIcon} alt="expand sidebar" id="expand-btn" className={this.props.expandBtnClass} />
                        </a>

                        <div className="inline-wrapper">
                            <Bar
                                data={this.props.chartData}
                                height={400}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            scaleLabel: {
                                                display: true,
                                                labelString: 'Week'
                                            }
                                        }],
                                        yAxes: [{
                                            scaleLabel: {
                                                display: true,
                                                labelString: `${this.props.reportTitle} Compliance(%)`
                                            },
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    },
                                    title: {
                                        display: true,
                                        fontFamily: 'ASCircular-Book',
                                        fontStyle: 'bold',
                                        fontColor: '#666',
                                        fontSize: '16',
                                        padding: '13',
                                        text: `${this.props.reportTitle} ${(this.props.reportTitle === 'Cosmos Cost' || this.props.reportTitle === 'Azure SQL Cost' || this.props.reportTitle === 'App Server Plan Cost') ? "Optimization Report" : "Health Report"} - ${this.props.subscription}`
                                    },
                                    tooltips: {
                                        enabled: true,
                                        mode: 'index',
                                        intersect: true,
                                        callbacks: {
                                            label: function (tooltipItems, data) {
                                                return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel + ' %';
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}