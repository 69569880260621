import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const auth = new Promise((res, rej) =>
    fetch('api/Auth/GetAdalConfig')
        .then(response => response.json())
        .then(data => {
            let adalConfig = {
                tenant: data.tenant,
                clientId: data.clientId,
                redirectUri: data.redirectUri,
                cacheLocation: 'sessionStorage',
                endpoints: {
                    graphApi: 'https://graph.windows.net',
                    vstsApi: 'https://itsals.visualstudio.com'
                }
            };

            let authContext = new AuthenticationContext(adalConfig);
            let getToken = () => authContext.getCachedToken(adalConfig.clientId)
            let graphApiFetch = (fetch, url, options) =>
                adalFetch(authContext, adalConfig.endpoints.graphApi, fetch, url, options);
            let withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.graphApi);

            res({
                adalConfig,
                authContext,
                getToken,
                graphApiFetch,
                withAdalLoginApi
            });

        }));

export function getToken() {
    return auth.then(response => response.getToken());
}
