import { authenticatedApiFetch } from "./FetchHelper";

export const getAzureDevOpsProjects = async () => {
    return await getAzureDevOpsProjectsApiCall();
}

const getAzureDevOpsProjectsApiCall = () => {
    return new Promise((res, rej) => {
        authenticatedApiFetch('api/AzureDevOps/GetAllProjectNames', {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            }).then((data) => {
                let vstsProjectList = [];
                data.map((vstsProjectName) => vstsProjectList.push(vstsProjectName.name));
                res(vstsProjectList);
            });
    })
}