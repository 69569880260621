import React, { Component } from 'react';
import { Dropdown } from './Dropdown';
import { Textbox } from './Textbox';
import { ChipButton } from './ChipButton'

export class PortProtocolInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            destinationPort: this.props.destinationPort,
            protocol: this.props.protocol
        };

        this.updateText = this.updateText.bind(this);
        this.isPortProtocolValid = this.isPortProtocolValid.bind(this);
        this.addPortProtocol = this.addPortProtocol.bind(this);
        this.deletePortProtocol = this.deletePortProtocol.bind(this);
    }

    addPortProtocol() {
        this.props.addPortProtocol({ destinationPort: this.state.destinationPort, protocol: this.state.protocol });
        this.setState({ destinationPort: "", protocol: '' });
    }

    deletePortProtocol() {
        this.props.deletePortProtocol(this.props.portProtocolIndex);
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    isPortProtocolValid() {
        return this.state.destinationPort && this.state.protocol;
    }

    render() {
        let protocols = ['TCP', 'UDP', 'TCP/UDP'];

        return (
            <div className="port-protocol-container">
                <Textbox
                    name="destinationPort"
                    type="text"
                    label="Destination Port/Range"
                    tooltip="The port used to connect to the destination."
                    maxlength="11"
                    pattern="^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(?:-([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$"
                    required="true"
                    errorMessage="Must not be empty and a valid network port or port range."
                    update={this.updateText}
                    default={this.state.destinationPort}
                    disabled={!this.props.editing || this.props.existingPortProtocol}
                    editing={this.props.editing && !this.props.existingPortProtocol}
                />
                <Dropdown
                    name="protocol"
                    label="Protocol"
                    tooltip="The protocol used to connect to destination."
                    values={protocols}
                    texts={protocols}
                    required="true"
                    errorMessage="Protocol is required."
                    update={this.updateText}
                    default={this.state.protocol}
                    disabled={!this.props.editing || this.props.existingPortProtocol}
                    editing={this.props.editing && !this.props.existingPortProtocol}
                />
                {!this.props.existingPortProtocol &&
                    <ChipButton
                        buttonClassName="save-button port-protocol-btn"
                        buttonText="Add"
                        action={this.addPortProtocol}
                        color='btn-success'
                        disabled={!this.isPortProtocolValid()}
                    />
                }
                {this.props.existingPortProtocol && this.props.editing &&
                    <ChipButton
                        buttonClassName="delete-button port-protocol-btn"
                        buttonText="Delete"
                        action={this.deletePortProtocol}
                        color='btn-danger'
                        disabled={!this.isPortProtocolValid()}
                    />
                }
            </div>
        );
    }
}