import React, { Component } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import { auth } from '../../adalConfig';
import { ChipModal } from '../ChipModal';
import { Kubernetes } from './Kubernetes';
import { ResourcePermissions } from './ResourcePermissions';
import { RunADOPipeline } from './RunADOPipeline';
import { LoadingCloud } from '../LoadingCloud'
import { StandAloneSpn } from './StandAloneSpn';
import { SpnPlus } from './SpnPlus';
import { NetworkFirewall } from './NetworkFirewall'
import { getAllSubscriptions } from '../../helpers/SubscriptionHelper';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class NewRequestModal extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            //Cloud Requests (SPN / SPN+) + Basic Request : 
            requestType: null,
            region: null,
            subscriptions: [],
            aadGroups: [],
            displayKeyvault: false,
            isKeyVaultEnabled: false,
            keyVaultName: null,
            resourceAdGroup: null,
            keyVaultErrorMessage: null,
            requesterManagerName: null,
            requesterManagerEmail: null,
            requesterTeamName: null,
            applicationFormData: this.props.applicationFormData,
            employeeId: null,
            managerId: null,
            servicePrincipalName: null,
            environment: [],
            submitting: false,
            networkApplicationName: null,
            networkFirewallRules: null,
            networkFirewallQuestions: null,
            devopsProjects: [],
            kubernetesClusters: [],
            displayUpdateSecret: false,
            secretUpdateKeyVaultName: null,
            secretUpdateSecretName: null,

            //Resource Permissions Request:
            resourcePermissionsSubscription: null,
            resourcePermissionsResourceType: null,
            resourcePermissionsACS: null,
            resourcePermissionsStorageAccount: null,
            resourcePermissionsIdentityType: null,
            resourcePermissionsADGroup: null,
            resourcePermissionsSPNObjectID: null,
            resourcePermissionsMSIObjectID: null,

            // Run ADO Pipeline Request:
            runADOPipelineProjectName: null,
            runADOPipelineID: null,
            runADOPipelineVariables: []
        };
        this.updateText = this.updateText.bind(this);
        this.setContext = this.setContext.bind(this);
        this.isNewRequestFormValid = this.isNewRequestFormValid.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.toggleKeyvault = this.toggleKeyvault.bind(this);
        this.toggleAppConfig = this.toggleAppConfig.bind(this);
        this.toggleSecretUpdate = this.toggleSecretUpdate.bind(this);
        this.addNetworkRule = this.addNetworkRule.bind(this);
        this.editNetworkRule = this.editNetworkRule.bind(this);
        this.deleteNetworkRule = this.deleteNetworkRule.bind(this);
    }

    toggleKeyvault(name, value) {
        this.setState({
            displayKeyvault: value,
            isKeyVaultEnabled: value
        });
    }

    toggleSecretUpdate(name, value) {
        this.setState({ displayUpdateSecret: value });
    }

    toggleAppConfig(name, value) {
        this.setState({
            displayAppConfig: value,
            isAppConfigEnabled: value,
        });
    }
    async getDevopsProjects() {
        try {
            let response = await authenticatedApiFetch('api/AzureDevOps/GetAllProjectNames', {});
            if (!response.ok) {
                throw new Error(response);
            }
            this.setState({ devopsProjects: await response.json() });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    async getKubernetesClusters() {
        try {
            let response = await authenticatedApiFetch('api/Kubernetes', {});
            if (!response.ok) {
                throw new Error(response);
            }
            this.setState({ kubernetesClusters: await response.json() });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    async updateSubscriptionList() {
        var subscriptionList = await getAllSubscriptions();
        this.setState({
            subscriptions: subscriptionList
        });
    }

    componentDidMount() {
        auth.then((response) => {
            response.authContext.getUser(this.setContext);
            this.updateSubscriptionList();
            this.getAdGroupsCall();
            this.getDevopsProjects();
            this.getKubernetesClusters();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.applicationFormData !== prevProps.applicationFormData) {
            this.setState({
                applicationFormData: this.props.applicationFormData
            })
        }
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    setContext(err, context) {
        this.setState({ userContext: context }, this.setUserID);
    }

    setUserID() {
        try {
            authenticatedApiFetch('api/Auth/GetUserIdentity?objectId=' + this.state.userContext.profile.oid, {})
                .then(userResponse => userResponse.json())
                .then(user => this.setState({
                    employeeId: user.employeeId,
                }));

            authenticatedApiFetch('api/Auth/GetUserManager?objectId=' + this.state.userContext.profile.oid, {})
                .then(managerResponse => managerResponse.json())
                .then(manager => this.setState({
                    managerId: manager ? manager.employeeId : null,
                    requesterManagerName: manager ? manager.displayName : null,
                    requesterManagerEmail: manager ? manager.mail : null
                }));
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    getAdGroupsCall() {
        authenticatedApiFetch("api/Auth/GetAzureAccessAdGroups", {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            }).then((data) => {
                let adGroupArray = [];
                data.map((adgroup) => adGroupArray.push(adgroup.displayName));
                this.setState({
                    aadGroups: adGroupArray
                });
            }).catch((err) => {
                this.context.handleError(err);
                throw err;
            });
    }

    submitRequest() {
        this.setState({ submitting: true });

        let requestObject = this.getRequestBodyFromState();

        let updateAppWithRequest = {
            originalApplication: this.state.applicationFormData,
            request: requestObject
        };

        authenticatedApiFetch('api/Request/CreateRequest', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateAppWithRequest)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response);
            }
        }).then((data) => {
            this.setState({
                submitting: false
            }, () => {
                this.props.toggleRequestModal();
                this.props.getApplicationRestCall();
                this.forceUpdate();
            });
        }).catch((error) => {
            this.setState({
                submitting: false,
                error: true
            })
        });
    }

    isNewRequestFormValid() {
        let baseRequest = this.state.requesterManagerName &&
            this.state.requesterManagerEmail &&
            this.state.requesterTeamName;
        
        if (this.state.requestType != null) {
            switch (Number(this.state.requestType)) {
                case 1:
                    let keyvaultValid = this.state.isKeyVaultEnabled ?
                        this.state.keyvaultChecked && this.state.keyVaultName && (this.state.resourceAdGroup && this.state.resourceAdGroup.length > 0) : true;
                    let appConfigValid = this.state.isAppConfigEnabled ?
                        this.state.appConfigName && (this.state.resourceAdGroup && this.state.resourceAdGroup.length > 0) : true;
                    let spnPlusForm = this.state.requestType &&
                        this.state.region &&
                        (this.state.selectedSubscriptions &&
                            this.state.selectedSubscriptions.length > 0)

                    return spnPlusForm && appConfigValid && keyvaultValid && baseRequest;
                case 2:
                    let standAloneSpnForm = this.state.servicePrincipalName && (this.state.selectedEnvironment && this.state.selectedEnvironment.length > 0);
                    return standAloneSpnForm && baseRequest;

                case 3:
                    let networkFirewallForm = this.state.networkApplicationName && this.state.networkFirewallRules && this.state.networkFirewallQuestions;
                    return baseRequest && networkFirewallForm;

                case 4:
                    let kubernetesForm = this.state.cluster && this.state.namespace && this.state.devopsProject;
                    return baseRequest && kubernetesForm;

                case 5:
                    let resourcePermissionsForm = this.state.resourcePermissionsResourceType && (this.state.resourcePermissionsACS || this.state.resourcePermissionsStorageAccount) && (this.state.resourcePermissionsADGroup || this.state.resourcePermissionsMSIObjectID || this.state.resourcePermissionsSPNObjectID);
                    return baseRequest && resourcePermissionsForm;

                case 6:
                    let runADOPipelineForm = this.state.runADOPipelineProjectName && this.state.runADOPipelineID && this.state.runADOPipelineVariables.length > 0 && !this.state.runADOPipelineVariables.find((x) => !x.value);
                    return baseRequest && runADOPipelineForm;
            }
        }

    }

    getRequestBodyFromState() {

        let cluster = this.state.kubernetesClusters && this.state.kubernetesClusters.find(cluster => cluster.id === this.state.cluster);
        return {
            requestType: this.state.requestType,
            subscriptions: this.state.selectedSubscriptions,
            environments: this.state.selectedEnvironment,
            region: this.state.region,
            isKeyVaultEnabled: this.state.isKeyVaultEnabled,
            keyVaultName: this.state.isKeyVaultEnabled && this.state.keyVaultName,
            keyVaultPermissions: this.state.isKeyVaultEnabled && this.state.resourceAdGroup,
            appConfigServiceName: this.state.isAppConfigEnabled && this.state.appConfigName,
            appConfigServicePermission: this.state.isAppConfigEnabled && this.state.resourceAdGroup,
            isAppConfigEnabled: this.state.isAppConfigEnabled,
            requesterManagerName: this.state.requesterManagerName,
            requesterManagerEmail: this.state.requesterManagerEmail,
            requesterTeamName: this.state.requesterTeamName,
            requesterName: this.state.userContext.profile.name,
            requesterEmail: this.state.userContext.profile.upn,
            requesterEmpId: this.state.employeeId,
            requesterObjectId: this.state.userContext.profile.oid,
            spnName: this.state.servicePrincipalName,
            networkApplicationName: this.state.networkApplicationName,
            networkFirewallRules: this.state.networkFirewallRules,
            networkFirewallQuestions: this.state.networkFirewallQuestions,
            clusterId: this.state.cluster,
            clusterName: cluster && cluster.clusterName,
            clusterNamespace: this.state.namespace,
            devopsProject: this.state.devopsProject,
            resourcePermissionsSubscription: this.state.resourcePermissionsSubscription,
            resourcePermissionsResourceType: this.state.resourcePermissionsResourceType,
            resourcePermissionsACS: this.state.resourcePermissionsACS,
            resourcePermissionsStorageAccount: this.state.resourcePermissionsStorageAccount,
            resourcePermissionsIdentityType: this.state.resourcePermissionsIdentityType,
            resourcePermissionsADGroup: this.state.resourcePermissionsADGroup,
            resourcePermissionsSPNObjectID: this.state.resourcePermissionsSPNObjectID,
            resourcePermissionsMSIObjectID: this.state.resourcePermissionsMSIObjectID,
            runADOPipelineProjectName: this.state.runADOPipelineProjectName,
            runADOPipelineID: this.state.runADOPipelineID,
            runADOPipelineVariables: this.state.runADOPipelineVariables,
            secretNameKeyVault: this.state.secretUpdateKeyVaultName,
            secretName: this.state.secretUpdateSecretName,
        }
    }


    addNetworkRule(rule) {
        var currentList = this.state.networkFirewallRules;
        var newList = currentList ? currentList.concat(rule) : [rule];

        if (rule.bidirectional) {
            let reversedRule = Object.assign({}, rule);
            reversedRule.sourceDNS = rule.destinationDNS;
            reversedRule.sourceIP = rule.destinationIP;
            reversedRule.destinationDNS = rule.sourceDNS;
            reversedRule.destinationIP = rule.sourceIP;

            newList = newList.concat(reversedRule);
        }

        this.setState({ networkFirewallRules: newList });
    }

    editNetworkRule(rule, index) {
        var currentList = this.state.networkFirewallRules;
        if (currentList) {
            currentList[index] = rule;
        }
        this.setState({ networkFirewallRules: currentList });
    }

    deleteNetworkRule(networkRuleIndex) {
        var currentList = this.state.networkFirewallRules;
        currentList.splice(networkRuleIndex, 1);
        this.setState({ networkFirewallRules: currentList });
    }

    render() {
        let requestType = {
            texts: [
                'Create Spn Plus',
                'Create Standalone SPN',
                'Network Firewall',
                'Azure Resource Permissions',
                'Run ADO Pipeline'
            ],
            values: [
                1,
                2,
                3,
                5,
                6
            ]
        }


        let request =
            (this.state.requestType != null && this.state.requestType == 1
                &&
                <SpnPlus
                    applicationName={this.state.applicationFormData.applicationName}
                    subscriptions={this.state.subscriptions}
                    aadGroups={this.state.aadGroups}
                    updateText={this.updateText}
                    toggleKeyvault={this.toggleKeyvault}
                    displayKeyvault={this.state.displayKeyvault}
                    toggleAppConfig={this.toggleAppConfig}
                    displayAppConfig={this.state.displayAppConfig}
                    editing={true}
                    keyvaultName={this.state.keyVaultName}
                    selectedSubscriptions={this.state.selectedSubscriptions}
                    region={this.state.region}
                />)
            ||
            (this.state.requestType == 2
                &&
                <StandAloneSpn
                subscriptions={this.state.subscriptions}
                disabled={this.state.disabled}
                updateText={this.updateText}
                editing={true}
                displayUpdateSecret={this.state.displayUpdateSecret}
                toggleSecretUpdate={this.toggleSecretUpdate}
                selectedSubscriptions={this.state.selectedSubscriptions}
                />)
            ||
            (this.state.requestType == 3
                &&
                <NetworkFirewall
                    disabled={this.state.disabled}
                    updateText={this.updateText}
                    addNetworkRule={this.addNetworkRule}
                    editNetworkRule={this.editNetworkRule}
                    deleteNetworkRule={this.deleteNetworkRule}
                    existingNetworkRules={this.state.networkFirewallRules}
                    editing={true}
                />)
            ||
            (this.state.requestType == 4
                &&
                <Kubernetes
                    disabled={this.state.disabled}
                    updateText={this.updateText}
                    editing={true}
                    projects={this.state.devopsProjects}
                    clusters={this.state.kubernetesClusters}
                />)
            ||
            (this.state.requestType == 5
                &&
                <ResourcePermissions
                    disabled={this.state.disabled}
                    updateText={this.updateText}
                    editing={true}
                    subscriptions={this.state.subscriptions}
                    aadGroups={this.state.aadGroups}
                />)
            ||
            (this.state.requestType == 6
                &&
                <RunADOPipeline
                    disabled={this.state.disabled}
                    updateText={this.updateText}
                    editing={true}
                />
            )

        return (
            <ChipModal
                isOpen={this.props.isRequestOpen}
                modalClassName="modal-90w"
                modalTitle="New Request"
                buttonText="Submit"
                disabled={!this.isNewRequestFormValid()}
                buttonAction={!this.state.submitting && this.submitRequest}
                toggleModal={this.props.toggleRequestModal}
                modalBody={
                    this.state.submitting ?
                        <LoadingCloud insideModal={true} />
                        :
                        <div className="container new-request">
                            <Dropdown
                                name="requestType"
                                label="Request Type"
                                tooltip="CreateSpnPlus will generate a SPN, Resource Group, and VSTS Endpoint. Standalone SPN will only create a SPN."
                                values={requestType.values}
                                texts={requestType.texts}
                                required="true"
                                errorMessage="Vsts project name is required"
                                update={this.updateText}
                                default={this.state.requestType}
                                disabled={false}
                                editing={true}
                            />

                            {request}

                            <Textbox
                                name="requesterManagerName"
                                type="text"
                                label="Requester Manager Name"
                                tooltip="The requestor manager name"
                                maxlength="250"
                                required="true"
                                errorMessage="Must not be empty."
                                update={this.updateText}
                                disabled={false}
                                default={this.state.requesterManagerName}
                            />
                            <Textbox
                                name="requesterManagerEmail"
                                type="email"
                                label="Requester Manager Email"
                                tooltip="The requestor manager email"
                                maxlength="250"
                                required="true"
                                errorMessage="Must not be empty, and a valid email address less than 50 characters."
                                update={this.updateText}
                                disabled={false}
                                default={this.state.requesterManagerEmail}
                            />
                            <Textbox
                                name="requesterTeamName"
                                type="text"
                                label="Requester Team Name"
                                tooltip="The requestor team name"
                                maxlength="250"
                                required="true"
                                errorMessage="Must not be empty."
                                default={this.props.groupName}
                                update={this.updateText}
                                disabled={this.props.isDisabled}
                            />

                        </div>
                }

            />


        );
    }
}