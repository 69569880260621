import React, { Component } from 'react';
import '../css/NewApplication.css';
import { Textbox } from './Textbox';
import { Dropdown } from './Dropdown';
import { Checkbox } from './Checkbox';
import { ChipButton } from './ChipButton';
import { Redirect } from 'react-router-dom'
import { auth } from '../adalConfig';
import { authenticatedApiFetch } from '../helpers/FetchHelper';
import { getAzureDevOpsProjects } from '../helpers/AzureDevOpsHelper';
import stringSimilarity from 'string-similarity';
import { ErrorContext } from "../ErrorContext";

export class NewApplication extends Component {
    static displayName = "New Application";
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.updateText = this.updateText.bind(this);
        this.submit = this.submit.bind(this);
        this.setContext = this.setContext.bind(this);
        this.updateVstsUrlText = this.updateVstsUrlText.bind(this);
        this.getExistingApplicationNames = this.getExistingApplicationNames.bind(this);
        this.checkForExistingApplication = this.checkForExistingApplication.bind(this);
        this.disableSubmit = this.disableSubmit.bind(this);

        this.state = {
            redirect: false,
            submitting: false,
            error: false,
            applicationName: null,
            productName: null,
            description: null,
            teamName: null,
            teamEmail: null,
            productOwnerName: null,
            productOwnerEmail: null,
            pii: false,
            pci: false,
            disasterRecoveryPlan: false,
            escalation: false,
            solutionDesign: false,
            governance: false,
            security: false,
            vstsProjectNames: [],
            vstsPortalUrl: null,
            vstsProjectName: null,
            tiers: null,
            employeeId: null,
            existingApplications: [],
            alert: false,
            isAzureDevops: false,
        };
    }

    updateText(name, value) {
        if (name == "githubOrganization") {
            this.updateRepo(value);
        }
        this.setState({
            [name]: value
        });
    }
    updateVstsUrlText(name, value) {
        this.setState({
            [name]: value,
            vstsPortalUrl: `https://dev.azure.com/itsals/${value}`
        });
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={"/ApplicationInformation/Details/" + this.state.appID} />
        }
    }

    setContext(err, context) {
        this.setState({ userContext: context }, this.setUserID);
    }

    async setUserID() {
        try {
            let userResponse = await authenticatedApiFetch('api/Auth/GetUserIdentity?objectId=' + this.state.userContext.profile.oid, {});
            let user = await userResponse.json();
            this.setState({
                employeeId: user.employeeId
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    componentDidMount() {
        auth.then((response) => {
            response.authContext.getUser(this.setContext);
            this.updateVstsProject();
            this.getExistingApplicationNames();
        });
    }

    async updateVstsProject() {
        var vstsProjectList = await getAzureDevOpsProjects();
        this.setState({
            vstsProjectNames: vstsProjectList
        });
    }

    submit() {

        let payload = this.constructPayload();
        this.setState({
            submitting: true,
            error: false
        });

        this.sendToChipDB(payload);
    }

    formatClassifications() {
        let classifications = []
        if (!this.state.pii && !this.state.pci) {
            classifications.push(0);
        } else {
            if (this.state.pii) {
                classifications.push(1);
            }

            if (this.state.pci) {
                classifications.push(2);
            }
        }
        return classifications;
    }

    async getExistingApplicationNames() {
        try {
            let applicationsResponse = await authenticatedApiFetch('api/ApplicationInformation/GetApplicationNames', {});
            let existingApps = await applicationsResponse.json();
            this.setState({ existingApplications: existingApps });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    disableSubmit() {
      let disablestate = false;
      for (const [key, value] of Object.entries(this.state)) {
        if (key === "vstsProjectName" || key === "vstsPortalUrl") {
          if (value == null || value === "") {
            disablestate = true;
          }
        } else {
          if (value === null || value === "") {
            disablestate = true;
          }
        }
      }
      return disablestate;
    }

    checkForExistingApplication() {
        if (this.state.applicationName && this.state.existingApplications) {
            var matches = stringSimilarity.findBestMatch(this.state.applicationName.toLowerCase(), this.state.existingApplications);
            var bestMatch = matches.bestMatch;
            var alert = false;

            if (bestMatch.rating === 1) {
                alert = {
                    color: 'danger',
                    text: `An application with the name '${bestMatch.target}' already exists!`
                }
                this.setState({ applicationName: null });
            }
            else if (bestMatch.rating > 0.6) {
                alert = {
                    color: 'warning',
                    text: `An existing application with a similar name was found: '${bestMatch.target}'. Please check if this application fulfills your needs.`
                }
            }

            this.setState({ alert: alert });
        }
    }

    constructPayload() {
        return {
            "applicationName": this.state.applicationName,
            "productName": this.state.productName,
            "description": this.state.description,
            "teamName": this.state.teamName,
            "teamEmail": this.state.teamEmail,
            "productOwner": {
                "contactInfo": {
                    "name": this.state.productOwnerName,
                    "employeeId": "TBD", // ! Fill in later
                    "email": this.state.productOwnerEmail
                }
            },
            "classifications": this.formatClassifications(),
            "checkList": {
                "disasterRecoveryPlan": Boolean(this.state.disasterRecoveryPlan),
                "escalation": Boolean(this.state.escalation),
                "solutionDesign": Boolean(this.state.solutionDesign),
                "governance": Boolean(this.state.governance),
                "security": Boolean(this.state.security)
            },
            "vstsInformation": {
                "vstsProjectName": this.state.vstsProjectName,
                "vstsPortalUrl": this.state.vstsPortalUrl
            },
            "createdBy": {
                "name": this.state.userContext.profile.name,
                "employeeId": this.state.employeeId,
                "email": this.state.userContext.profile.upn
            },
            "tierTypes": [
                this.state.tiers
            ]
        };
    }

    sendToChipDB(payload) {
        authenticatedApiFetch('api/ApplicationInformation/InsertApplicationInformation', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(payload)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response);
            }
        }).then((data) => {
            this.setState({
                submitting: false
            });
            this.setState({
                appID: data.id,
                redirect: true
            })
        }).catch((error) => {
            this.setState({
                submitting: false,
                error: true
            });
            this.context.handleError(error);
            throw error;
        });
    }

    render() {
        let tiers = {
            texts: [
                'Tier 0 - Critical Systems like middleware that supports the Tier 1 and 2 systems.',
                'Tier 1 - Critical Systems with immediate (< 1 hour) impact and escalating downstream operation impact.',
                'Tier 2 - Critical Systems with non-immediate impact (> 1 hour to 4 hours) with escalating impact over 24 hours.',
                'Tier 3 - Non-Critical Systems.'
            ],
            values: [
                0,
                1,
                2,
                3
            ]
        };

        return (
          <div>
            {this.renderRedirect()}
            <div
              className={`alert alert-danger ${
                this.state.error ? "" : "d-none"
              }`}
              role="alert"
            >
              An error has occurred. Please contact Cloud Core Team at {" "}

              <a href="mailto:CloudCoreTeam@Alaskaair.com">
                CloudCoreTeam@Alaskaair.com
              </a>
            </div>
            <div className="body-container white-background">
              <h1 className="new-application-header chip-header">
                New Application
              </h1>
              <div className="container">
                <div className="row">
                  <div className="col-md new-application-col-left">
                    <hr className="d-md-block d-none" />
                    <hr className="new-application-hr-no-padding d-block d-md-none" />
                    <div className="new-application-input-container">
                      <Textbox
                        name="applicationName"
                        type="text"
                        label="Application Name"
                        tooltip="The name of the application"
                        maxlength="50"
                        pattern="^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$"
                        required="true"
                        errorMessage="Must not be empty, and only letters and/or numbers and less than 50 characters."
                        update={this.updateText}
                        onBlur={this.checkForExistingApplication}
                        alert={
                          this.state.alert && {
                            color: this.state.alert.color,
                            text: this.state.alert.text,
                          }
                        }
                      />
                      <Textbox
                        name="productName"
                        type="text"
                        label="Product Name"
                        tooltip="The product name of the application"
                        maxlength="50"
                        required="true"
                        errorMessage="Must not be empty, and less than 50 characters."
                        update={this.updateText}
                      />
                      <Textbox
                        name="description"
                        type="text"
                        label="Description"
                        tooltip="The description and purpose of the application"
                        maxlength="250"
                        required="true"
                        errorMessage="Must not be empty, and less than 250 characters."
                        update={this.updateText}
                      />
                      <Textbox
                        name="productOwnerName"
                        type="text"
                        label="Product Owner's Name"
                        tooltip="The product owner of the application"
                        maxlength="50"
                        required="true"
                        errorMessage="Must not be empty, and less than 50 characters."
                        update={this.updateText}
                      />
                      <Textbox
                        name="productOwnerEmail"
                        type="email"
                        label="Product Owner's Email"
                        tooltip="The product owner's email address"
                        maxlength="50"
                        required="true"
                        errorMessage="Must not be empty, and a valid email address less than 50 characters."
                        update={this.updateText}
                      />
                      <Textbox
                        name="teamName"
                        type="text"
                        label="Team Name"
                        tooltip="The name of the team that owns/manages the application"
                        maxlength="50"
                        required="true"
                        errorMessage="Must not be empty, and less than 50 characters."
                        update={this.updateText}
                      />
                      <Textbox
                        name="teamEmail"
                        type="email"
                        label="Team Email"
                        tooltip="The email address of the team that owns/manages the application"
                        maxlength="100"
                        required="true"
                        errorMessage="Must not be empty, and a valid email address less than 50 characters."
                        update={this.updateText}
                      />
                    </div>
                  </div>
                  <div className="vertical-line d-none d-md-block"></div>
                  <div className="col-md new-application-col-right">
                    <hr className="d-none d-md-block" />
                    <div className="new-application-input-container">
                    <h6 className="new-application-checkbox-header">
                        Deployment Pipeline
                        <span className={`input-required`}>*</span>
                      </h6>
                      <Dropdown
                        name="vstsProjectName"
                        label="Azure DevOps Project Name"
                        tooltip="The Azure DevOps project name. For example: AlaskAirCom, AirportCustomerExperience"
                        values={this.state.vstsProjectNames}
                        texts={this.state.vstsProjectNames}
                        errorMessage="Azure DevOps project name is required"
                        update={this.updateVstsUrlText}
                        required="true"
                      />
                      <Textbox
                        name="vstsPortalUrl"
                        type="url"
                        disabled={true}
                        label="Azure DevOps Portal URL"
                        tooltip="The Azure DevOps project URL"
                        maxlength="250"
                        errorMessage="Must not be empty, and a valid URL less than 250 characters."
                        default={this.state.vstsPortalUrl}
                        update={this.updateText}
                      />
                      <Dropdown
                        name="tiers"
                        label="Tiers"
                        tooltip="For more information please review our Tier Docs"
                        values={tiers.values}
                        texts={tiers.texts}
                        required="true"
                        errorMessage="Tier is required"
                        update={this.updateText}
                      />

                      <h6 className="new-application-checkbox-header">
                        Data Profile:
                      </h6>

                      <div className="container new-application-checkbox-container">
                        <div className="row">
                          <div className="col-md">
                            <Checkbox
                              name="pii"
                              label="PII"
                              default={false}
                              tooltip="Select if application has PII (Personally Identifiable Information) data"
                              update={this.updateText}
                            />
                          </div>
                          <div className="col-md">
                            <Checkbox
                              name="pci"
                              label="PCI"
                              default={false}
                              tooltip="Select if application has PCI (Payment Card) data"
                              update={this.updateText}
                            />
                          </div>
                        </div>
                      </div>

                      <h6 className="new-application-checkbox-header">
                        Select the documents you will provide before going to
                        production:
                      </h6>

                      <div className="container new-application-checkbox-container">
                        <div className="row">
                          <div className="col-md">
                            <Checkbox
                              name="disasterRecoveryPlan"
                              label="Disaster Recovery Plan"
                              default={false}
                              tooltip="A documented process, or set of procedures, to recover and protect a business IT infrastructure in the event of a disaster"
                              update={this.updateText}
                            />
                            <Checkbox
                              name="escalation"
                              label="Escalation"
                              default={false}
                              tooltip="A set of procedures in place to deal with potential problems in a variety of contexts"
                              update={this.updateText}
                            />
                            <Checkbox
                              name="governance"
                              label="Governance"
                              default={false}
                              tooltip="Follow Alaska naming and tagging standards"
                              update={this.updateText}
                            />
                          </div>
                          <div className="col-md">
                            <Checkbox
                              name="security"
                              label="Security Design"
                              default={false}
                              tooltip="Follow the security guidelines for cloud resources"
                              update={this.updateText}
                            />
                            <Checkbox
                              name="solutionDesign"
                              label="Solution Design"
                              default={false}
                              tooltip="High level overview diagram of the application's architecture"
                              update={this.updateText}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center">
                  <ChipButton
                    action={this.submit}
                    disabled={this.disableSubmit()}
                    buttonText="Submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
}