import React, { useEffect, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import SubscriptionDropDown from './SubscriptionDropDown'
import { getAllSubscriptions } from '../../helpers/SubscriptionHelper';
import { auth } from '../../adalConfig';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';

export function ResourcePermissions(props) {

    let [selectedSubscription, setSelectedSubscription] = useState(props.resourcePermissionsSubscription);
    let [fetchedSubscriptions, setFetchedSubscription] = useState([]);
    let [selectedResourceType, setResourceType] = useState(props.resourcePermissionsResourceType);
    let [authToken, setAuthToken] = useState();
    let [fetchedACSList, setFetchedACSList] = useState([]);
    let [fetchedStorageAccountList, setFetchedStorageAccountList] = useState([]);
    let [fetchedAzureADGroups, setFetchedAzureADGroups] = useState([]);
    let [selectedIdentityType, setIdentityType] = useState(props.resourcePermissionsIdentityType);
    let [selectedACS, setSelectedACS] = useState(props.resourcePermissionsACS);
    let [selectedStorageAccount, setSelectedStorageAccount] = useState(props.resourcePermissionsStorageAccount)
    let [selectedADGroup, setSelectedADGroup] = useState(props.resourcePermissionsADGroup);
    let [isInputtingOtherADGroup, setIsInputtingOtherADGroup] = useState(false);
    let [SPNObjectID, setSPNObjectID] = useState(props.resourcePermissionsSPNObjectID);
    let [MSIObjectID, setMSIObjectID] = useState(props.resourcePermissionsMSIObjectID);

    const updateSubscriptionList = async () => {
        var subscriptionList = await getAllSubscriptions(true);
        setFetchedSubscription(subscriptionList)
    }

    useEffect(() => {

        auth.then((response) => {
            setAuthToken(response.getToken());
        });
    }, [])

    useEffect(() => {
        if (authToken) {
            updateSubscriptionList();
            if (selectedResourceType == "App Config Service") {
                fetchAppConfigServiceList();
            } else if (selectedResourceType == "Storage Account") {
                fetchStorageAccountList();
            }
            fetchAzureADGroups();
        }
    }, [authToken])

    const handleSubscriptionChange = (name, value) => {
        setSelectedSubscription(value);
        props.updateText(name, value);
    }

    const handleResourceTypeChange = (name, value) => {
        setResourceType(value);
        if (value == "App Config Service") {
            fetchAppConfigServiceList();
        } else if (value == "Storage Account") {
            fetchStorageAccountList();
        }
        props.updateText(name, value)
    }

    const fetchAppConfigServiceList = async () => {
        if (authToken) {
            let response = await authenticatedApiFetch('api/ResourceManagement/GetAppConfigServicesInSubscription?subscriptionId=' + selectedSubscription, {}, authToken);
            if (!response.ok) {
                throw new Error(response);
            }
            let acsList = await response.json();
            setFetchedACSList(acsList.value);
        }
    }

    const fetchStorageAccountList = async () => {
        if (authToken) {
            let response = await authenticatedApiFetch('api/ResourceManagement/GetStorageAccountsInSubscription?subscriptionId=' + selectedSubscription, {}, authToken);
            if (!response.ok) {
                throw new Error(response);
            }
            let storageAccountList = await response.json();
            setFetchedStorageAccountList(storageAccountList.value);
        }
    }

    const fetchAzureADGroups = async () => {
        if (authToken) {
            let response = await authenticatedApiFetch('api/Auth/GetAzureAccessAdGroups', {}, authToken);
            if (!response.ok) {
                throw new Error(response);
            }
            let azureAdGroupList = await response.json();

            if (props.resourcePermissionsADGroup && !azureAdGroupList.map(adg => adg.objectId).includes(props.resourcePermissionsADGroup)) {
                setIsInputtingOtherADGroup(true);
            }

            setFetchedAzureADGroups(azureAdGroupList);
        }
    }

    const handleACSChange = (name, value) => {
        setSelectedACS(value);
        props.updateText(name, value)
    }

    const storageAccountChange = (name, value) => {
        setSelectedStorageAccount(value);
        props.updateText(name, value)
    }

    const handleIdentityTypeChange = (name, value) => {
        if (value == "Azure AD Group") {
            fetchAzureADGroups();
        }
        setIdentityType(value);
        props.updateText(name, value)
    }

    const handleAzureADChange = (name, value) => {
        if (value == "other_id") {
            setIsInputtingOtherADGroup(true);
        } else {
            setIsInputtingOtherADGroup(false);
            setSelectedADGroup(value);
            props.updateText(name, value)
        }
    }

    const handleAzureADGroupObjID = (name, value) => {
        setSelectedADGroup(value);
        props.updateText(name, value)
    }

    const handleSPNObjectIDChange = (name, value) => {
        setSPNObjectID(value);
        props.updateText(name, value);
    }

    const handleMSIObjectIDChange = (name, value) => {
        setMSIObjectID(value);
        props.updateText(name, value);
    }

    return (
        <div>
            <Dropdown
                name="resourcePermissionsSubscription"
                label="Resource Subscription"
                tooltip="The subscription your resource lives in."
                values={fetchedSubscriptions.map(s => s.subscriptionId)}
                texts={fetchedSubscriptions.map(s => s.subscriptionName)}
                required={true}
                errorMessage="Subscription is required"
                update={handleSubscriptionChange}
                default={selectedSubscription}
                disabled={props.disabled}
            />
            <Dropdown
                name="resourcePermissionsResourceType"
                label="Resource Type"
                tooltip="The type of resource you wish to apply permission to."
                values={["App Config Service", "Storage Account"]}
                texts={["App Config Service", "Storage Account"]}
                required={true}
                errorMessage="Resource Type is required"
                update={handleResourceTypeChange}
                default={selectedResourceType}
                disabled={props.disabled}
            />
            {selectedResourceType == "App Config Service" &&
                <Dropdown
                    name="resourcePermissionsACS"
                    label="App Config Service Name"
                    tooltip="Select the ACS you would like to apply Data Reader permissions to."
                    values={fetchedACSList.map(acs => acs.id)}
                    texts={fetchedACSList.map(acs => acs.name)}
                    update={handleACSChange}
                    disabled={props.disabled}
                    default={selectedACS}
                />
            }
            {selectedResourceType == "Storage Account" &&
                <Dropdown
                    name="resourcePermissionsStorageAccount"
                    label="Storage Account Name"
                    tooltip="Select the Storage Account you would like to apply Blob Reader permissions to."
                    values={fetchedStorageAccountList.map(sa => sa.id)}
                    texts={fetchedStorageAccountList.map(sa => sa.name)}
                    update={storageAccountChange}
                    disabled={props.disabled}
                    default={selectedStorageAccount}
                />
            }

            <Dropdown
                name="resourcePermissionsIdentityType"
                label="Identity Type"
                tooltip="The type of identity you wish to use for the permissions on the resource."
                values={["Azure AD Group", "SPN", "Managed Service Identity"]}
                texts={["Azure AD Group", "SPN", "Managed Service Identity"]}
                required={true}
                errorMessage="Identity Type is required"
                update={handleIdentityTypeChange}
                disabled={props.disabled}
                default={selectedIdentityType}
            />

            {selectedIdentityType == "Azure AD Group" &&
                <Dropdown
                    name="resourcePermissionsADGroup"
                    label="Azure AD Group Name"
                    tooltip="Select the Azure AD Group you would like to use to apply permissions."
                    values={["other_id", ...fetchedAzureADGroups.map(adg => adg.objectId)]}
                    texts={["Other (Input ObjectID)", ...fetchedAzureADGroups.map(adg => adg.displayName)]}
                    update={handleAzureADChange}
                    disabled={props.disabled}
                    default={selectedADGroup}
                />
            }

            {isInputtingOtherADGroup &&
                <Textbox
                    name="resourcePermissionsADGroup"
                    type="text"
                    label="Azure AD Group Object ID"
                    tooltip="The Object ID for the Azure AD Group you would like to use to apply permissions."
                    maxlength="40"
                    update={handleAzureADGroupObjID}
                    disabled={props.disabled}
                    default={selectedADGroup}
                />
            }

            {selectedIdentityType == "SPN" &&
                <Textbox
                    name="resourcePermissionsSPNObjectID"
                    type="text"
                    label="SPN Object ID"
                    tooltip="The Object ID for the SPN you would like to use to apply permissions."
                    maxlength="40"
                    update={handleSPNObjectIDChange}
                    disabled={props.disabled}
                    default={SPNObjectID}
                />
            }

            {selectedIdentityType == "Managed Service Identity" &&
                <Textbox
                    name="resourcePermissionsMSIObjectID"
                    type="text"
                    label="MSI Object ID"
                    tooltip="The Object ID for the MSI you would like to use to apply permissions."
                    maxlength="40"
                    update={handleMSIObjectIDChange}
                    disabled={props.disabled}
                    default={MSIObjectID}
                />
            }

        </div>
    )
}