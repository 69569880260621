import React, { Component } from "react";
import { Dropdown } from "../Dropdown";
import { getSubscriptionsRestCall } from "../../helpers/SubscriptionHelper";

export class ReportSelectorDropdowns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionNames: this.props.preFilteredSubscriptionNames,
      reportType: this.props.reportType,
      subscriptionName: this.props.subscriptionName,
      fetchedSubscriptions: this.props.preFetchedSubscriptionNames,
    };

    this.updateText = this.updateText.bind(this);
    this.updateSubscriptionList = this.updateSubscriptionList.bind(this);
  }

  componentDidMount() {
    this.updateSubscriptionList();
  }

  async updateSubscriptionList() {
    var subscriptionList = await getSubscriptionsRestCall(
      this.state.reportType
    );
    this.setState({ subscriptionNames: subscriptionList });
  }

  updateText(name, value) {
    this.setState(
      {
        [name]: value,
      },
      this.updateSubscriptionList
    );
    this.props.updateText(name, value);
  }

  render() {
    let reportTypes = [
      "App Service Alerts",
      "App Service Plan Alerts",
      "App Service Plan Autoscale",
      "App Service Plan Cost Optimization",
      "Azure Analytics",
      "Azure SQL Cost Optimization Report",
      "Cosmos Cost Optimization Report",
      "Subscription Details",

    ];
    let reportTypeValues = [
      "appservicealert",
      "appserviceplanalert",
      "autoscale",
      "appserverfarmhealth",
      "analytics",
      "dbdtuconsumption",
      "cosmoscosthealth",
      "subscriptiondetails",
    ];

    return (
      <div
        className={`report-modal-container ${
          this.props.reportSelectorClass ? this.props.reportSelectorClass : ""
        }`}
      >
        {this.state.subscriptionNames && (
          <div>
            <Dropdown
              name="reportType"
              label="Report Type"
              tooltip=""
              values={reportTypeValues}
              texts={reportTypes}
              errorMessage="Vsts project name is required"
              update={this.updateText}
              default={this.state.reportType}
              disabled={false}
            />
            <Dropdown
              name="subscriptionName"
              label="Subscription"
              tooltip=""
              values={this.state.subscriptionNames}
              texts={this.state.subscriptionNames}
              errorMessage="Vsts project name is required"
              update={this.updateText}
              default={this.state.subscriptionName}
              disabled={false}
            />
          </div>
        )}
      </div>
    );
  }
}
