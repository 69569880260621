import React, { Component } from 'react';
import '../../../src/css/About.css'
import azureImg from '../../img/CHIPv1/microsoft_azure.svg';
import azureLogoImg from '../../img/CHIPv1/azure_logo_icon.svg';
import azureSysDiagram from '../../img/CHIPv1/azure_system_diagram.png';
import azureVirtualMachine from '../../img/CHIPv1/azure_virtual_machine.svg';
import azureApim from '../../img/CHIPv1/azure_apim_color.svg';
import azureAppService from '../../img/CHIPv1/azure_app_service_color.svg';
import azureAutomationRunbook from '../../img/CHIPv1/azure_automation_runbook_color.svg';
import azureDataFactory from '../../img/CHIPv1/azure_data_factory_color.svg';
import azureFunctionApp from '../../img/CHIPv1/azure_function_app.svg';
import azureServiceBus from '../../img/CHIPv1/azure_service_bus.svg';
import azureCosmos from '../../img/CHIPv1/azure_cosmos_db_color.svg';
import azureDataBrick from '../../img/CHIPv1/azure_databricks.svg';
import azureExpressRoute from '../../img/CHIPv1/azure_express_route_color.svg';
import azureSql from '../../img/CHIPv1/azure_sql_database_generic.svg';
import azureKeyVault from '../../img/CHIPv1/azure_key_vault.svg';
import Bob from '../../img/CHIPv1/Bob.svg';
import Humzah from '../../img/CHIPv1/Humzah.svg';
import Beau from '../../img/CHIPv1/Beau.svg';
import Tej from '../../img/CHIPv1/tej.svg';
import defaultProfileImg from '../../img/CHIPv1/profile_image.svg';

export class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        document.body.style = 'background-color: white;';
    }
    render() {
        return (
            <div>
                <div className="about-container container">

                    <div className="row aboutbanner">
                        <div className="col">
                            <h1 className="bannertext">Cloud Hub Information Portal</h1>
                        </div>
                    </div>

                    <div className="about-page-details">
                        <div className="row">
                            <div className="col">

                                <div className="about-float-right">
                                    <div className="aboutcontenttitle">
                                        Everything Begins With CHIP
                    </div>
                                    <div className="aboutcontent">
                                        <p>CHIP provides a high-level intake process for cloud deployment requests. It’s being built in an iterative fashion based on user feedback.</p>
                                        <p>Delivery teams are unable to deploy new solutions without entering a request in CHIP.</p>
                                    </div>
                                </div>

                                <div className="about-float-left aboutcontentimage">
                                    <img src={azureImg} alt='azure' />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="about-float-left">
                                    <div className="aboutcontenttitle">
                                        The CHIP Philosophy
                    </div>
                                    <div className="aboutcontent">
                                        <p>Make it easy to do the right thing and allow delivery teams following standards and best practices to go as fast as they want. </p>
                                        <p>For delivery teams which are challenged in following standards, engage/educate/empower them towards the standards model. As a last resort restrict the ability (via automation and communication) to deploy new releases. </p>
                                    </div>
                                </div>

                                <div className="about-float-right aboutcontentimage">
                                    <img src={azureLogoImg} alt='azure logo' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style={{ "width": "100%" }} />

                    <div className="row">
                        <div className="col">
                            <div className="aboutcontenttitle">
                                CHIP- Behind the Scenes Automation
            </div>

                            <div className="about-system-diagram">
                                <img src={azureSysDiagram} alt='azure sys diagram' />
                            </div>
                        </div>
                    </div>

                    <hr style={{ "width": "100%" }} />

                    <div className="row">

                        <div className="about-icon-container col-lg-4 col-sm-12 icon-row row">
                            <div className="col"><img src={azureVirtualMachine} /></div>
                            <div className="col"><img src={azureApim} /></div>
                            <div className="col"><img src={azureAppService} /></div>
                            <div className="col"><img src={azureAutomationRunbook} /></div>
                        </div>

                        <div className="about-icon-container col-lg-4 col-sm-12 icon-row row">
                            <div className="col"><img src={azureDataFactory} /></div>
                            <div className="col"><img src={azureFunctionApp} /></div>
                            <div className="col"><img src={azureServiceBus} /></div>
                            <div className="col"><img src={azureCosmos} /></div>
                        </div>

                        <div className="about-icon-container col-lg-4 col-sm-12 icon-row row">
                            <div className="col"><img src={azureDataBrick} /></div>
                            <div className="col"><img src={azureExpressRoute} /></div>
                            <div className="col"><img src={azureSql} /></div>
                            <div className="col"><img src={azureKeyVault} /></div>
                        </div>

                    </div>

                    <hr style={{ "width": "100%" }} />



                    <div className="row cloud-geniuses-container">
                        <div className="col contact-button-container">
                            <button type="button" className="btn btn-primary about-page-contact-btn"><a className='contact-us-link' href='mailto:cloudrequests@alaskaair.com'>Contact Us</a></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}