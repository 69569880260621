import React, { Component } from 'react';
import { List } from '../List';
import { ChipButton } from '../ChipButton';

export class ResourceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPagination: this.props.showPagination ? this.props.showPagination : "",
            buttonText: "More"
        };
        this.showAllRequest = this.showAllRequest.bind(this);
    }

    showAllRequest() {
        this.setState({
            showPagination: !this.state.showPagination,
            buttonText: this.state.showPagination ? "Less" : "More"
        });
    }

    render() {
        let completionDateFormatter = (cell, row) => {
            if (cell) {
                let visibleDate = new Date(cell).toLocaleDateString();
                return visibleDate == "1/1/1" ? "NA" : visibleDate
            }
        }

        let columns = [
            {
                text: 'Subscription',
                dataField: 'subscriptions',
                formatter: subFormatter,
                sort: true
            },
            {
                text: 'Resource Group',
                dataField: 'resourceGroupName',
                formatter: subFormatter,
                sort: true
            },
            {
                text: 'SPN Name',
                dataField: 'spnName',
                sort: true
            },
            {
                text: 'Created Date',
                dataField: 'creationDate',
                sort: true,
                formatter: completionDateFormatter
            }
        ];

        function subFormatter(cell) {
            return (cell.length > 16 ? `${cell.substring(0, 16)}...` : cell);
        }

        return (
            this.props.requestList != null
                ?
                <div className="container app-details-card app-details-request-table">
                    <div className="row">
                        <List
                            data={this.props.requestList}
                            columns={columns}
                            keyField='requestId'
                            hideSearch={this.props.hideSearch == null ? true : this.props.hideSearch}
                            showPagination={this.state.showPagination}
                            bordered={false}
                            sizePerPage={5}
                            hideSizePerPage={true}
                            hidePageListOnlyOnePage={true}
                            rowClasses="interactive-table-row"
                            listClassName={this.props.listClassName}
                        />
                    </div>
                    <div className={"col " + (this.props.requestList.length > 5 ? 'more-detail-request-btn' : 'more-detail-request-btn-hidden')}>
                        <ChipButton
                            color="secondary"
                            action={this.showAllRequest}
                            buttonText={this.state.buttonText}
                        />
                    </div>
                </div>
                :
                <div> No Resources! </div>
        );
    }
}