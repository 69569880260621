import React, { Component } from 'react';

export class AutoscaleModalContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0
        };
        this.gradeForMobile  = this.gradeForMobile.bind(this)
    }

    componentDidMount() {
        this.findAndSetAlertValues(this.props.resourceDetailsData);
        window.addEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    gradeForMobile(grade) {
        if (this.state.width < 600) {
            switch (grade) {
                case "FullyCompliant":
                    return "Fully";
                    break;
                case "NonCompliant":
                    return "Non";
                    break;
                case "PartiallyCompliant":
                    return "Partially";
                    break;
            }
        } else { return grade;}
    }

    findAndSetAlertValues(resourceDetailsData) {
        for (var resourceDetailDataIndex in resourceDetailsData) {
            var currentRule = resourceDetailsData[resourceDetailDataIndex];
            this.setState({ [currentRule.resourceDetailName]: { grade: currentRule.resourceDetailGrade, value: currentRule.resourceDetailValue } });
        }
    }

    render() {

        return (
            <div>
                <div className="lgmod-group">
                    <div className="row lgmod-row">
                        <div className="col-12 lgmod-section-title">
                            CPU
                            </div>
                    </div>

                    <div className="row lgmod-row">
                        <div className="row lgmod-row lgmod-box-title-row lgmod-padded-row">

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Range:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Value:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Instance Count:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Cool Down Time:</h3>
                                </div>

                            </div>
                        </div>

                        <div className="row lgmod-row lgmod-padded-row">
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>&lt; 50%</div>
                                <div>&gt; 65%</div>
                                <div>&gt; 95%</div>
                            </div>
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleInThreshold && this.state.CpuPercentageScaleInThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMinThreshold && this.state.CpuPercentageScaleOutMinThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMaxThreshold && this.state.CpuPercentageScaleOutMaxThreshold.grade)}</div>
                            </div>
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleInInstanceCount && this.state.CpuPercentageScaleInInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMinInstanceCount && this.state.CpuPercentageScaleOutMinInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMaxInstanceCount && this.state.CpuPercentageScaleOutMaxInstanceCount.grade)}</div>
                            </div>


                            <div className="col-3 lgmod-box lgmod-col">
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleInCooldownTime && this.state.CpuPercentageScaleInCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMinCooldownTime && this.state.CpuPercentageScaleOutMinCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.CpuPercentageScaleOutMaxCooldownTime && this.state.CpuPercentageScaleOutMaxCooldownTime.grade)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lgmod-group">
                    <div className="row lgmod-row">
                        <div className="col-12 lgmod-section-title">
                            HTTP Queue Length
                            </div>
                    </div>

                    <div className="row lgmod-row">
                        <div className="row lgmod-row lgmod-box-title-row lgmod-padded-row">

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Range:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Value:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Instance Count:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Cool Down Time:</h3>
                                </div>

                            </div>
                        </div>

                        <div className="row lgmod-row lgmod-padded-row">
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>= 0</div>
                                <div>&gt; 5</div>
                                <div>&gt; 35</div>
                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleInThreshold && this.state.HttpQueueLengthScaleInThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMinThreshold && this.state.HttpQueueLengthScaleOutMinThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMaxThreshold && this.state.HttpQueueLengthScaleOutMaxThreshold.grade)}</div>

                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleInInstanceCount && this.state.HttpQueueLengthScaleInInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMinInstanceCount && this.state.HttpQueueLengthScaleOutMinInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMaxInstanceCount && this.state.HttpQueueLengthScaleOutMaxInstanceCount.grade)}</div>

                            </div>

                            <div className="col-3 lgmod-box lgmod-col">
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleInCooldownTime && this.state.HttpQueueLengthScaleInCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMinCooldownTime && this.state.HttpQueueLengthScaleOutMinCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.HttpQueueLengthScaleOutMaxCooldownTime && this.state.HttpQueueLengthScaleOutMaxCooldownTime.grade)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lgmod-group">
                    <div className="row lgmod-row">
                        <div className="col-12 lgmod-section-title">
                            Memory Percentage
                            </div>
                    </div>

                    <div className="row lgmod-row">
                        <div className="row lgmod-row lgmod-box-title-row lgmod-padded-row">

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Range:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Value:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Instance Count:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Cool Down Time:</h3>
                                </div>

                            </div>
                        </div>

                        <div className="row lgmod-row lgmod-padded-row">
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>&le; 70%</div>
                                <div>&gt; 85%</div>
                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleInThreshold && this.state.MemoryPercentageScaleInThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleOutThreshold && this.state.MemoryPercentageScaleOutThreshold.grade)}</div>

                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleInInstanceCount && this.state.MemoryPercentageScaleInInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleOutInstanceCount && this.state.MemoryPercentageScaleOutInstanceCount.grade)}</div>

                            </div>

                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleInCooldownTime && this.state.MemoryPercentageScaleInCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.MemoryPercentageScaleOutInstanceCount && this.state.MemoryPercentageScaleOutCooldownTime.grade)}</div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="lgmod-group">
                    <div className="row lgmod-row">
                        <div className="col-12 lgmod-section-title">
                            Disk Queue Length
                            </div>
                    </div>

                    <div className="row lgmod-row">
                        <div className="row lgmod-row lgmod-box-title-row lgmod-padded-row">

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Range:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Threshold Value:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Instance Count:</h3>
                                </div>

                            </div>

                            <div className="col-3 lgmod-col">

                                <div className="lgmod-title-wrapper">
                                    <h3 className="lgmod-box-title">Scale Out/In Cool Down Time:</h3>
                                </div>

                            </div>
                        </div>

                        <div className="row lgmod-row lgmod-padded-row">
                            <div className="col-3 lgmod-box lgmod-col">
                                <div>&lt; 50</div>
                                <div>&gt; 100</div>
                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleInThreshold && this.state.DiskQueueLengthScaleInThreshold.grade)}</div>
                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleOutThreshold && this.state.DiskQueueLengthScaleOutThreshold.grade)}</div>

                            </div>
                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleInInstanceCount && this.state.DiskQueueLengthScaleInInstanceCount.grade)}</div>
                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleOutInstanceCount && this.state.DiskQueueLengthScaleOutInstanceCount.grade)}</div>

                            </div>

                            <div className="col-3 lgmod-box lgmod-col">

                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleInCooldownTime && this.state.DiskQueueLengthScaleInCooldownTime.grade)}</div>
                                <div>{this.gradeForMobile(this.state.DiskQueueLengthScaleOutCooldownTime && this.state.DiskQueueLengthScaleOutCooldownTime.grade)}</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}