import React, { Component } from 'react';
import '../css/SitePage.css';
import { List } from './List';
import '../css/ApplicationList.css';
import { LoadingCloud } from './LoadingCloud';
import { fetch_retry } from '../helpers/FetchHelper';
import { ErrorContext } from "../ErrorContext";

export class ApplicationList extends Component {
    static displayName = "Applications";
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = { data: [], loading: true };
        this.fetchApplications = this.fetchApplications.bind(this);
    }


    async fetchApplications() {
        try {
            this.setState({ loading: true });
            let appResponse = await fetch_retry('api/ApplicationInformation/GetAllApplications', {});
            let appListData = await appResponse.json();
            this.renderData(appListData);
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    componentDidMount() {
        this.fetchApplications();
    }

    renderData(fetchedData) {
        var newData = [];
        for (var i = 0; i < fetchedData.length; i++) {
            var currentApp = fetchedData[i];

            newData[i] = {
                app_name: currentApp.applicationName,
                prod_name: currentApp.productName,
                team_name: currentApp.teamName,
                tier: currentApp.tierTypes != null ? currentApp.tierTypes[0] : "",
                cost: currentApp.cost,
                created_by: currentApp.createdBy.name,
                created_on: currentApp.creationDate,
                view_more: currentApp.id
            }
        }
        this.setState({ data: newData, loading: false });
    }


    render() {
        const columns = [
            {
                text: 'Application Name',
                dataField: 'app_name',
                sort: true,
                formatter: nameCellFormatter
            },
            {
                text: 'Product Name',
                dataField: 'prod_name',
                sort: true,
                formatter: nameCellFormatter
            },
            {
                text: 'Team Name',
                dataField: 'team_name',
                sort: true
            },
            {
                text: 'Tier',
                dataField: 'tier',
                sort: true
            },
            {
                text: 'Cost',
                dataField: 'cost',
                sort: true,
                formatter: costCellFormatter,
                sortFunc: (valueA, valueB, order) => {
                    let trimmedA = valueA.slice(1, valueA.length).replace(",", "");
                    let trimmedB = valueB.slice(1, valueB.length).replace(",", "");
                    let parsedA = parseFloat(trimmedA);
                    let parsedB = parseFloat(trimmedB);
                    if (order == "asc") return parsedA - parsedB; 
                    return parsedB - parsedA;
                }
            },
            {
                text: 'Created By',
                dataField: 'created_by',
                sort: true
            },
            {
                text: 'Created On',
                dataField: 'created_on',
                sort: true,
                formatter: dateCellFormatter
            },
        ];

        function dateCellFormatter(cell) {
            var dateTime = new Date(cell);
            var dateString = parseInt(dateTime.getMonth() + 1, 10) + "/" + dateTime.getDate() + "/" + dateTime.getFullYear();
            return (<div>{dateString}</div>)
        }

        function nameCellFormatter(cell) {
            return (cell.length > 20 ? `${cell.substring(0, 20)}...` : cell);
        }

        function costCellFormatter(cell) {
            return (cell ? cell : '$0.00');
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.history.push(`/ApplicationInformation/Details/${row.view_more}`);
            }
        };

        
        return (
            <div>
                <h1 className="chip-header list-header">Applications</h1>
                {this.state.loading
                    ?
                    <LoadingCloud />
                    :
                    <List
                        className="list"
                        data={this.state.data}
                        columns={columns}
                        keyField='view_more'
                        rowEvents={rowEvents}
                        rowClasses="interactive-table-row"
                        showPagination={true}
                    />
                    
                }

            </div>

        );
    }
}
