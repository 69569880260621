import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import "../../css/RequestDetails.css";
import { ChipButton } from '../ChipButton'
import { NetworkFirewall } from './NetworkFirewall';

export class NetworkFirewallContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestDetails: this.props.requestDetails,
            networkFirewallRules: this.props.requestDetails.networkFirewallRules,
            networkFirewallQuestions: this.props.requestDetails.networkFirewallQuestions,
            approver: this.getApprover(this.props.requestDetails)
        }
        this.updateText = this.updateText.bind(this);
        this.constructEditedRequestDetails = this.constructEditedRequestDetails.bind(this);
        this.saveRequest = this.saveRequest.bind(this);
        this.addNetworkRule = this.addNetworkRule.bind(this);
        this.editNetworkRule = this.editNetworkRule.bind(this);
        this.deleteNetworkRule = this.deleteNetworkRule.bind(this);
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    getApprover(reqDet) {
        debugger;
        if (reqDet.approver == null || reqDet.approver.contactInfo == null) {
            if (reqDet.approvalComments != null && reqDet.approvalComments.includes(":")) {
                let fullname = reqDet.approvalComments.slice(0, reqDet.approvalComments.indexOf(":"));
                let names = fullname.split(' ');
                return {
                    "contactInfo": {
                        "name": fullname,
                        "employeeId": null,
                        "email": names.join('.') + "@alaskaair.com"
                    },
                    "IsApproved": true
                }
            }
            else return {
                "contactInfo": {
                    "name": "Unknown",
                    "employeeId": null,
                    "email": "Unknown"
                },
                "IsApproved": true
            }
        }
        else return reqDet.approver;
   }

    saveRequest() {
        var updatedRequest = this.constructEditedRequestDetails();
        this.props.updateRequest(updatedRequest);
    }

    constructEditedRequestDetails() {
        var updatedRequestDetails = this.state.requestDetails;
        updatedRequestDetails.networkApplicationName = this.state.networkApplicationName;
        updatedRequestDetails.networkFirewallRules = this.state.networkFirewallRules;
        updatedRequestDetails.networkFirewallQuestions = this.state.networkFirewallQuestions;
        updatedRequestDetails.requester.contactInfo.name = this.state.requesterName;
        updatedRequestDetails.requester.contactInfo.email = this.state.requesterEmail;
        updatedRequestDetails.requester.manager.contactInfo.name = this.state.requesterManagerName;
        updatedRequestDetails.requester.manager.contactInfo.email = this.state.requesterManagerEmail;
        updatedRequestDetails.requester.groupName = this.state.requesterTeamName;
        return updatedRequestDetails;
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestDetails !== prevProps.requestDetails) {
            this.setState({ requestDetails: this.props.requestDetails });
        }
    }

    addNetworkRule(rule) {
        var currentList = this.state.networkFirewallRules;
        var newList = currentList ? currentList.concat(rule) : [rule];
        if (rule.bidirectional) {
            let reversedRule = Object.assign({}, rule);
            reversedRule.sourceDNS = rule.destinationDNS;
            reversedRule.sourceIP = rule.destinationIP;
            reversedRule.destinationDNS = rule.sourceDNS;
            reversedRule.destinationIP = rule.sourceIP;

            newList = newList.concat(reversedRule);
        }
        this.setState({ networkFirewallRules: newList });
    }

    editNetworkRule(rule, index) {
        var currentList = this.state.networkFirewallRules;
        if (currentList) {
            currentList[index] = rule;
        }
        this.setState({ networkFirewallRules: currentList });
    }

    deleteNetworkRule(networkRuleIndex) {
        var currentList = this.state.networkFirewallRules;
        currentList.splice(networkRuleIndex, 1);
        this.setState({ networkFirewallRules: currentList });
    }

    render() {
        return (
            <div className="request-details-container">
                {this.props.requestDetails &&
                    <div>
                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Type"
                            update={this.updateText}
                            default={this.props.requestDetails.requestTypeName}
                            disabled={true}
                        />

                        <Textbox
                            name="requestStatus"
                            type="text"
                            label="Request Status"
                            update={this.updateText}
                            default={this.props.requestDetails.requestStatusName}
                            disabled={true}
                        />

                        {this.props.requestDetails.approvalComments &&
                            <Textbox
                                name="approvalComments"
                                type="text"
                                label="Approval Comments"
                                update={this.updateText}
                                default={this.props.requestDetails.approvalComments}
                                disabled={true}
                            />
                        }


                        <NetworkFirewall
                            editing={this.props.editing}
                            networkApplicationName={this.props.requestDetails.networkApplicationName}
                            existingNetworkRules={this.state.networkFirewallRules}
                            existingNetworkQuestions={this.state.networkFirewallQuestions}
                            addNetworkRule={this.addNetworkRule}
                            editNetworkRule={this.editNetworkRule}
                            deleteNetworkRule={this.deleteNetworkRule}
                            updateText={this.updateText}
                        />

                        {this.props.requestDetails.cherwellInformation &&
                            <Textbox
                                name="cherwellTicketNumber"
                                type="text"
                                label="Cherwell Ticket Number"
                                default={this.props.requestDetails.cherwellInformation.cherwellTicketNumber}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="creationDate"
                            type="text"
                            label="Creation Date"
                            default={new Date(this.props.requestDetails.creationDate).toLocaleDateString()}
                            disabled={true}
                        />

                        {(this.props.requestDetails.requestStatus === 3 || this.props.requestDetails.requestStatus === 6 || this.props.requestDetails.requestStatus === 7) &&
                            <>
                                <Textbox
                                    name="completionDate"
                                    type="text"
                                    label="Completion Date"
                                    default={new Date(this.props.requestDetails.completionDate).toLocaleDateString()}
                                    disabled={true}
                                />

                                <Textbox
                                    name="completionDate"
                                    type="text"
                                    label="Approver Name"
                                    default={this.state.approver.contactInfo.name}
                                    disabled={true}
                                />

                                <Textbox
                                    name="completionDate"
                                    type="text"
                                    label="Approver Email"
                                    default={this.state.approver.contactInfo.email}
                                    disabled={true}
                                />
                            </>
                        }

                        <Textbox
                            name="requesterName"
                            type="text"
                            label="Requester Name"
                            default={this.props.requestDetails.requester.contactInfo.name}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterEmail"
                            type="email"
                            label="Requester Email"
                            default={this.props.requestDetails.requester.contactInfo.email}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterManagerName"
                            type="text"
                            label="Requester's Manager Name"
                            default={this.props.requestDetails.requester.manager.contactInfo.name}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterManagerEmail"
                            type="email"
                            label="Requester's Manager Email"
                            default={this.props.requestDetails.requester.manager.contactInfo.email}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        <Textbox
                            name="requesterTeamName"
                            type="text"
                            label="Requester's Team Name"
                            default={this.props.requestDetails.requester.groupName}
                            disabled={!this.props.editing}
                            update={this.updateText}
                            editing={this.props.editing}

                        />
                        {this.props.editing &&
                            <ChipButton
                                buttonClassName="save-button"
                                buttonText="Save"
                                action={this.saveRequest}
                                color='btn-success'
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}