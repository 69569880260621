import React, { Component } from 'react';
import { ChipModal } from '../ChipModal'
import '../../css/ReportDetailsModal.css';
import { DetailsAppInfo } from './DetailsAppInfo';
import { AppServicePlanAlertModalContent } from './AppServicePlanAlertModalContent'
import { AppServiceAlertModalContent } from './AppServiceAlertModalContent'
import { AutoscaleModalContent } from './AutoscaleModalContent'
import { AnalyticsModalContent } from './AnalyticsModalContent'

export class ReportDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertCompliance: "",
            reportCollectionDate: "",
            capacityMin: null,
            capacityDefault: null
        };

    }

    componentDidUpdate(prevProps) {
        if (this.props.appData !== prevProps.appData) {
            this.setCompliancePercentageAndReportDate(this.props.appData.resourceDetailsData);
            if (this.props.appData.resourceTypeId === 1) {
                this.setCapacity(this.props.appData.resourceDetailsData);
            }
        }
    }

    setCompliancePercentageAndReportDate(resourceDetailsData) {
        var percentComplianceDetailData = resourceDetailsData.find(x => x.resourceDetailName === "PercentCompliance");

        this.setState({
            alertCompliance: percentComplianceDetailData.resourceDetailValue,
            reportCollectionDate: percentComplianceDetailData.collectionTimestamp
        });

    }

    setCapacity(resourceDetailsData) {
        var capacityMinRule = resourceDetailsData.find(x => x.resourceDetailName === "CapacityMin");
        var capacityDefaultRule = resourceDetailsData.find(x => x.resourceDetailName === "CapacityDefault");

        this.setState({
            capacityMin: { grade: capacityMinRule.resourceDetailGrade, value: capacityMinRule.resourceDetailValue },
            capacityDefault: { grade: capacityDefaultRule.resourceDetailGrade, value: capacityDefaultRule.resourceDetailValue }
        });

    }

    render() {

        return (
            <div>
                {this.props.appData &&
                    <ChipModal
                        isOpen={this.props.isDetailsModalOpen}
                        toggleModal={this.props.toggleDetailsModal}
                        modalClassName="reports-details-modal"
                        modalBodyClassName="reports-details-modal-body"
                        modalTitle={
                            <div>
                                <h5 className="modal-title details-modal-title">Report Details</h5>
                                {this.props.appData.resourceTypeId === 1
                                    &&
                                    <h3 className="details-modal-subtitle">Autoscale Details</h3>
                                }

                                {this.props.appData.resourceTypeId === 2
                                    &&
                                    <h3 className="details-modal-subtitle">App Service Alert Details</h3>
                                }

                                {this.props.appData.resourceTypeId === 3
                                    &&
                                    <h3 className="details-modal-subtitle">App Service Plan Alert Details</h3>
                                }
                            </div>
                        }
                        modalBody={
                            <div>
                                <DetailsAppInfo
                                    resourceName={this.props.appData.resourceName}
                                    resourceGroupName={this.props.appData.resourceGroupName}
                                    subscriptionName={this.props.appData.subscriptionName}
                                    alertCompliance={this.state.alertCompliance}
                                    reportCollectionDate={this.state.reportCollectionDate}
                                    capacityMin={this.state.capacityMin}
                                    capacityDefault={this.state.capacityDefault}
                                />

                                {this.props.appData.resourceTypeId === 1
                                    &&
                                    <AutoscaleModalContent
                                        resourceDetailsData={this.props.appData.resourceDetailsData}
                                    />
                                }

                                {this.props.appData.resourceTypeId === 2
                                    &&
                                    <AppServiceAlertModalContent
                                        resourceDetailsData={this.props.appData.resourceDetailsData}
                                    />
                                }

                                {this.props.appData.resourceTypeId === 3
                                    &&
                                    <AppServicePlanAlertModalContent
                                        resourceDetailsData={this.props.appData.resourceDetailsData}
                                    />
                                }

                                {this.props.appData.resourceTypeId === 13
                                    &&
                                    <AnalyticsModalContent
                                        resourceDetailsData={this.props.appData.resourceDetailsData}
                                    />
                                }

                            </div>
                        }
                    />
                }
            </div>
        );
    }
}