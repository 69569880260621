import React, { useEffect, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';

export function RunADOPipeline(props) {

    let [authToken, setAuthToken] = useState();
    let [devOpsProjectList, setDevOpsProjectList] = useState([]);
    let [pipelinesInProject, setPipelinesInProject] = useState([]);

    let [selectedProjectName, setSelectedProject] = useState(props.selectedProjectName);
    let [selectedPipelineID, setSelectedPipeline] = useState(props.selectedPipelineID);
    let [pipelineDetails, setPipelineDetails] = useState(props.selectedPipelineDetails);
    let [pipelineVariables, setPipelineVariables] = useState(props.selectedPipelineVariables || []);

    useEffect(() => {
        fetchADOProjects();
        fetchPipelinesInProject();
        fetchPipelineDetails();
    })

    useEffect(() => { fetchPipelinesInProject(); }, [props.selectedProjectName, selectedProjectName]);

    useEffect(() => {
        if (selectedPipelineID && selectedProjectName) fetchPipelineDetails();
    }, [props.selectedPipelineID, selectedPipelineID]);

    const fetchADOProjects = async () => {
        let response = await authenticatedApiFetch('api/AzureDevOps/GetAllProjectNames', {});
        if (!response.ok) {
            throw new Error(response);
        }
        let projectListRes = await response.json();
        let projectList = [];
        projectListRes.map((devOpsProject) => projectList.push(devOpsProject.name));
        setDevOpsProjectList(projectList);
    }

    const fetchPipelinesInProject = async () => {
        let response = await authenticatedApiFetch(`api/AzureDevOps/GetPipelinesInProject?projectName=${selectedProjectName}`, {});
        if (!response.ok) {
            throw new Error(response);
        }
        let pipelineListRes = await response.json();
        setPipelinesInProject(pipelineListRes);
    }


    const fetchPipelineDetails = async () => {
        if (selectedProjectName && selectedPipelineID) {

            let response = await authenticatedApiFetch(`api/AzureDevOps/GetPipelineDetails?projectName=${selectedProjectName}&pipelineID=${selectedPipelineID}`, {});
            if (!response.ok) {
                throw new Error(response);
            }

            let pipelineDetails = await response.json();

            if (pipelineVariables.length == 0) {
                let pipelineVariablesDefaults = [];
                Object.keys(pipelineDetails.variables).forEach((x) => {
                    pipelineVariablesDefaults.push({
                        name: x,
                        value: pipelineDetails.variables[x].value,
                    })
                });

                setPipelineVariables(pipelineVariablesDefaults);
                props.updateText("runADOPipelineVariables", pipelineVariablesDefaults);
            }

            setPipelineDetails(pipelineDetails);
        }
    }

    const handleProjectChange = (name, val) => {
        setSelectedProject(val);
        setSelectedPipeline("");
        props.updateText(name, val);
        props.updateText("runADOPipelineID", "");

    }

    const handlePipelineChange = (name, val) => {
        setSelectedPipeline(val);
        props.updateText("runADOPipelineID", val);
    }

    const handleVariableChange = (name, val) => {
        let variableIndex = pipelineVariables.findIndex((v) => v.name == name);
        let updatedVariables = [...pipelineVariables];
        updatedVariables[variableIndex] = { ...updatedVariables[variableIndex], value: val };
        setPipelineVariables(pipelineVariables);
        props.updateText("runADOPipelineVariables", updatedVariables);
    }

    return (
        <div>
            <Dropdown
                name="runADOPipelineProjectName"
                label="ADO Project Name"
                tooltip="The name of the ADO project where your pipeline lives."
                values={devOpsProjectList}
                texts={devOpsProjectList}
                required={true}
                errorMessage="Subscription is required"
                update={handleProjectChange}
                default={selectedProjectName}
                disabled={props.disabled}
            />
            <Dropdown
                name="runADOPipelineID"
                label="Pipeline"
                tooltip="The specific pipeline you wish to run"
                values={pipelinesInProject.map((x) => x.id)}
                texts={pipelinesInProject.map((x) => x.name)}
                required={true}
                errorMessage="Resource Type is required"
                update={handlePipelineChange}
                default={selectedPipelineID}
                disabled={props.disabled}
            />
            {pipelineDetails && Object.keys(pipelineDetails.variables).map((x) => {
                return (
                    <Textbox
                        name={x}
                        type="text"
                        label={x}
                        tooltip="A required pipeline variable to run this pipeline."
                        required={true}
                        update={handleVariableChange}
                        disabled={props.disabled}
                        default={pipelineVariables.length > 0 && pipelineVariables.find(variable => variable.name == x).value}
                    />
                );
            })}
        </div>
    )
}