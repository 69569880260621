import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import { NetworkRule } from './NetworkRule';
import { NetworkQuestions } from './NetworkQuestions';

export class NetworkFirewall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false
        };

    }

    render() {
        const generateKey = (pre) => {
            return `${pre}_${new Date().getTime()}`;
        }

        return (

            <div>
                <Textbox
                    name="networkApplicationName"
                    type="text"
                    label="Network Application Name"
                    tooltip="TBD"
                    maxlength="100"
                    required="true"
                    errorMessage="Must not be empty."
                    update={this.props.updateText}
                    default={this.props.networkApplicationName}
                    disabled={!this.props.editing}
                    editing={this.props.editing}
                />

                <div className="network-rules">
                    {this.props.existingNetworkRules &&
                        this.props.existingNetworkRules.map((rule, index) => {
                            return <div>
                                <NetworkRule
                                    key={generateKey(index)}
                                    addRule={this.props.addNetworkRule}
                                    editRule={this.props.editNetworkRule}
                                    deleteRule={this.props.deleteNetworkRule}
                                    ruleIndex={index}
                                    editing={this.props.editing}
                                    sourceDNS={rule.sourceDNS}
                                    sourceIP={rule.sourceIP}
                                    destinationDNS={rule.destinationDNS}
                                    destinationIP={rule.destinationIP}
                                    destinationPortProtocols={rule.destinationPortProtocols}
                                    protocol={rule.protocol}
                                    existingRule={true}
                                />
                            </div>
                        })
                    }
                </div>

                {this.props.editing &&
                    <NetworkRule
                        addRule={this.props.addNetworkRule}
                        editRule={this.props.editNetworkRule}
                        deleteRule={this.props.deleteNetworkRule}
                        editing={this.props.editing}
                        isRuleCardOpen={true}
                        existingRule={false}
                    />
                }

                <NetworkQuestions
                    update={this.props.updateText}
                    default={this.props.existingNetworkQuestions}
                    editing={this.props.editing}
                />

            </div>
        );
    }
}
