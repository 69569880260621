import React, { useState, useEffect} from 'react';
import '../css/AlertBanner.css';
import { UncontrolledAlert } from 'reactstrap';

export function AlertBanner(props) {
    const [showBanner, setBannerVisibilty] = useState(props.show == true);

    const onDismiss = () => setBannerVisibilty(false);

    useEffect(() => {
        setBannerVisibilty(props.show)
    }, [props.show]);

    return (
        <div>
            <UncontrolledAlert color="danger" isOpen={showBanner} toggle={onDismiss}>
                {props.body}
            </UncontrolledAlert>
        </div>
    );
}
