import { Integrations } from "@sentry/tracing";

export const sentry = new Promise((res, rej) =>
    fetch('api/Auth/GetSentryConfig')
        .then(response => response.json())
        .then(data => {
            let sentryData = {
                dsn: data.dsn,
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing(),
                ],
                environment: data.environment,
                tracesSampleRate: 1.0,
            }

            res(sentryData);

        }));
