import React, { Component } from 'react';
import '../css/List.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

export class List extends Component {

    render() {
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} rows
            </span>
        );
        const paginationOptions = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPage: this.props.sizePerPage,
            hideSizePerPage: this.props.hideSizePerPage,
            hidePageListOnlyOnePage: this.props.hidePageListOnlyOnePage,
        }

        return (
            <div className={`list-container`}>
                <ToolkitProvider
                    keyField={this.props.keyField}
                    data={this.props.data && this.props.data}
                    columns={this.props.columns}
                    search
                >
                    {props => (
                        <div className={this.props.listClassName}>
                            <SearchBar className={`search-bar ${this.props.hideSearch ? 'd-none' : ''}`} {...props.searchProps} />
                            <BootstrapTable
                                pagination={this.props.showPagination ? paginationFactory(paginationOptions) : null}
                                {...props.baseProps}
                                bordered={this.props.bordered}
                                rowClasses={this.props.rowClasses ? this.props.rowClasses : ''}
                                rowEvents={this.props.rowEvents ? this.props.rowEvents : {}}
                            />
                        </div>
                    )
                    }
                </ToolkitProvider>
            </div>

        );
    }

}
