import React, { Component, useState } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { ApplicationList } from './components/ApplicationList';
import { NewApplication } from './components/NewApplication';
import { ApplicationDetails } from './components/ApplicationDetails';
import { Reports } from './components/reports/Reports';
import { AllRequests } from './components/request/AllRequests';
import { Help } from './components/static_pages/Help';
import { About } from './components/static_pages/About';
import { NetworkRequest } from './components/request/NetworkRequest';
import { KubernetesAdmin } from './components/KubernetesAdmin';
import { KubernetesAdminForm } from './components/KubernetesAdminForm';
import { ErrorContext } from "./ErrorContext";
import * as Sentry from "@sentry/react";
import './css/App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


export default function App(props) {
    const [error, setError] = useState(false);

    return (
        
        <Layout error={error}>
            <ErrorContext.Provider value={{ handleError: setError }}>
                <Sentry.ErrorBoundary onError={setError} >
                    <Route exact path='/' component={Home} />
                    <Route path='/null' component={Home} errorHandler={setError}/>
                    <Route path='/signin-oidc' component={Home} errorHandler={setError}/>
                    <Route path='/ApplicationList' component={ApplicationList} errorHandler={setError}/>
                    <Route path='/NewApplication' component={NewApplication} errorHandler={setError}/>
                    <Route path='/Requests' component={AllRequests} errorHandler={setError}/>
                    <Route path='/Help' component={Help} errorHandler={setError}/>
                    <Route path='/About' component={About} errorHandler={setError}/>
                    <Route path='/ApplicationInformation/Details/:appID' component={ApplicationDetails} errorHandler={setError}/>
                    <Route path='/Reports/:reportType/:subscriptionName' component={Reports} errorHandler={setError}/>
                    <Route path='/NetworkRequest' component={NetworkRequest} errorHandler={setError}/>
                    <Route exact path='/Kubernetes' component={KubernetesAdmin} errorHandler={setError}/>
                    <Route exact path='/Kubernetes/NewCluster' component={KubernetesAdminForm} errorHandler={setError} />
                    <Route exact path='/Kubernetes/EditCluster/:id' component={KubernetesAdminForm} errorHandler={setError} />
                </Sentry.ErrorBoundary>
            </ErrorContext.Provider>
        </Layout>

    );
}

