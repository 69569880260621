import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import { Dropdown } from '../Dropdown';

export class NetworkQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            networkRequestDescription: null,
            networkRequestApplicationType: null,
            replacementDecomissionDate: null,
            sourceDestinationScope: null,
            isDestinationManagedApplication: null,
            destinationApplicationExplanation: null,
            networkRequestExpectedLife: null,
            temporaryDecomissionDate: null,
            previousTicketNumber: null,
            additionalComments: null
        };

        this.updateNetworkQuestion = this.updateNetworkQuestion.bind(this);
        this.setNetworkQuestionValues = this.setNetworkQuestionValues.bind(this);
        this.areNetworkQuestionsValid = this.areNetworkQuestionsValid.bind(this);
    }

    componentDidMount() {
        if (this.props.default) {
            this.setState({
                networkRequestApplicationType: this.pullAnswerFromDefaultProps(0),
                replacementDecomissionDate: this.pullAdditionalInformationFromDefaultProps(0),
                sourceDestinationScope: this.pullAnswerFromDefaultProps(1),
                isDestinationManagedApplication: this.pullAnswerFromDefaultProps(2),
                destinationApplicationExplanation: this.pullAdditionalInformationFromDefaultProps(2),
                networkRequestExpectedLife: this.pullAnswerFromDefaultProps(3),
                temporaryDecomissionDate: this.pullAdditionalInformationFromDefaultProps(3),
                previousTicketNumber: this.pullAnswerFromDefaultProps(4),
                additionalComments: this.pullAnswerFromDefaultProps(5),
                networkRequestDescription: this.pullAnswerFromDefaultProps(6),
            });
        }
    }

    pullAnswerFromDefaultProps = (index) => {
        return this.props.default[index] ? this.props.default[index].answer : "";
    }

    pullAdditionalInformationFromDefaultProps = (index) => {
        return this.props.default[index] ? this.props.default[index].additionalInformation : "";
    }

    setNetworkQuestionValues() {
        this.props.update("networkFirewallQuestions", [
            {
                question: "Is this request needed for a NEW, REPLACEMENT, or EXISTING application/service?",
                answer: this.state.networkRequestApplicationType,
                additionalInformation: this.state.replacementDecomissionDate ? this.state.replacementDecomissionDate : 'N/A'
            },
            {
                question: "Is the SOURCE and/or DESTINATION endpoint(s) considered CDE/PCI scope?",
                answer: this.state.sourceDestinationScope
            },
            {
                question: "Is the DESTINATION IP address an AS managed application/service?",
                answer: this.state.isDestinationManagedApplication,
                additionalInformation: this.state.destinationApplicationExplanation ? this.state.destinationApplicationExplanation : 'N/A'
            },
            {
                question: "Is this request for permanent or temporary use?",
                answer: this.state.networkRequestExpectedLife,
                additionalInformation: this.state.temporaryDecomissionDate ? this.state.temporaryDecomissionDate : 'N/A'
            },
            {
                question: "Previous request ticket #?",
                answer: this.state.previousTicketNumber,
            },
            {
                question: "Additional Comments",
                answer: this.state.additionalComments
            },
            {
                question: "Please describe the purpose of this firewall request",
                answer: this.state.networkRequestDescription,
            },
        ]
        );
    }

    areNetworkQuestionsValid() {
        return this.state.networkRequestApplicationType && this.state.networkRequestDescription 
            && this.state.sourceDestinationScope && this.state.isDestinationManagedApplication
            && this.state.networkRequestExpectedLife;
    }

    updateNetworkQuestion(questionName, value) {
        this.setState({ [questionName]: value }, () => {
            if (this.areNetworkQuestionsValid()) { this.setNetworkQuestionValues(); };
        });
    }

    render() {

        return (

            <div className="network-questions-container">
                <div>
                    <Textbox
                        name="networkRequestDescription"
                        type="text"
                        label="Please describe the purpose of this firewall request"
                        tooltip="Provide a description of the purpose and intent of this firewall request."
                        maxlength="250"
                        required="true"
                        errorMessage="Must be a valid non-empty description"
                        update={this.updateNetworkQuestion}
                        default={this.state.networkRequestDescription}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    <Dropdown
                        name="networkRequestApplicationType"
                        label="Is this request needed for a NEW, REPLACEMENT, or EXISTING application/service?"
                        values={["New", "Replacement", "Existing"]}
                        texts={["New", "Replacement", "Existing"]}
                        required="true"
                        errorMessage="Application type cannot be empty."
                        update={this.updateNetworkQuestion}
                        default={this.state.networkRequestApplicationType}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    {
                        this.state.networkRequestApplicationType == "Replacement"
                        &&
                        <Textbox
                            name="replacementDecomissionDate"
                            type="text"
                            label="Replacement Decomission Date"
                            tooltip="The date in which the replaced application can be decomissioned."
                            maxlength="40"
                            required="false"
                            pattern="^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$"
                            errorMessage="Must be a valid date in the MM/DD/YYYY format. (i.e. 06/17/2020)"
                            update={this.updateNetworkQuestion}
                            default={this.state.replacementDecomissionDate}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                        />
                    }

                    <Dropdown
                        name="sourceDestinationScope"
                        label="Is the SOURCE and/or DESTINATION endpoint(s) considered CDE/PCI scope?"
                        values={["Source Only", "Destination Only", "Source and Destination", "Neither", "Unknown"]}
                        texts={["Source Only", "Destination Only", "Source and Destination", "Neither", "Unknown"]}
                        required="true"
                        errorMessage="Source and Destination scope cannot be empty."
                        update={this.updateNetworkQuestion}
                        default={this.state.sourceDestinationScope}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    <Dropdown
                        name="isDestinationManagedApplication"
                        label="Is the DESTINATION IP address an AS managed application/service?"
                        values={["Yes", "No"]}
                        texts={["Yes", "No"]}
                        required="true"
                        errorMessage="FILL THIS IN"
                        update={this.updateNetworkQuestion}
                        default={this.state.isDestinationManagedApplication}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    {
                        this.state.isDestinationManagedApplication == "No"
                        &&
                        <Textbox
                            name="destinationApplicationExplanation"
                            type="text"
                            label="Please Explain"
                            tooltip="Explain why the destination IP address is NOT an AS managed app/service."
                            maxlength="500"
                            required="false"
                            errorMessage="Must be a valid explanation."
                            update={this.updateNetworkQuestion}
                            default={this.state.destinationApplicationExplanation}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                        />
                    }

                    <Dropdown
                        name="networkRequestExpectedLife"
                        label="Is this request for permanent or temporary use?"
                        values={["Permanent", "Temporary"]}
                        texts={["Permanent", "Temporary"]}
                        required="true"
                        errorMessage="Must indicate whether the request is permanent or temporary."
                        update={this.updateNetworkQuestion}
                        default={this.state.networkRequestExpectedLife}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    {
                        this.state.networkRequestExpectedLife == "Temporary"
                        &&
                        <Textbox
                            name="temporaryDecomissionDate"
                            type="text"
                            label="Temporary Request Decomission Date"
                            pattern="^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$"
                            tooltip="Indicate when this temporary request can be decomissioned"
                            maxlength="40"
                            required="false"
                            errorMessage="Must be a valid date in the MM/DD/YYYY format. (i.e. 06/17/2020)"
                            update={this.updateNetworkQuestion}
                            default={this.state.temporaryDecomissionDate}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                        />
                    }

                    <Textbox
                        name="previousTicketNumber"
                        type="text"
                        label="Previous Ticket Number"
                        tooltip="If you have a previous ticket this request links to, please provide the ticket number."
                        maxlength="40"
                        required="false"
                        errorMessage="Must be a valid ticket number."
                        update={this.updateNetworkQuestion}
                        default={this.state.previousTicketNumber}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />

                    <Textbox
                        name="additionalComments"
                        type="text"
                        label="Additional Comments"
                        maxlength="500"
                        required="false"
                        errorMessage="Must be valid additional comments and not empty."
                        update={this.updateNetworkQuestion}
                        default={this.state.additionalComments}
                        disabled={!this.props.editing}
                        editing={this.props.editing}
                    />
                </div>
            </div>


        );
    }
}
