import React, { Component } from 'react';

export class ShadowedColumnCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridClass: null
        };
    }
    componentWillMount() {
        if (this.props.colWidth !== undefined) {
            this.setState({
                gridClass: 'col-md-' + this.props.colWidth + ' app-details-container'
            });
        } else {
            this.setState({
                gridClass: 'col-md app-details-container'
            });
        }
    }
    render() {
        return (
            <div className={this.state.gridClass}>
                <div className="white-background">
                    <div className="app-details-card pb-0">
                        <h1 className="chip-header">{this.props.cardTitle}</h1>
                        {this.props.cardIcon}
                    </div>
                    <hr className="d-md-block app-details-request-line" />
                    {this.props.cardContent}
                </div>
            </div>
        )
    }
}