import React, { useEffect, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { getListAzureSubscriptionsBudget, isBudgetHealthy, subscriptionColor } from '../../helpers/AzureBudgetHelper';
import chroma from 'chroma-js';


function SubscriptionDropDown({ subscriptions, updateText, disabled, selectedSubscriptions }) {

    const [budget, setbudget] = useState();
    const [budgetMessage, setbudgetMessage] = useState();
    const colourStyles = {
        control: styles => ({ ...styles }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma('grey');
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (styles, { data }) => {
            let subColor = subscriptionColor(budget, data);            
            const color = chroma(subColor);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        }
    };
    async function azBudget() {
        if (selectedSubscriptions !== undefined && selectedSubscriptions !== "") {
            var getAzureSubBudget = await getListAzureSubscriptionsBudget(selectedSubscriptions);
            return getAzureSubBudget;
        }
    }

    useEffect(() => {
        azBudget().then((data) => {
            setbudget(data);
        });
    }, [selectedSubscriptions]);

    let budgetMessageHtml = (<><p>Last Month's budget(s)</p>
        <p className="mb-0">
            Red: Budget {">"} 100%
        </p><p className="mb-0">
            Yellow: Budget {">"} 80%
        </p><p className="mb-0">
            Green: Budget {"<"} 80%
        </p></>);

    useEffect(() => {
        if (budget && isBudgetHealthy(budget)) {
            setbudgetMessage(budgetMessageHtml)
        }
    }, [budget])

	
	return (
		<div>
            <Dropdown
                name="selectedSubscriptions"
                label="Subscriptions"
                tooltip="The subscription(s) you want to provision resource group(s) and spn(s). You may select multiple subscription to represent each environment."
                values={subscriptions}
                texts={subscriptions}
                required="true"
                errorMessage="Subscriptions is required"
                update={updateText}
                disabled={disabled}
                multiple={true}
                colourStyles={colourStyles}
                warning={budgetMessage && { color: "warning", text: budgetMessage }}
            />
		</div>
	)
}

export default SubscriptionDropDown