import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import { IPAddressInput } from '../IPAddressInput';
import { PortProtocolInput } from '../PortProtocolInput';
import { ChipButton } from '../ChipButton'
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { Checkbox } from '../Checkbox';

export class NetworkRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            sourceDNS: this.props.sourceDNS,
            sourceIP: this.props.sourceIP,
            destinationDNS: this.props.destinationDNS,
            destinationIP: this.props.destinationIP,
            destinationPortProtocols: this.props.destinationPortProtocols,
            bidirectional: this.props.bidirectional,
            isRuleCardOpen: this.props.isRuleCardOpen ? this.props.isRuleCardOpen : false,
        };

        this.updateText = this.updateText.bind(this);
        this.addRule = this.addRule.bind(this);
        this.editRule = this.editRule.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.toggleRuleCard = this.toggleRuleCard.bind(this);
        this.addPortProtocol = this.addPortProtocol.bind(this);
        this.deletePortProtocol = this.deletePortProtocol.bind(this);
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    addRule() {
        this.props.addRule({
            sourceDNS: this.state.sourceDNS,
            sourceIP: this.state.sourceIP,
            destinationDNS: this.state.destinationDNS,
            destinationIP: this.state.destinationIP,
            destinationPortProtocols: this.state.destinationPortProtocols,
            bidirectional: this.state.bidirectional
        });

        this.setState({
            sourceDNS: "",
            sourceIP: "",
            destinationDNS: "",
            destinationIP: "",
            destinationPortProtocols: [],
            bidirectional: false
        });
    }

    editRule() {
        this.props.editRule({
            sourceDNS: this.state.sourceDNS,
            sourceIP: this.state.sourceIP,
            destinationDNS: this.state.destinationDNS,
            destinationIP: this.state.destinationIP,
            destinationPortProtocols: this.state.destinationPortProtocols
        }, this.props.ruleIndex);

        this.setState({ isRuleCardOpen: false });
    }

    deleteRule() {
        this.props.deleteRule(this.props.ruleIndex);
    }

    toggleRuleCard() {
        this.setState({ isRuleCardOpen: !this.state.isRuleCardOpen });
    }

    isNetworkRuleValid() {
        return this.state.sourceIP && this.state.destinationIP && this.state.destinationPortProtocols;
    }

    addPortProtocol(portProtocol) {
        var currentList = this.state.destinationPortProtocols;
        var newList = currentList ? currentList.concat(portProtocol) : [portProtocol];
        this.setState({ destinationPortProtocols: newList });
    }

    deletePortProtocol(portProtocolIndex) {
        var currentList = this.state.destinationPortProtocols;
        currentList.splice(portProtocolIndex, 1);
        this.setState({ destinationPortProtocols: currentList });
    }

    render() {


        const generateKey = (pre) => {
            return `${pre}_${new Date().getTime()}`;
        }

        let buttonText =
            this.state.isRuleCardOpen ?
                (this.props.existingRule ?
                    `Hide Rule ${this.props.ruleIndex + 1}`
                    : `Hide New Rule`) :
                (this.props.existingRule ?
                    `Show Rule ${this.props.ruleIndex + 1}`
                    : `Show New Rule`);
        return (

            <div className="network-rule-card-container">
                <Button color="primary" onClick={this.toggleRuleCard} className={`network-rule-card-toggle ${this.state.isRuleCardOpen ? "btn-secondary" : (this.props.existingRule ? "btn-primary" : "btn-success")}`}>{buttonText}</Button>
                <Collapse isOpen={this.state.isRuleCardOpen}>
                    <Card className="network-rule-card">
                        <div>
                            <Textbox
                                name="sourceDNS"
                                type="text"
                                label="Source DNS/FQDN"
                                tooltip="TBD"
                                maxlength="240"
                                required="false"
                                errorMessage="Must not be empty."
                                update={this.updateText}
                                default={this.state.sourceDNS}
                                disabled={!this.props.editing}
                                editing={this.props.editing}
                            />
                            <IPAddressInput
                                name="sourceIP"
                                label="Source IP Address"
                                tooltip="this is an ip address"
                                required="true"
                                updateText={this.updateText}
                                default={this.state.sourceIP}
                                disabled={!this.props.editing}
                                editing={this.props.editing}
                            />
                            <Textbox
                                name="destinationDNS"
                                type="text"
                                label="Destination DNS/FQDN"
                                tooltip="TBD"
                                maxlength="240"
                                required="false"
                                errorMessage="Must not be empty."
                                update={this.updateText}
                                default={this.state.destinationDNS}
                                disabled={!this.props.editing}
                                editing={this.props.editing}
                            />

                            <IPAddressInput
                                name="destinationIP"
                                label="Destination IP Address"
                                tooltip="this is an ip address"
                                required="true"
                                updateText={this.updateText}
                                default={this.state.destinationIP}
                                disabled={!this.props.editing}
                                editing={this.props.editing}
                            />
                            <div className="network-rule-port-protocols">
                                {this.state.destinationPortProtocols &&
                                    this.state.destinationPortProtocols.map((portProtocol, index) => {
                                        return <PortProtocolInput
                                            key={generateKey(index)}
                                            name="destinationPortProtocol"
                                            label="Destination Port/Protocol"
                                            required="true"
                                            destinationPort={portProtocol.destinationPort}
                                            protocol={portProtocol.protocol}
                                            updateText={this.updateText}
                                            disabled={!this.props.editing}
                                            editing={this.props.editing}
                                            addPortProtocol={this.addPortProtocol}
                                            deletePortProtocol={this.deletePortProtocol}
                                            portProtocolIndex={index}
                                            existingPortProtocol={true}
                                        />
                                    })
                                }
                            </div>

                            {this.props.editing &&
                                <PortProtocolInput
                                    name="destinationPortProtocol"
                                    label="Destination Port/Protocol"
                                    tooltip="this is an ip address"
                                    required="true"
                                    updateText={this.updateText}
                                    disabled={!this.props.editing}
                                    editing={this.props.editing}
                                    existingPortProtocol={false}
                                    addPortProtocol={this.addPortProtocol}
                                    deletePortProtocol={this.deletePortProtocol}
                                />
                            }

                            {!this.props.destinationIP && !this.props.sourceIP &&
                                <Checkbox
                                    name="bidirectional"
                                    label="Bidirectional Network Rule"
                                    tooltip="Check this box to create a secondary rule with flipped source and destination."
                                    className="report-details-checkbox"
                                    default={this.state.bidirectional}
                                    update={this.updateText}
                                    disabled={!this.props.editing}
                                />
                            }

                            {!this.props.existingRule &&
                                <ChipButton
                                    buttonClassName="save-button"
                                    buttonText="Add Rule"
                                    action={this.addRule}
                                    color='btn-success'
                                    disabled={!this.isNetworkRuleValid()}

                                />
                            }

                            {this.props.existingRule && this.props.editing &&
                                <div className="edit-network-rule-buttons">
                                    <ChipButton
                                        buttonClassName="edit-button"
                                        buttonText="Save Rule"
                                        action={this.editRule}
                                        color='btn-success'
                                        disabled={!this.isNetworkRuleValid()}
                                    />
                                    <ChipButton
                                        buttonClassName="delete-button"
                                        buttonText="Delete Rule"
                                        color='btn-danger'
                                        action={this.deleteRule}
                                    />

                                </div>
                            }

                            <hr />
                        </div>
                    </Card>
                </Collapse>
            </div>


        );
    }
}
