import React, { Component } from 'react';
import '../css/ApplicationCost.css';

export class ApplicationCost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prod: null,
            width: window.innerWidth
        };
        this.formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });

    }

    setEnvironment(subscriptionCostDetail) {
        let response = {
            cost: subscriptionCostDetail.cost,
            environment: subscriptionCostDetail.environment,
            subscription: [
                {
                    subscription: subscriptionCostDetail.subscription.subscriptionName,
                    subscriptionID: subscriptionCostDetail.subscription.subscriptionId
                }
            ],
            resourceGroupCost: [

            ]
        };

        let displayedGroups = [];

        subscriptionCostDetail.resourceGroupCostDetails.map((resourceGroupCostDetail) => {

            if (!displayedGroups.includes(resourceGroupCostDetail.resourceGroup.toLowerCase())) {

                if (this.state.width > 1400 || this.state.width < 600) {
                    response.resourceGroupCost.push({
                        cost: resourceGroupCostDetail.cost,
                        resourceGroup: resourceGroupCostDetail.resourceGroup
                    });
                } else {
                    response.resourceGroupCost.push({
                        cost: resourceGroupCostDetail.cost,
                        resourceGroup: (resourceGroupCostDetail.resourceGroup).substring(0, 6) + '...'
                    });
                }

                displayedGroups.push(resourceGroupCostDetail.resourceGroup.toLowerCase())
            }
        });
        return response;
    }

    componentDidMount() {
        this.updateSubscriptionCost();
        window.addEventListener('resize', this.updateDimensions);

    }

    updateSubscriptionCost() {
        let prod;
        let qa;
        let test;
        this.props.subscriptionCostDetails.forEach((subscriptionCostDetail) => {
            if (subscriptionCostDetail.environment === "prod") {
                prod = this.setEnvironment(subscriptionCostDetail);
            } else if (subscriptionCostDetail.environment === "qa") {
                qa = this.setEnvironment(subscriptionCostDetail);
            } else {
                test = this.setEnvironment(subscriptionCostDetail);
            }
            this.setState({
                prod: prod,
                qa: qa,
                test: test,
                envCollection: [prod, qa, test],
                width: window.innerWidth
            });
        });
    }

    updateDimensions = () => {
        this.updateSubscriptionCost();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {

        return (
            <div className="application-detail-overall-cost container">
                <p>Overall Cost: {this.props.cost}</p>
                <div className="row subscription-cost">
                    <div className="col-sm-4 cost">
                        Dev Environment Cost: {this.state.test && this.formatter.format(this.state.test.cost)}
                    </div>
                    <div className="col-sm-4 cost">
                        QA Environment Cost: {this.state.qa && this.formatter.format(this.state.qa.cost)}
                    </div>
                    <div className="col-sm-4 cost">
                        Prod Environment Cost: {this.state.prod && this.formatter.format(this.state.prod.cost)}
                    </div>
                </div>
                <div className="row resource-cost">
                    <ResourceGroupCostColumn
                        environment={this.state.test}
                        formatter={this.formatter}
                    />
                    <ResourceGroupCostColumn
                        environment={this.state.qa}
                        formatter={this.formatter}
                    />
                    <ResourceGroupCostColumn
                        environment={this.state.prod}
                        formatter={this.formatter}
                    />
                </div>
            </div>
        )
    }
}


class ResourceGroupCostColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="col-sm-4 cost">
                <div className="row resource-group-cost-title">
                    <div className="col-sm-6">Resource Group</div>
                    <div className="col-sm-6">Cost</div>
                </div>
                <div className="row resource-group-cost">
                    {this.props.environment && this.props.environment.resourceGroupCost.map((costDetails) => {
                        return (
                            <React.Fragment key={costDetails.resourceGroup}>
                                <div className="col-sm-6">{costDetails.resourceGroup}</div>
                                <div className="col-sm-6">{this.props.formatter.format(costDetails.cost)}</div>
                            </React.Fragment>
                        );
                    })}

                </div>
            </div>
        )
    }
} 