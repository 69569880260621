import { authenticatedApiFetch } from "./FetchHelper";

export const getSubscriptionsRestCall = async (reportType) => {
    var fetchedSubscriptionNames = await getSubscriptionApiCall();
    reportType = reportType != null ? reportType : "";
    return updateSubscriptionList(fetchedSubscriptionNames, reportType);
}

export const getAllSubscriptions = async (detailed = false) => {
    return await getSubscriptionApiCall(detailed);
}

const getSubscriptionApiCall = (detailed) => {
    return new Promise((res, rej) => {
        authenticatedApiFetch('api/ResourceManagement/GetAllSubscriptions', {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    rej(response);
                }
            }).then((data) => {
                if (detailed) {
                    res(data)
                } else {
                    let fetchedSubscriptionNames = [];
                    data.map((subscription) => {
                        fetchedSubscriptionNames.push(subscription.subscriptionName);
                    });
                    res(fetchedSubscriptionNames);
                }
            });
    })
};

const updateSubscriptionList = (fetchedSubscriptions, reportType) => {
    var subscriptionList;

    if (reportType === 'dbdtuconsumption') {
        subscriptionList = fetchedSubscriptions;
    }
    else {
        subscriptionList = fetchedSubscriptions.filter(x => x.toLowerCase().includes('prod'));
    }

    subscriptionList = reportType !== 'subscriptiondetails' ? ["ALL"].concat(subscriptionList) : subscriptionList;

    return subscriptionList;

}