export const constructRequestListAndDict = (requestList) => {
    let formattedRequestList = [];
    let provisionedResources = [];
    let formattedRequestDictionary = {};

    sortRequestListByRequestDate(requestList);

    requestList.map((x) => {
        formatRequest(x, formattedRequestList, formattedRequestDictionary);
        formatResourcesRequest(x, provisionedResources);
    });


    return {
        formattedRequestList: formattedRequestList,
        formattedRequestDictionary: formattedRequestDictionary,
        provisionedResources: provisionedResources
    }

}

const sortRequestListByRequestDate = (list) => {
    if (list) {
        list.sort((a, b) => {
            if (a.request) {
                return (a.request.creationDate < b.request.creationDate) ? 1 : -1
            }
            else {
                return (a.creationDate < b.creationDate) ? 1 : -1
            }
        });
    }
}

export const constructRequestListAndDictFromNested = (nestedRequestList) => {
    let newRequestList = [];
    let approvedCompletedRequestList = [];
    let failedRequestList = [];
    let submittedRequestList = [];
    let approvedByNetworking = [];
    let approvedBySecurity = [];
    let rejectedList = [];

    let formattedRequestDictionary = {};
    for (var requestList in nestedRequestList) {
        sortRequestListByRequestDate(nestedRequestList[requestList]);
    }

    nestedRequestList["New"].map(x => {
        formatRequest(x.request, newRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["Approved"].map(x => {
        formatRequest(x.request, approvedCompletedRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["Completed"].map(x => {
        formatRequest(x.request, approvedCompletedRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["Submitted"].map(x => {
        formatRequest(x.request, submittedRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["Failed"].map(x => {
        formatRequest(x.request, failedRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["Rejected"].map(x => {
        formatRequest(x.request, failedRequestList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
        formatRequest(x.request, rejectedList, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["ApprovedByNetworking"].map(x => {
        formatRequest(x.request, approvedByNetworking, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });

    nestedRequestList["ApprovedBySecurity"].map(x => {
        formatRequest(x.request, approvedBySecurity, formattedRequestDictionary, x.applicationID, x.applicationName, x.teamName);
    });



    return {
        newRequestList: newRequestList,
        approvedCompletedRequestList: approvedCompletedRequestList,
        failedRequestList: failedRequestList,
        submittedRequestList: submittedRequestList,
        formattedRequestDictionary: formattedRequestDictionary,
        approvedByNetworking: approvedByNetworking,
        approvedBySecurity: approvedBySecurity,
        rejectedList: rejectedList
    }
}

const formatRequest = (x, requestList, requestDictionary, applicationId, applicationName, teamName) => {
    let requestStatusName;

    switch (x.requestStatus) {
        case 0:
            requestStatusName = 'New';
            break;
        case 1:
            requestStatusName = 'Approved';
            break;
        case 2:
            requestStatusName = 'Rejected';
            break;
        case 3:
            requestStatusName = 'Completed';
            break;
        case 4:
            requestStatusName = "Failed";
            break;
        case 5:
            requestStatusName = "Submitted";
            break;
        case 6:
            requestStatusName = "Approved By Networking";
            break;
        case 7:
            requestStatusName = "Approved By Security";
            break;
        default:
            requestStatusName = "N/A";
            break;
    }
    let requestTypeName;
    switch (x.requestTypes) {
        case 1:
            requestTypeName = 'Create SPN+';
            break;
        case 2:
            requestTypeName = 'Create Standalone SPN';
            break;
        case 3:
            requestTypeName = 'Create Network Firewall';
            break;
        case 4:
            requestTypeName = 'Kubernetes'
            break;
        case 5:
            requestTypeName = 'Resource Permissions'
            break;
        case 6:
            requestTypeName = 'Run ADO Pipeline'
            break;

        default:
            requestTypeName = "N/A";
            break;
    }
    var formattedRequest = x;
    formattedRequest.requestStatusName = requestStatusName;
    formattedRequest.requestTypeName = requestTypeName;
    formattedRequest.applicationId = applicationId;
    formattedRequest.applicationName = applicationName;
    formattedRequest.teamName = teamName;
    requestDictionary[x.requestId] = formattedRequest;

    requestList.push({
        requestStatus: requestStatusName,
        requestType: requestTypeName,
        cherwellTicketNumber: x.cherwellInformation != null ? x.cherwellInformation.cherwellTicketNumber : "",
        requestId: x.requestId,
        applicationName: applicationName,
        teamName: teamName,
        completionDate: formattedRequest.completionDate
    });
}

const formatResourcesRequest = (x, provisionedResources) => {
    if ((x.requestTypes == 1 || x.requestTypes == 2) && x.requestedSubscriptions != null && x.provisionedSpns != null) {
        x.requestedSubscriptions.forEach(sub => {
            x.provisionedSpns.forEach(prov => {
                provisionedResources.push({
                    subscriptions: sub,
                    resourceGroupName: prov.resourceGroup == null ? "N/A" : prov.resourceGroup,
                    spnName: prov.name == null ? "N/A" : prov.name,
                    creationDate: x.creationDate,
                });
            })
        });
    }
}