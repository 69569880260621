import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import "../../css/RequestDetails.css"
import { ChipButton } from '../ChipButton'
import { ResourcePermissions } from './ResourcePermissions'
export class ResourcePermissionsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {

            requestDetails: this.props.requestDetails,
            spnName: null,
            spnApplicationId: null,
            spnObjectId: null
        };

        this.updateText = this.updateText.bind(this);
        this.saveRequest = this.saveRequest.bind(this);

    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    saveRequest() {
        var updatedRequest = this.constructEditedRequestDetails();
        this.props.updateRequest(updatedRequest);
    }

    constructEditedRequestDetails() {
        var updatedRequestDetails = this.state.requestDetails;
        updatedRequestDetails.resourcePermissionsACS = this.state.resourcePermissionsACS;
        updatedRequestDetails.resourcePermissionsADGroup = this.state.resourcePermissionsADGroup;
        updatedRequestDetails.resourcePermissionsIdentityType = this.state.resourcePermissionsIdentityType;
        updatedRequestDetails.resourcePermissionsMSIObjectID = this.state.resourcePermissionsMSIObjectID;
        updatedRequestDetails.resourcePermissionsResourceType = this.state.resourcePermissionsResourceType;
        updatedRequestDetails.resourcePermissionsSPNObjectID = this.state.resourcePermissionsSPNObjectID;
        updatedRequestDetails.resourcePermissionsStorageAccount = this.state.resourcePermissionsStorageAccount;
        updatedRequestDetails.resourcePermissionsSubscription = this.state.resourcePermissionsSubscription;
        return updatedRequestDetails;
    }

    render() {
        return (
            <div className="request-details-container">
                {this.props.requestDetails &&
                    <div>
                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Type"
                            update={this.updateText}
                            default={this.props.requestDetails.requestTypeName}
                            disabled={true}
                        />

                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Status"
                            update={this.updateText}
                            default={this.props.requestDetails.requestStatusName}
                            disabled={true}
                        />

                        {this.props.requestDetails.approvalComments &&
                            <Textbox
                                name="approvalComments"
                                type="text"
                                label="Approval Comments"
                                update={this.updateText}
                                default={this.props.requestDetails.approvalComments}
                                disabled={true}
                            />
                        }

                        <ResourcePermissions
                            editing={this.props.editing}
                            updateText={this.updateText}
                            resourcePermissionsSubscription={this.props.requestDetails.resourcePermissionsSubscription}
                            resourcePermissionsACS={this.props.requestDetails.resourcePermissionsACS}
                            resourcePermissionsADGroup={this.props.requestDetails.resourcePermissionsADGroup}
                            resourcePermissionsIdentityType={this.props.requestDetails.resourcePermissionsIdentityType}
                            resourcePermissionsMSIObjectID={this.props.requestDetails.resourcePermissionsMSIObjectID}
                            resourcePermissionsResourceType={this.props.requestDetails.resourcePermissionsResourceType}
                            resourcePermissionsSPNObjectID={this.props.requestDetails.resourcePermissionsSPNObjectID}
                            resourcePermissionsStorageAccount={this.props.requestDetails.resourcePermissionsStorageAccount}
                            disabled={!this.props.editing}
                        />

                        {this.props.requestDetails.cherwellInformation &&
                            <Textbox
                                name="cherwellTicketNumber"
                                type="text"
                                label="Cherwell Ticket Number"
                                default={this.props.requestDetails.cherwellInformation.cherwellTicketNumber}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="creationDate"
                            type="text"
                            label="Creation Date"
                            default={new Date(this.props.requestDetails.creationDate).toLocaleDateString()}
                            disabled={true}
                        />

                        {this.props.requestDetails.requestStatus === 3 &&
                            <Textbox
                                name="completionDate"
                                type="text"
                                label="Completion Date"
                                default={new Date(this.props.requestDetails.completionDate).toLocaleDateString()}
                                disabled={true}
                            />
                        }

                        {(this.props.requestDetails.requestStatus == 3 || this.props.requestDetails.requestStatus == 1) && this.props.requestDetails.approver &&
                            <>
                                <Textbox
                                    name="approverName"
                                    type="text"
                                    label="Approver Name"
                                    default={this.props.requestDetails.approver.contactInfo.name}
                                    disabled={true}
                                />

                                <Textbox
                                    name="approverEmail"
                                    type="text"
                                    label="Approver Email"
                                    default={this.props.requestDetails.approver.contactInfo.email}
                                    disabled={true}
                                />
                            </>
                        }
                        <Textbox
                            name="requesterName"
                            type="text"
                            label="Requester Name"
                            default={this.props.requestDetails.requester.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}
                        />
                        <Textbox
                            name="requesterEmail"
                            type="email"
                            label="Requester Email"
                            default={this.props.requestDetails.requester.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterManagerName"
                            type="text"
                            label="Requester's Manager Name"
                            default={this.props.requestDetails.requester.manager.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}



                        />
                        <Textbox
                            name="requesterManagerEmail"
                            type="email"
                            label="Requester's Manager Email"
                            default={this.props.requestDetails.requester.manager.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterTeamName"
                            type="text"
                            label="Requester's Team Name"
                            default={this.props.requestDetails.requester.groupName}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        {this.props.editing &&
                            <ChipButton
                                buttonClassName="save-button"
                                buttonText="Save"
                                action={this.saveRequest}
                                color='btn-success'
                            />
                        }
                    </div>

                }
            </div>
        );
    }
}