import '../css/NavMenu.css';
import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import BackgroundImage from "../img/chip-background_optimized.jpg"
import { auth } from '../adalConfig';
import { ChipModal } from './ChipModal';
import { ReportSelectorDropdowns } from './reports/ReportSelectorDropdowns';
import { getSubscriptionsRestCall } from '../helpers/SubscriptionHelper';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            transparent: this.props.location.pathname === "/" || this.props.location.pathname === "/null",
            userContext: null,
            isReportModalOpen: false,
            reportType: "",
            subscriptionName: "",
            redirect: false,
            subscriptionNames: null,
            resourcesDropdownOpen: false,
            howToDropdownOpen: false,
            isBudgetSubmenuOpen: false,
            resourceDropDownCss: ""
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.setContext = this.setContext.bind(this);
        this.updateText = this.updateText.bind(this);
        this.toggleResourcesDropdown = this.toggleResourcesDropdown.bind(this);
        this.toggleHowToDropdown = this.toggleHowToDropdown.bind(this);
        this.toggleBudgetSubmenu = this.toggleBudgetSubmenu.bind(this);

    }



    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });

    }

    setContext(err, context) {
        this.setState({ userContext: context });
    }

    async updateSubscriptionList() {
        var subscriptionList = await getSubscriptionsRestCall(this.state.reportType);
        this.setState({ subscriptionNames: subscriptionList });
    }

    componentDidMount() {
        auth.then((response) => {

            response.authContext.getUser(this.setContext);
            this.updateSubscriptionList();

        });
    }

    toggleNavbarTransparency(isHome) {
        if (isHome) {
            this.setState({
                transparent: true,
                collapsed: !this.state.collapsed
            });
        }
        else {
            this.setState({
                transparent: false,
                collapsed: !this.state.collapsed
            });
        }
    }

    toggleReportModal() {
        this.setState({ isReportModalOpen: !this.state.isReportModalOpen });
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    selectReport() {
        if (this.state.reportType && this.state.subscriptionName) {
            this.setState({
                isReportModalOpen: false
            }, () => {
                this.props.history.push(`/Reports/${this.state.reportType}/${this.state.subscriptionName}`);
                this.toggleNavbarTransparency(false);
            })
        } else {
            //add error for form validation
        }
    }

    toggleResourcesDropdown() {
        this.setState(prevState => ({
            resourcesDropdownOpen: !prevState.resourcesDropdownOpen
        }));
    }

    toggleHowToDropdown() {
        this.setState(prevState => ({
            howToDropdownOpen: !prevState.howToDropdownOpen
        }));
    }

    toggleBudgetSubmenu() {
        this.setState(prevState => ({
            isBudgetSubmenuOpen: !prevState.isBudgetSubmenuOpen
        }));
    }

    signOut() {
        auth.then((response) => {
            response.authContext.logOut();
        });
    }

    render() {
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapsing navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <header>
                <Navbar className={"navbar-expand-lg navbar-toggleable-sm ng-white mb-3 " + (this.state.transparent ? 'navbar-transparent' : 'navbar-solid')} light>
                    <Container>
                        <NavbarBrand tag={Link} to="/" onClick={() => this.toggleNavbarTransparency(true)}>CHIP</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className={"d-sm-inline-flex" + classOne} isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} to="/NewApplication" onClick={() => this.toggleNavbarTransparency(false)}>New Application</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/ApplicationList" onClick={() => this.toggleNavbarTransparency(false)}>Applications</NavLink>
                                </NavItem>
                                {this.state.userContext && this.state.userContext.profile.roles && this.state.userContext.profile.roles.includes("SuperUser") &&
                                    <NavItem>
                                        <NavLink tag={Link} to="/Requests" onClick={() => this.toggleNavbarTransparency(false)}>Requests</NavLink>
                                    </NavItem>
                                }
                                {this.state.userContext && this.state.userContext.profile.roles && (this.state.userContext.profile.roles.includes("NetworkAdmin")
                                    || this.state.userContext.profile.roles.includes("SuperUser") || this.state.userContext.profile.roles.includes("SecurityAdmin")) &&
                                    <NavItem>
                                        <NavLink tag={Link} to="/NetworkRequest" onClick={() => this.toggleNavbarTransparency(false)}>Network Requests</NavLink>
                                    </NavItem>
                                }
                                <NavItem>
                                    <Dropdown className="resources-dropdown" isOpen={this.state.resourcesDropdownOpen} toggle={this.toggleResourcesDropdown}>
                                        <DropdownToggle caret>
                                            Resources
                                        </DropdownToggle>
                                        <DropdownMenu className={(this.state.transparent ? 'home-page-resource-dropdown' : 'other-resource-drop-down')}>
                                            <DropdownItem>
                                                <a href="https://alaskaair.sharepoint.com/sites/SysDocs/SitePages/ITS%20System%20Documents.aspx" target="_blank">ITS/Ecomm System Documents</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="https://alaskaair.sharepoint.com/sites/ASICS/ESA/CCC/Pages/Home.aspx" target="_blank">CCC SharePoint Site</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="https://dev.azure.com/itsals/CloudHub" target="_blank">CloudHub Project Overview</a>
                                            </DropdownItem>                
                                            <DropdownItem onMouseEnter={this.toggleBudgetSubmenu} onMouseLeave={this.toggleBudgetSubmenu} >
                                                <Dropdown className="budget-submenu" direction="right" isOpen={this.state.isBudgetSubmenuOpen} toggle={this.toggleBudgetSubmenu}>
                                                    <DropdownToggle caret className="budget-submenu-button">
                                                        Budget
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem> <a href="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=1MVED7BCwkW_VdD-qEMNMwk2uu8AAJZKig6E4y9s32pUQzRYUUZFUVkxMkgyM0NJVFZHMlBLUU80Ny4u" target="_blank">Request Budget Change</a></DropdownItem>
                                                        <DropdownItem> <a href="https://portal.azure.com/#@alaskaair.onmicrosoft.com/dashboard/arm/subscriptions/f2b323a4-f1d8-4e77-8e68-829eabc614b5/resourceGroups/dashboards/providers/Microsoft.Portal/dashboards/eac8eee9-dd88-42a4-8eb5-1e55539ae6b6" target="_blank">Monthly Budget Dashboard</a></DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavItem>
                                <NavItem>
                                    <Dropdown className="resources-dropdown" isOpen={this.state.howToDropdownOpen} toggle={this.toggleHowToDropdown}>
                                        <DropdownToggle caret>
                                            How To
                                        </DropdownToggle>
                                        <DropdownMenu className={(this.state.transparent ? 'home-page-resource-dropdown' : 'other-resource-drop-down')}>                                                                  
                                            <DropdownItem>
                                                <a href="https://dev.azure.com/itsals/CloudHub/_wiki/wikis/CloudHub.wiki/567/AKS" target="_blank">AKS</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="https://dev.azure.com/itsals/CloudHub/_git/ARMTemplates?path=/README.md&_a=preview" target="_blank">ARM Templates</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="https://dev.azure.com/itsals/CloudHub/_git/Cloud%20HowTos?path=%2FCHIP%20Firewall%20Request%2FREADME.MD&_a=preview" target="_blank">CHIP Network Firewall Requests</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="https://dev.azure.com/itsals/CloudHub/_git/Cloud%20HowTos?path=/HowTo%20Get%20Azure%20Access/README.md&_a=preview" target="_blank">Get Azure Access</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavItem>
                                {this.state.userContext && this.state.userContext.profile.roles && this.state.userContext.profile.roles.includes("SuperUser") &&
                                    <NavItem>
                                        <NavLink tag={Link} to="/Kubernetes" onClick={() => this.toggleNavbarTransparency(false)}>Kubernetes</NavLink>
                                    </NavItem>
                                }
                                <NavItem>
                                    <NavLink tag={Link} to="#" onClick={() => this.toggleReportModal()}>Reports</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/Help" onClick={() => this.toggleNavbarTransparency(false)}>Help</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/About" onClick={() => this.toggleNavbarTransparency(false)}>About</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled>Hello, {this.state.userContext && this.state.userContext.profile.name}</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink onClick={this.signOut}>Sign Out</NavLink>
                                </NavItem>
                            </ul>

                        </Collapse>
                    </Container>
                </Navbar>

                <div style={{ display: this.state.transparent ? 'block' : 'none' }}>
                    <img src={BackgroundImage} alt="background" className="background-image" />
                </div>

                <ChipModal
                    isOpen={this.state.isReportModalOpen}
                    toggleModal={() => this.toggleReportModal()}
                    redirect={this.state.redirect}
                    modalClassName="modal-50w modal-vertical-center"
                    modalBodyClassName="report-selector-modal-body"
                    modalTitle="Select Report"
                    buttonText="Select"
                    buttonAction={() => this.selectReport()}
                    modalBody={
                        <div>
                            <ReportSelectorDropdowns
                                updateText={this.updateText}
                                reportType={this.state.reportType}
                                subscriptionName={this.state.subscriptionName}
                                preFetchedSubscriptionNames={this.state.fetchedSubscriptions && this.state.fetchedSubscriptions}
                                preFilteredSubscriptionNames={this.state.subscriptionNames && this.state.subscriptionNames}
                            />
                        </div>}
                />
            </header>
        );
    }
}


export default withRouter(NavMenu)