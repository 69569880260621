import React, { Component } from 'react';

export class AppServiceAlertModalContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            http403ruleCompliance: "",
            http403alertThreshold: "",
            actionGroupContactPoints: "",
            http5XXalertCompliance: "", 
            http5XXalertThreshold: ""
        };
    }

    componentDidMount() {
        this.findAndSetAlertValues(this.props.resourceDetailsData);
    }


    findAndSetAlertValues(resourceDetailsData) {

        var http403rule =
            resourceDetailsData.find(x => x.resourceDetailName === "Http403Threshold");
        var http5XXrule =
            resourceDetailsData.find(x => x.resourceDetailName === "Http5xxThreshold");
        var actionGroupRule = 
            resourceDetailsData.find(x => x.resourceDetailName === "AlertsContactInformationCount");

        this.setState({
            http403ruleCompliance: http403rule.resourceDetailGrade,
            http403alertThreshold: http403rule.resourceDetailValue,
            actionGroupContactPoints: actionGroupRule.resourceDetailValue,
            http5XXalertCompliance: http5XXrule.resourceDetailGrade,
            http5XXalertThreshold: http5XXrule.resourceDetailValue
        });

    }

    render() {

        let isDynamic403 = this.state.http403alertThreshold == 0 && this.state.http403ruleCompliance == 'FullyCompliant'
        let isDynamic5xx = this.state.http5XXalertThreshold == 0 && this.state.http5XXalertCompliance == 'FullyCompliant'

        return (
            <div>
                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http403 Alert Rule Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http403 Alert Threshold:</h3>
                            </div>

                        </div>

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Action Group Contact Points:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.http403ruleCompliance}</div>
                        </div>
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{isDynamic403 ? "Dynamic" : this.state.http403alertThreshold}</div>
                        </div>
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.actionGroupContactPoints}</div>
                        </div>
                    </div>
                </div>

                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http5XX Alert Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http5XX Alert Threshold:</h3>
                            </div>

                        </div>


                    </div>

                    <div className="row lgmod-row lgmod-padded-row">

                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.http5XXalertCompliance}</div>
                        </div>
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{isDynamic5xx ? "Dynamic" : this.state.http5XXalertThreshold}</div>
                        </div>
                    </div>

                </div>   

                <div className="lgmod-group" />
            </div>
        );
    }
}