import React, { Component } from 'react';
import { ChipModal } from '../ChipModal';
import { Textbox } from '../Textbox';
import CloseIcon from '../../img/CHIPv1/Close.png'

export class SubscriptionDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newEmails: [],
            deletedEmails: [],
            addNewInput: false,
            currentEmailInput: null,
            emailInputCount: 1,
            emailInputs: [],
            deleteEmailInputs: [],
            emailInputDict: {},
            deletableEmails: this.props.recipients
        };

        this.updateText = this.updateText.bind(this);
        this.checkAndAddInput = this.checkAndAddInput.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
    }

    updateText(name, value) {
        this.setState({ emailInputDict: { ...this.state.emailInputDict, [name]: value } });
    }

    updateNewEmails() {

        this.setState({
            newEmails: Object.values(this.state.emailInputDict).filter(email => email && email !== "")
        });
    }

    createEmailInputs() {
        var emailInputs = [];
        for (var i = 0; i < this.state.emailInputCount; i++) {
            emailInputs.push(
                <Textbox
                    name={`Email-${i}`}
                    type="email"
                    label="Email"
                    tooltip="The product owner's email address"
                    maxlength="50"
                    required="true"
                    errorMessage="Must not be empty, and a valid email address less than 50 characters."
                    update={this.updateText}
                    default={this.state.currentEmailInput}
                    disabled={false}
                    onBlur={this.checkAndAddInput}
                    valid={true}
                />
            );
        }
        this.setState({ emailInputs: emailInputs });
    }

    deleteEmail(e) {
        var deleteEmailIndex = e.target.getAttribute("alt");
        var updatedDeletableEmails = this.state.deletableEmails;
        var deletedEmail = this.state.deletableEmails[deleteEmailIndex];
        var updatedDeletedEmails = this.state.deletedEmails;
        updatedDeletedEmails.push(deletedEmail);
        updatedDeletableEmails.splice(deleteEmailIndex, 1);
        this.setState({
            deletableEmails: updatedDeletableEmails,
            deletedEmails: updatedDeletedEmails
        }, this.createDeleteEmailInputs);
    }

    createDeleteEmailInputs() {
        var deleteEmailInputs = [];
        for (var recipientIndex in this.state.deletableEmails) {
            var currentRecipient = this.state.deletableEmails[recipientIndex];
            deleteEmailInputs.push(
                <div className="edit-modal-email">
                    <button>
                        <img src={CloseIcon} alt={recipientIndex} onClick={this.deleteEmail} />
                    </button>
                    <p className="edit-modal-email-p">{currentRecipient}</p>
                </div>
            );
        }
        this.setState({ deleteEmailInputs: deleteEmailInputs });
    };

    addNewInput() {
        this.setState({ emailInputCount: this.state.emailInputCount + 1 }, () => {
            this.createEmailInputs();
        });

    }

    checkAndAddInput() {
        this.updateNewEmails();
        if (this.state.emailInputDict[`Email-${this.state.emailInputCount - 1}`]) {
            this.addNewInput();
        }
    }

    componentDidMount() {
        this.createEmailInputs();
        this.createDeleteEmailInputs();
    }

    componentDidUpdate(prevProps) {
        if (this.props.recipients !== prevProps.recipients) {
            this.setState({ deletableEmails: this.props.recipients }, this.createDeleteEmailInputs);
        }
    }

    render() {

        const addEmailModalContent = (
            <div id="add-emails-wrapper">
                <div className="add-email-input-wrapper">
                    {this.state.emailInputs}
                </div>
            </div>
        );

        const deleteEmailModalContent = (
            <div>
                <h1>Delete</h1>

                <div id="edit-emails-wrapper">
                    {this.state.deleteEmailInputs}
                </div>
            </div>
        );

        const modalTitle =
            (this.props.subscriptionDetailModalType === 'addEmails' &&
                <h5 className="modal-title email-modal-title" id="add-modal-title">Add Email Recipients</h5>)
            || (this.props.subscriptionDetailModalType === 'deleteEmails' &&
                <h5 className="modal-title email-modal-title" id="add-modal-title">Delete Email Recipients</h5>);

        const modalBody = (this.props.subscriptionDetailModalType === 'addEmails' && addEmailModalContent)
            || (this.props.subscriptionDetailModalType === 'deleteEmails' && deleteEmailModalContent);

        const buttonAction = (this.props.subscriptionDetailModalType === 'addEmails' && (() => this.props.updateRecipientsSubmit(this.state.newEmails)))
            || (this.props.subscriptionDetailModalType === 'deleteEmails' && (() => this.props.deleteEmailsSubmit(this.state.deletedEmails)));

        return (
            <div>
                {this.props.recipients &&

                    <ChipModal
                        isOpen={this.props.isSubscriptionDetailModalOpen}
                        toggleModal={this.props.toggleSubscriptionDetailModal}
                        modalClassName="subscription-details-modal modal-vertical-center"
                        modalBodyClassName="email-modal-body"
                        modalTitle={modalTitle}
                        modalBody={modalBody}
                        buttonText="Save"
                        buttonAction={buttonAction}
                    />
                }
            </div>
        );
    }
}