import React, { Component } from 'react';

export class ComplianceRating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complianceRatingClass: ""
        };

    }


    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setComplianceRatingClass();
        }
    }

    setClass(className) {

        this.setState({
            complianceRatingClass: className
        });
    }

    setComplianceRatingClass() {
        const nonCompliantClass = 'noncompliant-high-alert';
        const partialCompliantClass = 'partialcompliant-medium-alert';
        const fullyCompliantClass = 'fullycompliant';

        if (this.props.alertComplianceGrade) {
            switch (this.props.alertComplianceGrade.toLowerCase()) {
                case 'noncompliant':
                    this.setClass(nonCompliantClass);
                    break;
                case 'partiallycompliant':
                    this.setClass(partialCompliantClass);
                    break;
                case 'fullycompliant':
                    this.setClass(fullyCompliantClass);
                    break;
                default:
                    break;
            }
        }

        if (this.props.alertComplianceValue != null) {
            var complianceValue = parseInt(this.props.alertComplianceValue);
            if (complianceValue < 50) {
                this.setClass(nonCompliantClass);
            } else if (complianceValue < 100) {
                this.setClass(partialCompliantClass);
            } else if (complianceValue === 100) {
                this.setClass(fullyCompliantClass);
            }
        }
    }

    render() {
        return (
            <span className={this.state.complianceRatingClass}>
                {this.props.alertComplianceValue != null && `${this.props.alertComplianceValue}%`}
                {this.props.alertComplianceGrade && this.props.alertComplianceGrade}
            </span>
        );
    }
}