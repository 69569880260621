import React, { Component } from "react";
import { Textbox } from './Textbox';
import { Dropdown } from "./Dropdown";
import { LoadingCloud } from './LoadingCloud';
import { authenticatedApiFetch } from '../helpers/FetchHelper';
import { ErrorContext } from "../ErrorContext";

export class KubernetesAdminForm extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.updateText = this.updateText.bind(this);
        this.updateResourceIdValue = this.updateResourceIdValue.bind(this);
        this.updateEnvironmentValue = this.updateEnvironmentValue.bind(this);
        this.removeEnvironment = this.removeEnvironment.bind(this);
        this.addEnvironment = this.addEnvironment.bind(this);
        this.updateRegionValue = this.updateRegionValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchClusterInfo = this.fetchClusterInfo.bind(this);
        this.updateKubernetesCluster = this.updateKubernetesCluster.bind(this);
        this.createNewKubernetesCluster = this.createNewKubernetesCluster.bind(this);

        this.state = {
            clusterName: "",
            clusterInformationCollection: [
                {
                    clusterResourceId: "",
                    region: "",
                    environment: "test",
                },
                {
                    clusterResourceId: "",
                    region: "",
                    environment: "qa",
                },
                {
                    clusterResourceId: "",
                    region: "",
                    environment: "prod",
                },
            ],
            loading: false,
        };
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchClusterInfo();
        }
    }
    removeEnvironment(index) {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        clusterInformationCollection.splice(index, 1);
        this.setState({
            clusterInformationCollection: clusterInformationCollection,
        });
    }

    addEnvironment() {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        clusterInformationCollection.push({ clusterResourceId: '', environment: '' });
        this.setState({
            clusterInformationCollection: clusterInformationCollection,
        });
    }

    updateRegionValue(index, value) {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        clusterInformationCollection[index].region = value;
        this.setState({ clusterInformationCollection: clusterInformationCollection });
    }

    updateResourceIdValue(index, value) {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        clusterInformationCollection[index].clusterResourceId = value;
        this.setState({ clusterInformationCollection: clusterInformationCollection });
    }

    updateEnvironmentValue(index, value) {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        clusterInformationCollection[index].environment = value;
        this.setState({ clusterInformationCollection: clusterInformationCollection });
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    async fetchClusterInfo() {
        this.setState({ loading: true });
        try {
            let apiResponse = await authenticatedApiFetch(`api/Kubernetes/${this.props.match.params.id}`, {});
            let kubernetesClusterInfo = await apiResponse.json();
            this.setState({
                clusterName: kubernetesClusterInfo.clusterName,
                clusterInformationCollection: kubernetesClusterInfo.clusterInformationCollection || []
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
        this.setState({ loading: false });

    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        let value = {
            clusterName: this.state.clusterName,
            clusterInformationCollection: this.state.clusterInformationCollection,
        };

        try {
            if (this.props.match.params.id) {
                await this.updateKubernetesCluster(value);
            } else {
                await this.createNewKubernetesCluster(value);
            }
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
        this.setState({ loading: false });
        this.props.history.push(`/Kubernetes`);
    }

    async createNewKubernetesCluster(clusterBody) {
        try {
            await authenticatedApiFetch('api/Kubernetes', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify(clusterBody)
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    async updateKubernetesCluster(clusterBody) {

        try {
            await authenticatedApiFetch(`api/Kubernetes/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'put',
                body: JSON.stringify(clusterBody)
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    render() {
        let clusterInformationCollection = this.state.clusterInformationCollection;
        let environments = {
            texts: [
                "Dev",
                "Test",
                "QA",
                "Prod"
            ],
            values: [
                "dev",
                "test",
                "qa",
                "prod"
            ],
        };
        let { loading, clusterName } = this.state;
        return (
            <div>
                <div className="body-container white-background">
                    <h1 className="new-application-header chip-header">{this.props.match.params.id ? "Edit Cluster" : "New Cluster"}</h1>
                    {loading
                        ?
                        <LoadingCloud />
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <hr></hr>
                                    <div className="new-application-input-container">
                                        <form onSubmit={this.handleSubmit} className="py-4">
                                            <Textbox
                                                name="clusterName"
                                                default={clusterName}
                                                type="text"
                                                label="Cluster Name"
                                                tooltip="The name of the cluster (without the environment type!)"
                                                maxlength="1000"
                                                pattern="^[a-zA-Z0-9]([-_a-zA-Z0-9]*[a-zA-Z0-9])?$"
                                                required="true"
                                                errorMessage="The name can contain only letters, numbers, underscores and hyphens. The name must start and end with letter or number."
                                                update={this.updateText}
                                            />
                                            {
                                                clusterInformationCollection.map((cluster, i) =>

                                                    <div className="card card-body my-2" key={i}>
                                                        <button className="my-2 align-self-end btn btn-sm btn-danger" type="button" key={i} onClick={() => this.removeEnvironment(i)}>Remove environment</button>
                                                        <Dropdown
                                                            key={`environment-${i}`}
                                                            name={`environment-${i}`}
                                                            label="Environment"
                                                            tooltip="Azure environment"
                                                            values={environments.values}
                                                            texts={environments.texts}
                                                            default={cluster.environment.toLowerCase()}
                                                            required="true"
                                                            errorMessage="Environment is required"
                                                            update={(name, value) => this.updateEnvironmentValue(i, value)}
                                                        />
                                                        <Textbox
                                                            key={`resourceId-${i}`}
                                                            name={`resourceId-${i}`}
                                                            type="text"
                                                            label={`Azure AKS Cluster Resource Id for ${cluster.environment.toUpperCase()}`}
                                                            tooltip="it should look like '/subscriptions/xxxx-xxx/resourceGroups...'"
                                                            maxlength="1000"
                                                            pattern="^\/subscriptions\/.*\/managedClusters\/.*$"
                                                            default={cluster.clusterResourceId}
                                                            required="true"
                                                            errorMessage=""
                                                            update={(name, value) => this.updateResourceIdValue(i, value)}
                                                        />
                                                        <Textbox
                                                            key={`region-${i}`}
                                                            name={`region-${i}`}
                                                            type="text"
                                                            label="Azure Region"
                                                            tooltip="Azure Region"
                                                            maxlength="1000"
                                                            default={cluster.region}
                                                            required="true"
                                                            errorMessage=""
                                                            update={(name, value) => this.updateRegionValue(i, value)}
                                                        />
                                                    </div>

                                                )
                                            }
                                            <input type="submit" className="btn btn-primary mr-4" value={this.props.match.params.id ? "Save Cluster" : "Create New Cluster"} />
                                            <button type="button" className="btn btn-secondary" onClick={this.addEnvironment}>Add another environment</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}