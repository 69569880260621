import React, { Component } from 'react';
import '../../css/Reports.css';
import '../../css/SubscriptionDetailsReport.css';
import { ReportSelectorDropdowns } from './ReportSelectorDropdowns';
import { ReportChart } from './ReportChart';
import { List } from '../List';
import { ReportDetailsModal } from './ReportDetailsModal';
import { SubscriptionDetailsReport } from './SubscriptionDetailsReport';
import { LoadingCloud } from '../LoadingCloud';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class Reports extends Component {
    static displayName = "Reports";
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            reportType: this.props.match.params.reportType,
            subscriptionName: this.props.match.params.subscriptionName,
            chartData: null,
            chartSideClass: "chart-box animated-fast fadeOutLeft",
            chartDiagramClass: "chart-box animated-fast slideOutLeft",
            menuToggleClass: "btn btn-secondary inline-block-div animated-fast btnFadeInLeft",
            expandBtnClass: "animated-fast rotateIn",
            appDetailsDictionary: null,
            tableData: null,
            columns: null,
            AADGroupName: null,
            AADGroupOwnerEmail: null,
            AADGroupOwnerName: null,
            recipients: null,
            subscriptionDetailsRequestObj: null,
            loading: false
        };

        this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
        this.updateText = this.updateText.bind(this);
        this.toggleChartInfoSwitch = this.toggleChartInfoSwitch.bind(this);
    }

    componentDidMount() {
        this.getReportData();
    }

    async getChartData() {
        return new Promise(async (res, rej) => {
            try {
                var chartDataResponse = null;

                switch (this.state.reportType) {
                    case "dbdtuconsumption":
                        chartDataResponse = await authenticatedApiFetch(`api/Report/GetReportHealthData?reportType=sqlcosthealth&subscription=${this.state.subscriptionName}`, {});
                        break;
                    case "cosmoscosthealth":
                        chartDataResponse = await authenticatedApiFetch(`api/Report/GetReportHealthData?reportType=cosmoscosthealth&subscription=${this.state.subscriptionName}`, {})
                        break;
                    default:
                        chartDataResponse = await authenticatedApiFetch(`api/Report/GetReportHealthData?reportType=${this.state.reportType}&subscription=${this.state.subscriptionName}`, {});
                        break;
                }
                if (chartDataResponse) {
                    var chartData = await chartDataResponse.json();
                    this.setState({ chartData: chartData }, res);
                }
                else {
                    this.setState({ noChartData: true }, res);
                }
            } catch (err) {
                this.context.handleError(err);
                throw err;
            }
        });
    }

    isTableRefreshRequired() {
        if (this.state.reportType === 'dbdtuconsumption') {
            return true;
        } else {
            var lastPathName = this.props.history.location.pathname;
            return lastPathName.includes('dbdtuconsumption');
        }

    }

    async getTableData() {
        return new Promise(async (res, rej) => {
            try {
                if (this.isTableRefreshRequired()) {
                    this.setState({ tableData: null, columns: null });
                }

                if (this.state.reportType === "dbdtuconsumption") {
                    var fetchedAppDetailsResponse = await authenticatedApiFetch(`api/Report/GetSqlReportDetailedData?subscription=${this.state.subscriptionName}`, {});
                    var fetchedAppDetails = await fetchedAppDetailsResponse.json();
                    this.setState({
                        columns: fetchedAppDetails.columns,
                        tableData: fetchedAppDetails.tableEntities,
                    }, res);

                } else if (this.state.reportType === "cosmoscosthealth") {
                    var fetchedAppDetailsResponse = await authenticatedApiFetch(`api/Report/GetCosmoReportDetailedData?subscription=${this.state.subscriptionName}`, {});
                    var fetchedAppDetails = await fetchedAppDetailsResponse.json();

                    this.setState({
                        columns: fetchedAppDetails.columns,
                        tableData: fetchedAppDetails.cosmosEntities,
                    }, res);
                }
                else if (this.state.reportType === "appserverfarmhealth") {
                    var fetchedAppDetailsResponse = await authenticatedApiFetch(`api/Report/GetAppServerFarmCostReportDetailedData?subscription=${this.state.subscriptionName}`, {});
                    var fetchedAppDetails = await fetchedAppDetailsResponse.json();
                    this.setState({
                        columns: fetchedAppDetails.columns,
                        tableData: fetchedAppDetails.appServerFarmEntities,
                    }, res);
                } else {
                    var fetchedAppDetailsResponse = await authenticatedApiFetch(`api/Report/GetReportDetailedData?reportType=${this.state.reportType}&subscription=${this.state.subscriptionName}`, {});
                    var fetchedAppDetails = await fetchedAppDetailsResponse.json();
                    this.setState({
                        columns: fetchedAppDetails.columns,
                        tableData: fetchedAppDetails.tableEntities,
                        appDetailsDictionary: fetchedAppDetails.appDetailsDictionary,
                    }, res);
                }
            } catch (err) {
                this.context.handleError(err);
                throw err;
            }
        });
    }

    updateText(name, value) {

        var newValues = value !== this.state.subscriptionName && value !== this.state.reportType;

        this.setState({
            [name]: value
        }, () => { if (newValues) { this.redirectToReport(); } });
    }

    async getSubscriptionDetails() {
        try {
            this.setState({ loading: true });
            var fetchedSubscriptionDetailsResponse = await authenticatedApiFetch(`api/Report/GetSubscriptionDetailsReport?subscription=${this.state.subscriptionName}`, {});

            var fetchedSubscriptionDetails = await fetchedSubscriptionDetailsResponse.json();
            this.setState({
                subscriptionDetailsRequestObj: fetchedSubscriptionDetails,
                AADGroupName: fetchedSubscriptionDetails.aadGroupName,
                AADGroupOwnerEmail: fetchedSubscriptionDetails.aadGroupOwnerEmail,
                AADGroupOwnerName: fetchedSubscriptionDetails.aadGroupOwnerName,
                recipients: fetchedSubscriptionDetails.emails,
                AADSubscriptionOwnerID: fetchedSubscriptionDetails.aadSubscriptionOwnerId,
                loading: false
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }

    }

    getReportData() {
        if (this.state.reportType !== "subscriptiondetails") {
            this.setState({ loading: true });

            Promise.all([this.getTableData(), this.getChartData()]).then(() => {
                this.setState({
                    loading: false
                });
            });

        } else {
            this.getSubscriptionDetails();
        }
    }

    redirectToReport() {

        this.getReportData();

        this.props.history.push(`/Reports/${this.state.reportType}/${this.state.subscriptionName}`);
    }

    toggleDetailsModal() {
        this.setState({ isDetailsModalOpen: !this.state.isDetailsModalOpen });
    }

    getReportTitle(reportType) {
        switch (reportType) {
            case 'appservicealert':
                return "App Service Alerts";
            case 'appserviceplanalert':
                return "App Service Plan Alerts";
            case 'autoscale':
                return "App Service Plan Autoscale";
            case 'analytics':
                return "Azure Analytics";
            case 'dbdtuconsumption':
                return "Azure SQL Cost";
            case 'cosmoscosthealth':
                return "Cosmos Cost";
            case 'appserverfarmhealth':
                return "App Server Plan Cost"
            default:
                return ""
        }
    }

    toggleChartInfoSwitch() {
        if (this.state.chartSideClass.includes('fadeInLeft')) {

            var newChartSideClass = this.state.chartSideClass.replace("fadeInLeft", "fadeOutLeft");
            var newChartDiagramClass = this.state.chartDiagramClass.replace("slideInLeft", "slideOutLeft");
            var newMenuToggleClass = this.state.menuToggleClass.replace("btnFadeOutLeft", "btnFadeInLeft");
            var newExpandButtonClass = this.state.expandBtnClass.replace("rotateOut", "rotateIn");

            this.setState({
                chartSideClass: newChartSideClass,
                chartDiagramClass: newChartDiagramClass,
                menuToggleClass: newMenuToggleClass,
                expandBtnClass: newExpandButtonClass
            });


        } else if (this.state.chartSideClass.includes('fadeOutLeft')) {

            var newChartSideClass2 = this.state.chartSideClass.replace("fadeOutLeft", "fadeInLeft");
            var newChartDiagramClass2 = this.state.chartDiagramClass.replace("slideOutLeft", "slideInLeft");
            var newMenuToggleClass2 = this.state.menuToggleClass.replace("btnFadeInLeft", "btnFadeOutLeft");
            var newExpandButtonClass2 = this.state.expandBtnClass.replace("rotateIn", "rotateOut");

            this.setState({
                chartSideClass: newChartSideClass2,
                chartDiagramClass: newChartDiagramClass2,
                menuToggleClass: newMenuToggleClass2,
                expandBtnClass: newExpandButtonClass2
            });

        } else {

            this.setState({
                chartSideClass: this.state.chartSideClass + 'fadeInLeft'
            });

        }
    }

    render() {

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ selectedApp: this.state.appDetailsDictionary[row.app_name], isDetailsModalOpen: true });
            }
        };

        return (
            <div>
                <ReportSelectorDropdowns
                    updateText={this.updateText}
                    reportType={this.props.match.params.reportType}
                    subscriptionName={this.props.match.params.subscriptionName}
                    reportSelectorClass={"side-by-side-report-selector"}
                    tooltipsVisible={false}
                />

                {this.state.loading ?

                    <LoadingCloud />

                    :

                    <div className="report-content-container">
                        {this.state.reportType !== 'subscriptiondetails' &&

                            <div>
                                {this.state.chartData &&
                                    <ReportChart
                                        chartData={this.state.chartData}
                                        reportTitle={this.getReportTitle(this.state.reportType)}
                                        subscription={this.state.subscriptionName}
                                        chartSideClass={this.state.chartSideClass}
                                        chartDiagramClass={this.state.chartDiagramClass}
                                        menuToggleClass={this.state.menuToggleClass}
                                        expandBtnClass={this.state.expandBtnClass}
                                        toggleChartInfoSwitch={this.toggleChartInfoSwitch}
                                    />
                                }

                                {this.state.columns && this.state.tableData && this.state.tableData.length > 0 &&
                                    <List
                                        className="list"
                                        data={this.state.tableData}
                                        columns={this.state.columns}
                                        keyField={'id'}
                                        showPagination={true}
                                        rowClasses="interactive-table-row"
                                        rowEvents={this.state.reportType !== 'dbdtuconsumption' && this.state.reportType !== 'cosmoscosthealth' && this.state.reportType !== 'appserverfarmhealth' && rowEvents}
                                    />
                                }

                                <ReportDetailsModal
                                    isDetailsModalOpen={this.state.isDetailsModalOpen}
                                    toggleDetailsModal={this.toggleDetailsModal}
                                    appData={this.state.selectedApp}
                                />
                            </div>
                        }

                        {this.state.reportType === 'subscriptiondetails' && this.state.recipients &&
                            <SubscriptionDetailsReport
                                AADGroupName={this.state.AADGroupName}
                                AADGroupOwnerEmail={this.state.AADGroupOwnerEmail}
                                AADGroupOwnerName={this.state.AADGroupOwnerName}
                                recipients={this.state.recipients}
                                subscriptionDetailsRequestObj={this.state.subscriptionDetailsRequestObj}
                            />
                        }

                    </div>

                }
            </div>
        );

    }
}