import React, { Component } from 'react';
import { ChipModal } from './ChipModal';
import { ApplicationForm } from './ApplicationForm';

export class ApplicationFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.updateText = this.updateText.bind(this);
        this.disableApplicationDetailsBtn = this.disableApplicationDetailsBtn.bind(this);
        this.setStateToPropsFormValues = this.setStateToPropsFormValues.bind(this);
    }

    updateText(name, value) {
        
        this.setState({
            [name]: value
        });

        if (name === 'vstsProjectName') {
            this.setState({
                vstsPortalUrl: `https://dev.azure.com/itsals/${value}`
            });
        }
    }

    setStateToPropsFormValues() {
        this.setState({
            applicationName: this.props.formValues.applicationName,
            productName: this.props.formValues.productName,
            description: this.props.formValues.description,
            teamName: this.props.formValues.teamName,
            teamEmail: this.props.formValues.teamEmail,
            productOwnerName: this.props.formValues.productOwnerName,
            productOwnerEmail: this.props.formValues.productOwnerEmail,
            pii: this.props.formValues.pii,
            pci: this.props.formValues.pci,
            disasterRecoveryPlan: this.props.formValues.disasterRecoveryPlan,
            escalation: this.props.formValues.escalation,
            solutionDesign: this.props.formValues.solutionDesign,
            governance: this.props.formValues.governance,
            security: this.props.formValues.security,
            vstsProjectName: this.props.formValues.vstsProjectName,
            vstsPortalUrl: this.props.formValues.vstsPortalUrl,
            vstsProjectNames: this.props.vstsProjects,
            tiers: this.props.formValues.tiers,
            isAzureDevops: this.props.formValues.isAzureDevops,
            isGithub: this.props.formValues.isGithub
        });
    }

    componentDidMount() {
        this.setStateToPropsFormValues();
    }
    
    disableApplicationDetailsBtn() {
        let disableState = false;
        if (!(this.state.productName && this.state.applicationName && this.state.productName &&
            this.state.description && this.state.teamName && this.state.teamEmail &&
            this.state.productOwnerName && this.state.productOwnerEmail)) {
            disableState = true;
        }

        if (this.state.isAzureDevops == false && this.state.isGithub == false) {
            disableState = true;
        }
        if (this.state.isGithub) {
            if (this.state.githubOrganization == null || this.state.githubOrganization == "") {
                disableState = true;
            }
            if (this.state.githubRepository == null || this.state.githubRepository == "") {
                disableState = true;
            }
        }

        if (this.state.isAzureDevops) {
            if (this.state.vstsProjectName == null || this.state.vstsProjectName == "") {
                disableState = true;
            }
        }

        return disableState;
           
    }

    render() {
        return (
            <div>
                <ChipModal
                    isOpen={this.props.isOpen}
                    toggleModal={this.props.toggleModal}
                    buttonAction={this.props.buttonAction}
                    disabled={this.disableApplicationDetailsBtn()}
                    modalClassName="modal-90w"
                    modalTitle="Application Details"
                    modalBody={<ApplicationForm
                        origin="modal"
                        updateState={(name, value) => {
                            this.props.updateState(name, value);
                            this.updateText(name, value)
                        }}
                        appID={this.props.appID}
                        formValues={this.props.formValues}
                        isDisabled={false}
                        vstsProjects={this.props.vstsProjects}
                        githubOrg={ this.props.githubOrg}
                    />}
                />
            </div>
        );
    }
}