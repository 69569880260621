import React, { Component } from 'react';
import '../../../src/css/Help.css'
import codingImg from '../../img/CHIPv1/coding.png';
import searchImg from '../../img/CHIPv1/search.png';
import iconServiceLineImg from '../../img/CHIPv1/icon-service-line.svg';
import iconInformationImg from '../../img/CHIPv1/icon_information.png';
import findAppImg from '../../img/CHIPv1/findApp.png';
import newAppImg from '../../img/CHIPv1/newApp.PNG';

export class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (

            <div className="Help">
                <div className="box">
                    <h3>Welcome to CHIP Help Page</h3>
                    <p>
                        This step-by-step guide walks you through how to make a request for an application. Currently we have a request that can provision a SPN, VSTS Endpoint, and a Resource Group. This will enable the user to release the application to the cloud through VSTS.
                    </p>
                </div>



                <div className="help-wrapper">
                    <hr />
                    <div className="w3-third">
                            Create Application
                            <br />
                            <img src={codingImg} alt="Create App" />

                    </div>
                    <div className="w3-third">
                            Find Application <br />
                            <img src={searchImg} alt="Find App" />

                    </div>
                    <div className="w3-third">
                            Make a Request <br />
                            <img src={iconServiceLineImg} alt="Create SPN Request" />

                    </div>
                </div>

                <hr />

                <div className="help-body">
                    <div className="createAppPortfolio">
                        <h3 id="CreateApplication">Create Application</h3>
                        <ol>
                            <li> Sign in with your Alaska SSO Credentials.</li>
                            <li> On the navigation bar select "New Application".</li>
                            <li> Fill out Application Portfolio and then click on the "SAVE" button. If an application is already completed click on this link to find your application to create a request <a href="#FindApplication">here</a>.</li>
                            <img src={newAppImg} className="help-category-img" alt="Create SPN Request" />
                        </ol>
                       
                    </div>

                    <div>
                        <h3 id="FindApplication">Find Application</h3>
                        <p>If you recently completed creating the application skip steps 1 - 4 and continue to creating a request <a href="#CreateRequest">here</a>.</p>
                        <ol>
                            <li> On the navigation bar click on "Applications".</li>
                            <li> Search your application name on the search bar.</li>
                            <li> Select "View More" once your application is found. If your application is not found create a new application <a href="#CreateApplication"> here</a>.</li>
                            <img src={findAppImg} className="help-category-img" alt="find your app" />
                        </ol>

                    </div>

                    <div>
                        <h3 id="CreateSpnRequest">Make a Request</h3>
                        <ol>
                            <li>In order to create a request the user should already have an application created. Search if your application exist <a href="#FindApplication"> here</a>.</li>
                            <li>On your application details page, scroll down until you see the “ADD REQUEST” button.</li>
                            <li>Select request type and fill in the fields then click save. You should receive an email from Cherwell that a ticket has been created. Once the application is approved by the admin you will receive an email with your request being completed.</li>
                        </ol>
                    </div>

                </div>
            </div>
        );
    }
}