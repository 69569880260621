import React, { Component } from 'react';
import '../css/Textbox.css';
import { Alert } from 'reactstrap';


export class Textbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.default ? this.props.default : '',
            valid: true,
            focused: false,
            tooltipVisible: false,
            tooltipLocation: {},
            pattern: this.props.pattern
        };
    }

    componentDidMount() {
        let emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let urlRe = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        let ipRe = "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$";

        let regexPattern = this.props.pattern;
        if (this.props.type === 'email') regexPattern = emailRe;
        if (this.props.type === 'url') regexPattern = urlRe;
        if (this.props.type === 'ip') regexPattern = ipRe;

        this.setState({ pattern: regexPattern });

        if (this.props.update) {
            this.props.update(this.props.name, this.state.value);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.default !== prevProps.default) {
            this.setState({ value: this.props.default });
        }

        if (this.props.currentSidebarOption !== prevProps.currentSidebarOption) {
            this.reconfigureSidedropdown(this.props.currentSidebarOption);
        }
    }

    componentWillReceiveProps() {
        if (this.props.disabled) {
            this.setState({ value: this.props.default });
        }
    }

    handleChange(event) {
        let value = event.target.value;
        let valid;

        let regex = new RegExp(this.state.pattern);

        valid = (!this.props.maxlength ||
            value.length <= this.props.maxlength)
            && regex.test(value)
            && (this.props.required !== 'true' || value.length > 0);

        this.setState({
            value: value,
            valid: valid
        });

        this.props.update(this.props.name, valid ? value : null);
    }

    handleSidedropdownChange(value) {
        this.setState({
            value: ""
        }, () => { this.reconfigureSidedropdown(value); });
    }

    reconfigureSidedropdown(value) {
        let option = this.props.sideDropdownOptions[value];

        this.setState({
            placeholder: option.placeholder,
            pattern: option.pattern,
            focused: true,
            currentSidebarOption: value
        }, () => { this.textbox.reportValidity(); });
    }

    toggleTooltip(show, event) {
        let targetRect = event.target.getBoundingClientRect();
        let parentRect = event.target.offsetParent.offsetParent.getBoundingClientRect()
        this.setState({
            tooltipVisible: show,
            tooltipLocation: {
                top: targetRect.top - parentRect.top - 8,
                left: targetRect.left - parentRect.left + 18
            }
        });
    }

    focusChange(state) {
        this.setState({
            focused: state
        })
    }

    render() {
        let textboxInUse = this.state.value !== '' || this.state.focused;

        return (
            <div className={`textbox ${!this.props.disabled && (this.props.editing || !this.state.value) && 'textbox-active'}`}>
                <div
                    className={`input-tooltip ${this.state.tooltipVisible ? 'visible' : ''}`}
                    style={this.state.tooltipLocation}
                >
                    {this.props.tooltip}
                </div>
                <label
                    className={`input-label ${textboxInUse ? 'input-label-active' : ''}`}
                    htmlFor={this.props.name}
                >
                    <span
                        onClick={() => this.textbox.focus()}
                    >
                        {this.props.label}
                    </span>
                    <span
                        className={`input-required ${this.props.required !== 'true' ? 'invisible' : ''}`}
                    >
                        *
                    </span>
                    <span
                        className={`input-information ${textboxInUse ? 'invisible' : ''}`}
                        name={this.props.name}
                        onMouseOver={(evt) => this.toggleTooltip(true, evt)}
                        onMouseOut={(evt) => this.toggleTooltip(false, evt)}
                    >
                        i
                    </span>
                </label>

                <div className={`${this.props.sideDropdownOptions && 'sidedropdown-textbox'}`}>
                    <input
                        className={`input-master ${this.props.required && this.props.editing ? 'required-input' : ''} ${!this.state.valid ? 'input-error' : ''} ${this.props.sideDropdownOptions && 'sidedropdown-input'}`}
                        type={this.props.type}
                        name={this.props.name}
                        value={this.state.value}
                        maxLength={this.props.maxlength}
                        pattern={this.state.pattern}
                        required={this.props.required === 'true'}
                        onChange={(evt) => this.handleChange(evt)}
                        onFocus={() => this.props.onFocus ? this.props.onFocus() : this.focusChange(true)}
                        onBlur={() => this.props.onBlur ? this.props.onBlur() : this.focusChange(false)}
                        ref={(input) => { this.textbox = input; }}
                        disabled={this.props.disabled ? 'disabled' : ''}
                        placeholder={this.state.focused ? this.state.placeholder : ""}
                    />
                    {this.props.sideDropdownOptions &&
                        <select
                            className={`input-master dropdown-select sidedropdown-dropdown ${this.props.required == "true" && this.props.editing ? 'required-input required-sidedropdown' : ''}`}
                            onChange={(evt) => this.handleSidedropdownChange(evt.target.value)}
                            value={this.state.currentSidebarOption}
                            disabled={this.props.disabled ? 'disabled' : ''}
                        >
                            {this.props.sideDropdownOptions.map((x, index) => {
                                return <option value={index}>{x.text}</option>
                            })}
                        </select>
                    }
                </div>
                <div className={`input-errorMessage ${!this.state.valid ? 'visible' : ''}`}>
                    {this.props.errorMessage}
                </div>



                {this.props.alert &&

                    <Alert color={this.props.alert.color}>
                        {this.props.alert.text}
                    </Alert>
                }

            </div>
        );
    }
}