import React, { useState, useEffect } from 'react'
import { Dropdown } from '../Dropdown';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';

function RegionDropDown({ updateText, disabled, selectedSubscriptions, region, applicationName }) {
    const [regionErrorMessage, setregionErrorMessage] = useState()
    const [isWarning, setisWarning] = useState(false);
    useEffect(() => {
        checkResourceGroupName()
    }, [region,selectedSubscriptions])

    let checkResourceGroupName = async () => {
        if (region != null && region != "" && region != "WEST US 2" && region != "WEST CENTRAL US") {
            setisWarning(true);
        } else {
            setisWarning(false);
        }
        if(selectedSubscriptions != null && selectedSubscriptions.length > 0 && region != ""){

            let errorMessage = "";

            for(let i = 0; i < selectedSubscriptions.length; i++){
                let environment = selectedSubscriptions[i].substring(selectedSubscriptions[i].lastIndexOf('.')+1,selectedSubscriptions[i].length)
                let resourceGroupName = `${applicationName}-${environment}-group`
                let response = await authenticatedApiFetch(`api/ResourceManagement/ResourceGroupAvailability?subscriptionName=${selectedSubscriptions[i]}&resourceGroupName=${resourceGroupName}`, {})
                if(response.ok) {
                    let jsonResponse = await response.json();                
                    var regionStr = region;
                    // removes all spaces from the string and lower the string
                    regionStr = regionStr.replace(/\s+/g, '').toLowerCase();
                    if (jsonResponse.location && jsonResponse.location != regionStr) {
                        errorMessage += `Resource group ${resourceGroupName.toLowerCase()} exist in ${jsonResponse.location} in ${selectedSubscriptions[i]}. Remove ${selectedSubscriptions[i]} to proceed. `
                        updateText("region", "")
                    } 
                }
            }
            setregionErrorMessage(errorMessage);
        }
        
    }
    let regions = ["WEST US 2", "WEST US", "CENTRAL US", "EAST US", "EAST US 2", "NORTH CENTRAL US", "SOUTH CENTRAL US", "WEST CENTRAL US"];

    return (
        <div>
            <Dropdown
                name="region"
                label="Region"
                tooltip="The location of the resource group. Default:West US 2"
                values={regions}
                texts={regions}
                required="true"
                errorMessage="Region is required"
                update={updateText}
                default={region}
                disabled={disabled}
                alert={regionErrorMessage && { color: "danger", text: regionErrorMessage }}
                warning={isWarning && { color: "warning", text: "Please Select Our Standard Region-Pairs: WestUS2 or WestCentralUS. Non-Standard Regions are only permitted if your requested resource(s) are not available in Standard Regions." }}
             />
        </div>
    )
}

export default RegionDropDown
