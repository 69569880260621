import React, { Component } from 'react';

export class AppServicePlanAlertModalContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cpuAlertCompliance: "",
            cpuAlertThreshold: "",
            actionGroupContactPoints: "",
            httpQueueCompliance: "",
            httpQueueThreshold: "",
            memoryQueueCompliance: "",
            memoryQueueThreshold: "",
            diskQueueCompliance: "",
            diskQueueThreshold: ""
        };
    }

    componentDidMount() {
        this.findAndSetAlertValues(this.props.resourceDetailsData);
    }

    findAndSetAlertValues(resourceDetailsData) {

        var cpuRule =
            resourceDetailsData.find(x => x.resourceDetailName === "CpuPercentageThreshold");
        var diskQueueRule =
            resourceDetailsData.find(x => x.resourceDetailName === "DiskQueueLengthThreshold");
        var memoryRule =
            resourceDetailsData.find(x => x.resourceDetailName === "MemoryPercentageThreshold");
        var httpQueueRule =
            resourceDetailsData.find(x => x.resourceDetailName === "HttpQueueLengthThreshold");
        var actionGroupRule =
            resourceDetailsData.find(x => x.resourceDetailName === "AlertsContactInformationCount");

        this.setState({
            cpuAlertCompliance: cpuRule.resourceDetailGrade,
            cpuAlertThreshold: cpuRule.resourceDetailValue,
            actionGroupContactPoints: actionGroupRule.resourceDetailValue,
            httpQueueCompliance: httpQueueRule.resourceDetailGrade,
            httpQueueThreshold: httpQueueRule.resourceDetailValue,
            memoryQueueCompliance: memoryRule.resourceDetailGrade,
            memoryQueueThreshold: memoryRule.resourceDetailValue,
            diskQueueCompliance: diskQueueRule.resourceDetailGrade,
            diskQueueThreshold: diskQueueRule.resourceDetailValue
        });

    }

    render() {

        let isCpuDynamic = this.state.cpuAlertThreshold == 0 && this.state.cpuAlertCompliance == 'FullyCompliant';
        let isDiskDynamic = this.state.diskQueueThreshold == 0 && this.state.diskQueueCompliance == 'FullyCompliant';
        let isMemoryDynamic = this.state.memoryQueueThreshold == 0 && this.state.memoryQueueCompliance == 'FullyCompliant';
        let isHttpQueueDynamic = this.state.httpQueueThreshold == 0 && this.state.httpQueueCompliance == 'FullyCompliant';

        return (
            <div>
                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">CPU Alert Rule Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">CPU Alert Threshold:</h3>
                            </div>

                        </div>

                        <div className="col-4 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Action Group Contact Points:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.cpuAlertCompliance}</div>
                        </div>
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{isCpuDynamic ? 'Dynamic' : this.state.cpuAlertThreshold}</div>
                        </div>
                        <div className="col-4 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.actionGroupContactPoints}</div>
                        </div>
                    </div>
                </div>

                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http Queue Length Alert Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Http Queue Length Alert Threshold:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">

                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.httpQueueCompliance}</div>
                        </div>
                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{isHttpQueueDynamic ? 'Dynamic' : this.state.httpQueueThreshold}</div>
                        </div>
                    </div>
                </div>
                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Memory Percentage Alert Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Memory Percentage Alert Threshold:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">

                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.memoryQueueCompliance}</div>
                        </div>
                            <div className="col-6 lgmod-box lgmod-box lgmod-col">
                                <div>{isMemoryDynamic ? 'Dynamic' : this.state.memoryQueueThreshold}</div>
                        </div>
                    </div>
                </div>
                <div className="row lgmod-row">
                    <div className="row lgmod-row lgmod-box-title-row lgmod-box-title-row lgmod-padded-row">

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Disk Queue Length Alert Compliance:</h3>
                            </div>

                        </div>

                        <div className="col-6 lgmod-col">

                            <div className="lgmod-title-wrapper">
                                <h3 className="lgmod-box-title">Disk Queue Length Alert Threshold Value:</h3>
                            </div>

                        </div>

                    </div>

                    <div className="row lgmod-row lgmod-padded-row">

                        <div className="col-6 lgmod-box lgmod-box lgmod-col">
                            <div>{this.state.diskQueueCompliance}</div>
                        </div>
                            <div className="col-6 lgmod-box lgmod-box lgmod-col">
                                <div>{isDiskDynamic ? 'Dynamic' : this.state.diskQueueThreshold}</div>
                        </div>
                    </div>
                </div>

                <div className="lgmod-group" />
            </div>
        );
    }
}