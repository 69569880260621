import React, { Component } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import { Checkbox } from '../Checkbox';


export class Kubernetes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let projectValues =  this.props.projects.map(project => project.name);        
        let clusterValues = {
            texts: this.props.clusters.map(cluster => cluster.clusterName),
            values: this.props.clusters.map((cluster) => cluster.id),
        }
        return (
            <div>
                <Dropdown
                    name="cluster"
                    label="Kubernetes Cluster"
                    tooltip="Kubernetes cluster to setup namespace in."
                    values={clusterValues.values}
                    texts={clusterValues.texts}
                    required="true"
                    errorMessage="Cluster is required"
                    disabled={this.props.disabled}
                    update={this.props.updateText}
                />   
                <Textbox
                    name="namespace"
                    type="text"
                    label="Namespace"
                    tooltip="The name of the namespace. Do NOT include environment (test,qa,prod)."
                    maxlength="1000"
                    pattern="^[a-z0-9]([-_a-z0-9]*[a-z0-9])?$"
                    required="true"
                    disabled={this.props.disabled}
                    errorMessage="The name can contain only lowercase letters, numbers, underscores and hyphens. The name must start and end with letter or number."
                    update={this.props.updateText}
                />
                <Dropdown
                    name="devopsProject"
                    label="Devops Project"
                    tooltip="Azure Devops project to create environment in."
                    values={projectValues}
                    texts={projectValues}
                    required="true"
                    disabled={this.props.disabled}
                    errorMessage="Devops project is required"
                    update={this.props.updateText}
                />
            </div>
        );
    }
}
