import React, { Component } from 'react';
import { RequestTable } from './RequestTable';
import { ShadowedColumnCard } from '../ShadowedColumnCard'
import { constructRequestListAndDictFromNested } from '../../helpers/RequestHelper';
import { RequestDetailsModal } from './RequestDetailsModal'
import { LoadingCloud } from '../LoadingCloud';
import '../../css/Requests.css';
import { auth } from '../../adalConfig';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class NetworkRequest extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            newRequestList: [],
            approvedRequestList: [],
            failedRequestList: [],
            submittedRequestList: [],
            fetchedRequestDictionary: {},
            isRequestDetailsModalOpen: false,
            requestDetails: null,
            fetching: true,
            userContext: null,
            isAdmin: false
        };

        this.fetchNewData = this.fetchNewData.bind(this);
        this.toggleRequestDetailsModal = this.toggleRequestDetailsModal.bind(this);
        this.showRequestDetails = this.showRequestDetails.bind(this);
        this.setContext = this.setContext.bind(this);
    }

    componentDidMount() {

        auth.then((response) => {
            response.authContext.getUser(this.setContext);

            try {
                this.setState({ fetchInterval: setInterval(this.fetchAllRequests, 5000) });
            } catch (e) {
                console.log(e);
            }
            this.fetchNewData();
        });

    }

    setContext(err, context) {

        if (context && context.profile.roles &&
            (context.profile.roles.includes("NetworkAdmin") ||
                context.profile.roles.includes("SuperUser") ||
                context.profile.roles.includes("SecurityAdmin"))) {
            this.setState({
                userContext: context,
                isAdmin: true
            });
        } else {
            this.setState({
                userContext: context,
                isAdmin: false
            })
        }

    }

    componentWillUnmount() {
        clearInterval(this.state.fetchInterval);
    }

    async fetchNewData() {
        try {
            const allRequestsResponse = await authenticatedApiFetch(`/api/Request/GetAllNetworkRequests`, {});
            const allRequests = await allRequestsResponse.json();
            var constructedLists = constructRequestListAndDictFromNested(allRequests);

            this.setState({
                newRequestList: constructedLists.newRequestList,
                approvedRequestList: constructedLists.approvedCompletedRequestList,
                submittedRequestList: constructedLists.submittedRequestList,
                failedRequestList: constructedLists.failedRequestList,
                fetchedRequestDictionary: constructedLists.formattedRequestDictionary,
                approvedByNetworking: constructedLists.approvedByNetworking,
                approvedBySecurity: constructedLists.approvedBySecurity,
                rejectedList: constructedLists.rejectedList,
                fetching: false
            });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    toggleRequestDetailsModal() {
        this.setState(prevState => ({
            isRequestDetailsModalOpen: !prevState.isRequestDetailsModalOpen
        }));
    }

    showRequestDetails(clickedRequestDetails) {
        this.setState({ requestDetails: clickedRequestDetails }, () => {
            this.toggleRequestDetailsModal();
        });
    }

    render() {
        return (
            this.state.fetching ?
                <LoadingCloud />
                :
                <div>
                    {this.state.isAdmin &&
                        <div className="request-page-container">

                            <h1 className="request-page-title"> Network Requests: </h1>
                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="Submitted"
                                    colWidth={12}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.submittedRequestList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.submittedRequestList.length > 5}
                                            listClassName="allrequests-table"
                                        />
                                    }
                                />
                            </div>
                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="Rejected"
                                    colWidth={12}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.rejectedList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.rejectedList.length > 5}
                                            listClassName="allrequests-table"
                                        />
                                    }
                                />
                            </div>
                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="Approved by Networking, Pending Security"
                                    colWidth={6}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.approvedByNetworking}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.approvedByNetworking.length > 5}
                                            listClassName="allrequests-table"
                                            hideSearch={this.state.approvedRequestList.length === 0}
                                        />
                                    }
                                />
                                <ShadowedColumnCard
                                    cardTitle="Ready to Implement"
                                    colWidth={6}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.approvedBySecurity}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.approvedBySecurity.length > 5}
                                            listClassName="allrequests-table"

                                        />
                                    }
                                />
                            </div>


                            <RequestDetailsModal
                                isRequestDetailsModalOpen={this.state.isRequestDetailsModalOpen}
                                toggleRequestDetailsModal={this.toggleRequestDetailsModal}
                                requestDetails={this.state.requestDetails && this.state.requestDetails}
                                fetchNewData={this.fetchNewData}
                            />
                        </div>
                    }
                </div>
        );
    }
}