import { runWithAdal } from 'react-adal';
import { auth } from './adalConfig';
import { sentry } from './sentryConfig';
import * as Sentry from "@sentry/react";

const DO_NOT_LOGIN = false;

sentry.then((response) => {
    Sentry.init(response);
});

auth.then((response) => {
    runWithAdal(
        response.authContext,
        () => { require('./indexApp'); },
        DO_NOT_LOGIN
    );
});
