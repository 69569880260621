import React, { Component } from "react";
import "../css/Dropdown.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Alert } from 'reactstrap';



const animatedComponents = makeAnimated();


export class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.default != null ? this.props.default : "",
      valid: true,
      options: [],
      defaultValues: [],
    };
  }

    
  
  componentDidMount() {

    this.initOptions();
    if (this.props.update) {
      this.props.update(this.props.name, this.state.value);
    }
  }

  componentDidUpdate(prevProps) {

    if (
      this.props.values !== prevProps.values ||
      this.props.texts !== prevProps.texts
    ) {
      this.initOptions();
    }
    if (this.props.default !== prevProps.default || this.props.alert !== prevProps.alert) {
      this.setState({ value: this.props.alert ? "" : this.props.default});
    }

  }

  handleChange(event) {
    let value = event.target.value;

    let valid = this.props.required !== "true" ? true : value ? true : false;

    this.setState({
      value: value,
      valid: valid,
    });
    this.props.update(this.props.name, valid ? value : null);
  }

  handleMultipleChange(e) {
    let value = [];
    if (e !== null) {
      for (var i = 0, l = e.length; i < l; i++) {
        value.push(e[i].value);
      }
      this.setState({
        value: value,
      });
    } else {
      this.setState({
        value: "",
      });
    }
    this.props.update(this.props.name, value.length > 0 ? value : null);
  }

  toggleTooltip(show, event) {
    let targetRect = event.target.getBoundingClientRect();
    let parentRect = event.target.offsetParent.offsetParent.getBoundingClientRect();
    this.setState({
      tooltipVisible: show,
      tooltipLocation: {
        top: targetRect.top - parentRect.top - 8,
        left: targetRect.left - parentRect.left + 18,
      },
    });
  }
  componentWillReceiveProps() {
    if (this.props.disabled) {
      this.setState({ value: this.props.default });
    }
  }

  initOptions() {
    let options = [];
    let defaultValues = this.state.defaultValues;

    if (this.props.values) {
      for (let i = 0; i < this.props.values.length; i++) {
        let value = this.props.values[i];
        let text = this.props.texts[i];
        if (this.props.multiple) {
          var multiselectOption = { value: value, label: text, key: value };

          options.push(multiselectOption);

          if (this.props.default && this.props.default.includes(value)) {
            defaultValues.push(multiselectOption);
          }
        } else {
          options.push(
            <option key={value} value={value}>
              {text}
            </option>
          );
        }
      }
    }
    this.setState({
      options: options,
      defaultValues: this.props.multiple ? defaultValues : null,
    });
  }

  render() {
    let dropdownInUse = this.state.value !== "";
    return (
      <div
        className={`dropdown ${
          !this.props.disabled &&
          (this.props.editing || !this.state.value) &&
          "dropdown-active"
        }`}
      >
        <div
          className={`input-tooltip ${
            this.state.tooltipVisible ? "visible" : ""
          }`}
          style={this.state.tooltipLocation}
        >
          {this.props.tooltip}
        </div>
        <label
          className={`input-label ${dropdownInUse ? "input-label-active" : ""}`}
          htmlFor={this.props.name}
        >
          {this.props.label}
          {this.props.required && (
            <span
              className={`input-required ${
                this.props.required !== "true" ? "invisible" : ""
              }`}
            >
              *
            </span>
          )}

          {this.props.tooltip && this.props.tooltip != "" && (
            <span
              className={`input-information ${
                dropdownInUse ? "invisible" : ""
              }`}
              name={this.props.name}
              onMouseOver={(evt) => this.toggleTooltip(true, evt)}
              onMouseOut={(evt) => this.toggleTooltip(false, evt)}
            >
              i
            </span>
          )}
        </label>

        {this.props.multiple ? (
        <Select
            placeholder={null}
            className={`input-master dropdown-select ${
                this.props.required == "true" && this.props.editing
                    ? "required-input"
                    : "na"
                } ${!this.state.valid ? "input-error" : ""} multiselect-dropdown`}
            onChange={(e) => this.handleMultipleChange(e)}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={this.state.options}
            styles={this.props.colourStyles}
            isDisabled={this.props.disabled}
            defaultValue={this.state.defaultValues}
        />
        ) : (
          <select
            className={`input-master dropdown-select ${
              this.props.required == "true" && this.props.editing
                ? "required-input"
                : "na"
            } ${!this.state.valid ? "input-error" : ""}`}
            onChange={(evt) => this.handleChange(evt)}
            name={this.props.name}
            value={this.props.multiple ? [this.state.value] : this.state.value}
            disabled={this.props.disabled ? "disabled" : ""}
          >
            <option></option>
            {this.state.options}
          </select>
        )}

        <div
          className={`input-errorMessage ${!this.state.valid ? "visible" : ""}`}
        >
          {this.props.errorMessage}
        </div>

        {this.props.alert && (
          <Alert color={this.props.alert.color}>{this.props.alert.text}</Alert>
            )}
        {this.props.warning && (
            <Alert color={this.props.warning.color}>{this.props.warning.text}</Alert>
        )}
      </div>
    );
  }
}


