import React, { Component } from 'react';
import { authenticatedApiFetch } from '../helpers/FetchHelper';
import { LoadingCloud } from './LoadingCloud';
import { ErrorContext } from "../ErrorContext";
import { ChipButton } from './ChipButton';
import '../css/KubernetesAdmin.css';

export class KubernetesAdmin extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            clusters: [

            ],
            loading: true,
        };
        this.fetchClusters = this.fetchClusters.bind(this);
        this.deleteCluster = this.deleteCluster.bind(this);
    }

    async fetchClusters() {
        try {
            let apiResponse = await authenticatedApiFetch('api/Kubernetes', {});
            let kubernetesClusters = await apiResponse.json();
            this.setState({ loading: false, clusters: kubernetesClusters || [] });
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    async deleteCluster(id) {
        this.setState({ loading: true });
        try {
            await authenticatedApiFetch(`api/Kubernetes/Delete/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify({}),
            });
            await this.fetchClusters();
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.fetchClusters();
    }

    render() {
        let { clusters, loading } = this.state;
        return (
            <div>
               
                {loading
                    ?
                    <LoadingCloud />
                    :

                    <div>
                        <div className="kubernetes-admin-header">
                            <h1 className="chip-header list-header">Kubernetes Clusters</h1>
                            <ChipButton action={() => this.props.history.push(`/Kubernetes/NewCluster`)}
                                buttonClassName="new-cluster-btn"
                                buttonText="New Cluster"
                                color="primary"
                            />
                        </div>

                        {clusters.map((cluster, i) =>
                            <div className="card my-2" key={i}>
                                <div className="card-body">
                                    <div key={`title-${i}`} className="card-title"><h4>{cluster.clusterName}</h4></div>
                                    <p key={`body-${i}`} className="card-text">
                                        Clusters: {[...new Set(cluster.clusterInformationCollection.map(information => information.clusterName))].join(', ')}
                                        <br />
                                        Environments: {[...new Set(cluster.clusterInformationCollection.map(information => information.environment))].join(', ')}
                                        <br />
                                        Regions: {[...new Set(cluster.clusterInformationCollection.map(information => information.region))].join(', ')}
                                    </p>
                                    <div className="kubernetes-cluster-actions">
                                        <ChipButton action={() => this.props.history.push(`/Kubernetes/EditCluster/${cluster.id}`)}
                                            buttonClassName="kubernetes-action-btn edit-cluster-btn"
                                            buttonText="Edit"
                                            color="primary"
                                        />
                                        <ChipButton action={() => { if (window.confirm('Are you sure you wish to delete this Kubernetes Cluster?')) this.deleteCluster(cluster.id) }}
                                            buttonClassName="kubernetes-action-btn delete-cluster-btn"
                                            buttonText="Delete"
                                            color="red"
                                            />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}