import React, { Component } from 'react';
import { Textbox } from '../Textbox';
import "../../css/RequestDetails.css"
import { ChipButton } from '../ChipButton'

export class CreateStandaloneSpnDetailsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {

            requestDetails: this.props.requestDetails,
            spnName: null,
            spnApplicationId: null,
            spnObjectId: null
        };

        this.updateText = this.updateText.bind(this);
        this.saveRequest = this.saveRequest.bind(this);

    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
    }

    saveRequest() {
        var updatedRequest = this.constructEditedRequestDetails();
        this.props.updateRequest(updatedRequest);
    }

    constructEditedRequestDetails() {
        var updatedRequestDetails = this.state.requestDetails;
        updatedRequestDetails.spnName = this.state.spnName;
        updatedRequestDetails.requester.contactInfo.name = this.state.requesterName;
        updatedRequestDetails.requester.contactInfo.email = this.state.requesterEmail;
        updatedRequestDetails.requester.manager.contactInfo.name = this.state.requesterManagerName;
        updatedRequestDetails.requester.manager.contactInfo.email = this.state.requesterManagerEmail;
        updatedRequestDetails.requester.groupName = this.state.requesterTeamName;
        return updatedRequestDetails;
    }

    render() {
        return (
            <div className="request-details-container">
                {this.props.requestDetails &&
                    <div>
                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Type"
                            update={this.updateText}
                            default={this.props.requestDetails.requestTypeName}
                            disabled={true}
                        />

                        <Textbox
                            name="requestType"
                            type="text"
                            label="Request Status"
                            update={this.updateText}
                            default={this.props.requestDetails.requestStatusName}
                            disabled={true}
                        />

                        {this.props.requestDetails.approvalComments &&
                            <Textbox
                                name="approvalComments"
                                type="text"
                                label="Approval Comments"
                                update={this.updateText}
                                default={this.props.requestDetails.approvalComments}
                                disabled={true}
                            />
                        }
                        <Textbox
                            name="spnName"
                            type="text"
                            label="SPN Name"
                            update={this.updateText}
                            default={this.props.requestDetails.spnName}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                        />

                        {this.state.spnApplicationId &&
                            <Textbox
                                name="spnApplicationId"
                                type="text"
                                label="SPN Application ID"
                                default={this.state.spnApplicationId}
                                disabled={true}
                            />
                        }
                        {this.state.spnObjectId &&
                            <Textbox
                                name="spnObjectId"
                                type="text"
                                label="SPN Object ID"
                                default={this.state.spnObjectId}
                                disabled={true}
                            />
                        }
                        {this.props.requestDetails.cherwellInformation &&
                            <Textbox
                                name="cherwellTicketNumber"
                                type="text"
                                label="Cherwell Ticket Number"
                                default={this.props.requestDetails.cherwellInformation.cherwellTicketNumber}
                                disabled={true}
                            />
                        }

                        <Textbox
                            name="creationDate"
                            type="text"
                            label="Creation Date"
                            default={new Date(this.props.requestDetails.creationDate).toLocaleDateString()}
                            disabled={true}
                        />

                        {this.props.requestDetails.requestStatus === 3 &&
                            <Textbox
                                name="completionDate"
                                type="text"
                                label="Completion Date"
                                default={new Date(this.props.requestDetails.completionDate).toLocaleDateString()}
                                disabled={true}
                            />
                        }

                        {(this.props.requestDetails.requestStatus == 3 || this.props.requestDetails.requestStatus == 1) && this.props.requestDetails.approver &&
                            <>
                                <Textbox
                                    name="approverName"
                                    type="text"
                                    label="Approver Name"
                                    default={this.props.requestDetails.approver.contactInfo.name}
                                    disabled={true}
                                />

                                <Textbox
                                    name="approverEmail"
                                    type="text"
                                    label="Approver Email"
                                    default={this.props.requestDetails.approver.contactInfo.email}
                                    disabled={true}
                                />
                            </>
                        }
                        <Textbox
                            name="requesterName"
                            type="text"
                            label="Requester Name"
                            default={this.props.requestDetails.requester.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}
                        />
                        <Textbox
                            name="requesterEmail"
                            type="email"
                            label="Requester Email"
                            default={this.props.requestDetails.requester.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterManagerName"
                            type="text"
                            label="Requester's Manager Name"
                            default={this.props.requestDetails.requester.manager.contactInfo.name}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}



                        />
                        <Textbox
                            name="requesterManagerEmail"
                            type="email"
                            label="Requester's Manager Email"
                            default={this.props.requestDetails.requester.manager.contactInfo.email}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        <Textbox
                            name="requesterTeamName"
                            type="text"
                            label="Requester's Team Name"
                            default={this.props.requestDetails.requester.groupName}
                            disabled={!this.props.editing}
                            editing={this.props.editing}
                            update={this.updateText}


                        />
                        {this.props.editing &&
                            <ChipButton
                                buttonClassName="save-button"
                                buttonText="Save"
                                action={this.saveRequest}
                                color='btn-success'
                            />
                        }
                    </div>

                }
            </div>
        );
    }
}