import React, { Component } from 'react';
import '../css/ApplicationDetails.css';
import pencilIcon from '../img/pencil-icon-black.svg';
import { ApplicationForm } from './ApplicationForm';
import { ApplicationCost } from './ApplicationCost';
import { NewRequestModal } from './request/NewRequestModal';
import { LoadingCloud } from './LoadingCloud';
import { RequestTable } from './request/RequestTable';
import { ResourceTable } from './request/ResourceTable';
import { ShadowedColumnCard } from './ShadowedColumnCard'
import { constructRequestListAndDict } from '../helpers/RequestHelper';
import { getAzureDevOpsProjects } from '../helpers/AzureDevOpsHelper';
import { ApplicationFormModal } from './ApplicationFormModal';
import { RequestDetailsModal } from './request/RequestDetailsModal'
import { fetch_retry } from '../helpers/FetchHelper';
import { auth } from '../adalConfig';
import { authenticatedApiFetch } from '../helpers/FetchHelper';
import { ErrorContext } from "../ErrorContext";
import { getGithubOrgs, getGithubRepoByOrgs } from '../helpers/GithubHelper';

export class ApplicationDetails extends Component {
    static displayName = "Application Details";
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            appID: this.props.match.params.appID,
            loading: true,
            submitting: false,
            error: false,
            applicationName: null,
            productName: null,
            description: null,
            teamName: null,
            teamEmail: null,
            productOwnerName: null,
            productOwnerEmail: null,
            pii: false,
            pci: false,
            disasterRecoveryPlan: false,
            escalation: false,
            solutionDesign: false,
            governance: false,
            security: false,
            vstsProjectName: null,
            vstsPortalUrl: "",
            tiers: null,
            cost: null,
            subscriptionCostDetails: null,
            isModalOpen: false,
            applicationFormData: {},
            isRequestOpen: false,
            isRequestDetailsModalOpen: false,
            isGithub: false,
            
        };
        this.toggleRequestModal = this.toggleRequestModal.bind(this);
        this.getApplicationRestCall = this.getApplicationRestCall.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.showRequestDetails = this.showRequestDetails.bind(this);
        this.toggleRequestDetailsModal = this.toggleRequestDetailsModal.bind(this);

    }

    toggleModal() {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }));
    }

    toggleRequestModal() {
        this.setState(prevState => ({
            isRequestOpen: !prevState.isRequestOpen
        }));
    }

    async updateVstsProject() {
        var vstsProjectList = await getAzureDevOpsProjects();
        this.setState({
            vstsProjectNames: vstsProjectList
        });
    }

    async updateGithubOrg() {
        var githubOrgList = await getGithubOrgs();
        this.setState({
            githubOrgNames: githubOrgList
        })
    }

    async updateRepo(org) {
        var githubRepos = await getGithubRepoByOrgs(org);
        this.setState({
            githubRepositories: githubRepos
        })
    }

    submit() {
        this.setState({
            submitting: true,
            error: false
        });
        authenticatedApiFetch('api/ApplicationInformation/UpdateApplicationInformation', {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.applicationFormData)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response);
            }
        }).then((data) => {
            this.toggleModal();
            this.getApplicationRestCall();
            this.setState({
                submitting: false
            });
            this.setState({
                appID: data.id,
            });
        }).catch((error) => {
            this.setState({
                submitting: false,
                error: true
            });
            this.context.handleError(error);
            throw error;
        });
    }

    updateState(name, value) {
        let updatedData = this.state.applicationFormData;
        switch (name) {
            case "productOwnerName":
                updatedData.productOwner.contactInfo.name = value;
                break;
            case "productOwnerEmail":
                updatedData.productOwner.contactInfo.email = value;
                break;
            case "githubOrganization":
                if (updatedData.github === null) {
                    updatedData.github = new Object();
                } 
                updatedData.github.organization = value;
                    this.updateRepo(value);
                
                break;
            case "githubRepository":
                if (updatedData.github === null) {
                    updatedData.github = new Object();
                } 
                updatedData.github.repository = value;
                break;
            case "isGithub":
                updatedData.isGithub = value;
                if (value == false) {
                    updatedData.github = null;
                }
                break;
            case "isAzureDevops":
                updatedData.isAzureDevops = value;
                if (value == false) {
                    updatedData.vstsInformation = null;
                }
                break;
            case "vstsProjectName":
                if (updatedData.vstsInformation === null) {
                    updatedData.vstsInformation = new Object();
                }
                updatedData.vstsInformation.vstsProjectName = value;
                updatedData.vstsInformation.vstsPortalUrl = `https://dev.azure.com/itsals/${value}`;
                this.setState({
                    "vstsPortalUrl": `https://dev.azure.com/itsals/${value}`
                });
                break;
            case "tiers":
                updatedData.tierTypes = [parseInt(value)];
                break;
            case "pii":
                this.updateClassification(1, value, updatedData.classifications);
                
                break;
            case "pci":
                this.updateClassification(2, value, updatedData.classifications);
                break;
            case "disasterRecoveryPlan":
            case "escalation":
            case "governance":
            case "security":
            case "solutionDesign":
                updatedData.checkList[name] = value;
                break;
            default:
                updatedData[name] = value;
                break;
        }


        this.setState({
            [name]: value,
            applicationFormData: updatedData
        });

    }

    updateClassification(value, isBeingAdded, classifications) {
        if (isBeingAdded && !classifications.includes(value)) {
            classifications.push(value);
        } else {
            classifications.pop(value);
        }

    }

    getApplicationRestCall() {
        fetch_retry('api/ApplicationInformation/GetApplicationInformationById?id=' + this.state.appID, {
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response);
            }
        }).then((data) => {
            let {
                applicationName,
                productName,
                description,
                productOwner: {
                    contactInfo: {
                        name: productOwnerName,
                        email: productOwnerEmail
                    }
                },
                teamEmail,
                teamName,
                cost,
                classifications,
                tierTypes,
                checkList: {
                    disasterRecoveryPlan,
                    escalation,
                    solutionDesign,
                    governance,
                    security
                },
                requestsCollection,
                subscriptionCostDetails
            } = data;
            let requestList = [];

            if (requestsCollection) {
                let {
                    createSpnRequests,
                    createStandaloneSpnRequests,
                    createNetworkFirewallRequests, 
                    createKubernetesRequests,
                    createResourcePermissionsRequests,
                    runADOPipelineRequests
                } = requestsCollection;

                if (createSpnRequests) {
                    requestList = requestList.concat(createSpnRequests);
                }

                if (createStandaloneSpnRequests) {
                    requestList = requestList.concat(createStandaloneSpnRequests);
                }

                if (createNetworkFirewallRequests) {
                    requestList = requestList.concat(createNetworkFirewallRequests);
                }
                if(createKubernetesRequests) {
                    requestList = requestList.concat(createKubernetesRequests);
                }
                if (createResourcePermissionsRequests) {
                    requestList = requestList.concat(createResourcePermissionsRequests)
                }

                if (runADOPipelineRequests) {
                    requestList = requestList.concat(runADOPipelineRequests);
                }
            }
            if (subscriptionCostDetails && subscriptionCostDetails.length > 0) {
                let [{
                    subscription: {
                        subscriptionName,
                        subscriptionId
                    },
                    environment,
                    cost: subscriptionCost,
                    resourceGroupCostDetails: [{
                        resourceGroup,
                        cost: resourceGroupCost
                    }]
                }] = subscriptionCostDetails;

            }

            var formattedRequests = constructRequestListAndDict(requestList);
            if (!tierTypes) {
                tierTypes = [];
            }
            let isAzureDevops = false;
            if (data.vstsInformation && data.vstsInformation.vstsProjectName) {
                isAzureDevops = true;
            }
            let isGithub = false;
            if (data.github) {
                if (data.github.organization && data.github.repository) {
                    isGithub = true;
                }
            }

            this.setState({
                loading: false,
                applicationName: applicationName,
                productName: productName,
                description: description,
                productOwnerName: productOwnerName,
                productOwnerEmail: productOwnerEmail,
                teamEmail: teamEmail,
                teamName: teamName,
                vstsProjectName: data.vstsInformation && data.vstsInformation.vstsProjectName,
                vstsPortalUrl: data.vstsInformation && data.vstsInformation.vstsPortalUrl,
                disasterRecoveryPlan: disasterRecoveryPlan,
                escalation: escalation,
                solutionDesign: solutionDesign,
                governance: governance,
                security: security,
                pii: classifications.includes(1),
                pci: classifications.includes(2),
                tiers: tierTypes.length > 0 ? tierTypes[0] : 0,
                cost: cost,
                applicationFormData: data,
                requestList: formattedRequests.formattedRequestList,
                provisioedResources: formattedRequests.provisionedResources,
                fetchedRequestDictionary: formattedRequests.formattedRequestDictionary,
                isGithub: isGithub,
                isAzureDevops: isAzureDevops,
                githubOrganization: data.github && data.github.organization,
                githubRepository: data.github && data.github.repository

            });
            if (data.github && data.github.organization) {
                this.updateRepo(data.github.organization);
            }
            this.forceUpdate();

        }).catch((error) => {
            this.setState({
                loading: false,
                error: true
            });
        });
    }

    componentDidMount() {
        auth.then((response) => {
            response.authContext.getUser(this.setContext);
            this.getApplicationRestCall();
            this.updateVstsProject();
            this.updateGithubOrg();
        });
        
    }


    setContext = (context) => {
        this.setState({ userContext: context }, this.setUserID);
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = null;
    }

    showRequestDetails(clickedRequestDetails) {
        this.setState({
            requestDetails: { ...clickedRequestDetails, applicationId: this.state.appID }
            }, () => {
                this.toggleRequestDetailsModal()

        });
    }

    toggleRequestDetailsModal() {
        this.setState(prevState => ({
            isRequestDetailsModalOpen: !prevState.isRequestDetailsModalOpen
        }));
    }


    render() {
       
        return (
            this.state.loading ?
                <div>
                    <LoadingCloud />
                </div>
                :
                <div className="body-container">
                    <div className="container">
                        <div className="row">
                            <ShadowedColumnCard
                                cardTitle="Application Details"
                                cardIcon={
                                    <a
                                        className="chip-header pencil-icon-header"
                                        onClick={() => this.toggleModal()}>
                                        <img alt='' src={pencilIcon} />
                                    </a>
                                }
                                cardContent={
                                    <ApplicationForm
                                        origin="details"
                                        appID={this.props.match.params.appID}
                                        isDisabled={true}
                                        formValues={this.state}
                                        updateState={(name, value) => this.updateState}
                                        vstsProjects={this.state.vstsProjectNames}
                                        githubOrg={this.state.githubOrgNames}
                                        
                                    />
                                }
                            />

                            <div className="col-md">

                                <div className="row">

                                    <ShadowedColumnCard
                                        cardTitle="Request"
                                        colWidth={12}
                                        cardContent={
                                            <RequestTable
                                                hideTeamAndAppName={true}
                                                requestList={this.state.requestList}
                                                toggleRequestModal={this.toggleRequestModal}
                                                fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                                showPagination={true}
                                                requestClickAction={this.showRequestDetails}
                                                isApplicationDetails={true}
                                                listClassName={'appdetails-request-table'}
                                            />}
                                    />

                                    <ShadowedColumnCard
                                        cardTitle="Provisioned Resources"
                                        colWidth={12}
                                        cardContent={
                                            <ResourceTable
                                                requestList={this.state.provisioedResources}
                                                showPagination={true}
                                                listClassName={'appdetails-request-table'}
                                            />}
                                    />

                                    <ShadowedColumnCard
                                        cardTitle="Cost"
                                        colWidth={12}
                                        cardContent={
                                            <ApplicationCost
                                                cost={this.state.cost}
                                                subscriptionCostDetails={this.state.applicationFormData.subscriptionCostDetails != null ?
                                                    this.state.applicationFormData.subscriptionCostDetails : []}
                                            />
                                        }
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <ApplicationFormModal
                        isOpen={this.state.isModalOpen}
                        toggleModal={this.toggleModal}
                        buttonAction={() => this.submit()}
                        modalClassName="modal-90w"
                        modalTitle="Edit Application Details"
                        formValues={this.state}
                        appID={this.state.appID}
                        updateState={(name, value) => this.updateState(name, value)}
                        vstsProjects={this.state.vstsProjectNames}
                        githubOrg={this.state.githubOrgNames}
                    />

                    <NewRequestModal
                        isRequestOpen={this.state.isRequestOpen}
                        toggleRequestModal={this.toggleRequestModal}
                        applicationFormData={this.state.applicationFormData}
                        getApplicationRestCall={this.getApplicationRestCall}
                        groupName={this.state.teamName}
                    />

                    <RequestDetailsModal
                        isRequestDetailsModalOpen={this.state.isRequestDetailsModalOpen}
                        toggleRequestDetailsModal={this.toggleRequestDetailsModal}
                        requestDetails={this.state.requestDetails && this.state.requestDetails}
                        fetchNewData={this.getApplicationRestCall}
                    />
                </div >
        );
    }
}

