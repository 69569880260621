import React, { Component } from 'react';
import { List } from '../List';
import { ChipButton } from '../ChipButton';
import { Link } from "react-router-dom";


export class RequestTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPagination: this.props.showPagination ? this.props.showPagination : "",
            buttonText: "More"
        };
        this.showAllRequest = this.showAllRequest.bind(this);
    }

    showAllRequest() {
        this.setState({
            showPagination: !this.state.showPagination,
            buttonText: this.state.showPagination ? "Less" : "More"
        });
    }

    render() {
        function LinkCellFormatter(cell, row) {
            return (<div><a target="_blank" rel="noopener noreferrer" href={"https://cherwell.alaskaair.com/CherwellClient/Access/Command/Queries.GoToRecord?BusObID=Incident&PublicID=" + cell}>{cell}</a></div>);
        }

        let columns = [
            {
                text: 'Request Type',
                dataField: 'requestType',
                sort: true
            },
            {
                text: 'Cherwell Number',
                dataField: 'cherwellTicketNumber',
                formatter: LinkCellFormatter,
                sort: true
            },
            {
                text: 'Request Status',
                dataField: 'requestStatus',
                sort: true
            }
        ];

        let getTrimmedName = (row) => {
            let appDetails = this.props.fetchedRequestDictionary[row.requestId]; 
            let trimmedName = (appDetails.applicationName.length > 15 ? `${appDetails.applicationName.substring(0, 15)}...` : appDetails.applicationName);
            return trimmedName;
        }

        let appNameFormatter = (cell, row) => {
            let appDetails = this.props.fetchedRequestDictionary[row.requestId]; 
            return (<Link to={`/ApplicationInformation/Details/${appDetails.applicationId}`}>{getTrimmedName(appDetails)}</Link>);
        }

        let completionDateFormatter = (cell, row) => {
            let appDetails = this.props.fetchedRequestDictionary[row.requestId];
            if (appDetails.completionDate) {
                let visibleDate = new Date(appDetails.completionDate).toLocaleDateString();
                return visibleDate == "1/1/1" ? "NA" : visibleDate
            }
        }

        if (!this.props.hideTeamAndAppName) {
            columns = columns.concat([{
                text: 'Application Name',
                dataField: 'applicationName',
                sort: true,
                formatter: appNameFormatter,
                filterValue: (cell, row) => getTrimmedName(row)
            }, {
                text: 'App Team Name',
                dataField: 'teamName',
                sort: true,
                formatter: nameFormatter
            }
            ]);

            if (this.props.showCompletedDate) {
                columns = columns.concat([{
                    text: 'Completion Date',
                    dataField: 'completionDate',
                    sort: true,
                    formatter: completionDateFormatter
                }]);
            }
        }

        function nameFormatter(cell) {
            return (cell.length > 15 ? `${cell.substring(0, 15)}...` : cell);
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                var clickedRequest = this.props.fetchedRequestDictionary[row.requestId];
                if (this.props.requestClickAction) {
                    this.props.requestClickAction(clickedRequest)
                } else {
                    console.log("no click listener passed.. please pass the 'requestClickAction' prop to enable this feature");

                }
            }
        };

        return (
            this.props.requestList != null
                ?
                <div className="container app-details-card app-details-request-table">
                    <div className="row">
                        <List
                            data={this.props.requestList}
                            columns={columns}
                            keyField='requestId'
                            hideSearch={this.props.hideSearch == null ? true : this.props.hideSearch}
                            showPagination={this.state.showPagination}
                            bordered={false}
                            sizePerPage={5}
                            hideSizePerPage={true}
                            hidePageListOnlyOnePage={true}
                            rowClasses="interactive-table-row"
                            rowEvents={rowEvents}
                            listClassName={this.props.listClassName}
                        />
                    </div>
                    {this.props.isApplicationDetails &&
                        <div className="row btn-margin">
                            <div className={"col " + (this.props.requestList.length > 5 ? 'more-detail-request-btn' : 'more-detail-request-btn-hidden')}>
                                <ChipButton
                                    color="secondary"
                                    action={this.showAllRequest}
                                    buttonText={this.state.buttonText}
                                />
                            </div>
                            <div className="col new-request-btn">
                                <ChipButton
                                    color="primary"
                                    action={() => this.props.toggleRequestModal()}
                                    buttonText="New Request"
                                />
                            </div>
                        </div>
                    }
                </div>
                :
                <div> No Requests! </div>
        );
    }
}