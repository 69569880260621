import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ChipButton } from './ChipButton';
import '../css/ChipModal.css';

export class ChipModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggle}
                    className={this.props.modalClassName}
                >

                    <ModalHeader
                        toggle={this.props.toggleModal}
                        className="chip-header"
                    >
                        {this.props.modalTitle}
                    </ModalHeader>
                    <ModalBody className={this.props.modalBodyClassName ? this.props.modalBodyClassName : ''}>
                        {this.props.modalBody}
                    </ModalBody>
                    {this.props.buttonAction && !this.props.submitting && 
                        <ModalFooter>
                            <ChipButton
                                disabled={this.props.disabled && this.props.disabled}
                                color={this.props.color ? this.props.color : 'primary'}
                                action={this.props.buttonAction ? this.props.buttonAction : this.props.toggleModal}
                                buttonText={this.props.buttonText ? this.props.buttonText : "Save"}
                            ></ChipButton>
                            {this.props.secondaryButton &&
                                <ChipButton
                                    disabled={this.props.secondaryButton.disabled && this.props.secondaryButton.disabled}
                                    color={this.props.secondaryButton.color ? this.props.secondaryButton.color : 'primary'}
                                    action={this.props.secondaryButton.action ? this.props.secondaryButton.action : this.props.toggleModal}
                                    buttonText={this.props.secondaryButton.text ? this.props.secondaryButton.text : "Cancel"}
                                ></ChipButton>
                            }
                        </ModalFooter>
                    }
                </Modal>
            </div>
        );
    }
}