import React, { Component } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import { Checkbox } from '../Checkbox';
import SubscriptionDropDown from './SubscriptionDropDown';

export class StandAloneSpn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicePrincipalName: null,
            environments: [],
        };

    }

    render() {
        let environment = {
            texts: ["Dev", "Test", "Qa", "Prod"],
            values:[1,2,3,4]
        };
        return (
            <div>
                <Textbox
                    name="servicePrincipalName"
                    type="text"
                    label="Service Principal Name"
                    tooltip="The name of your standalone SPN."
                    maxlength="24"
                    required="true"
                    errorMessage="Must not be empty and cannot contain the word SPN (it is auto-appended)."
                    update={this.props.updateText}
                    default={this.props.servicePrincipalName}
                    disabled={this.props.isDisabled}
                    editing={this.props.editing}
                    pattern={/^((?!(spn)).)*$/}
                />
                <Dropdown
                    name="selectedEnvironment"
                    label="Environment"
                    tooltip="the environment where the spn is being used in."
                    values={environment.values}
                    texts={environment.texts}
                    required="true"
                    errorMessage="Environment is required"
                    update={this.props.updateText}
                    default={this.props.environments}
                    disabled={false}
                    multiple={true}
                />
                {/*<div className="key-vault">*/}
                {/*    <Checkbox*/}
                {/*        name="isSecretUpdateEnabled"*/}
                {/*        label="Key Vault Secret Update"*/}
                {/*        default={false}*/}
                {/*        tooltip="After selecting 'Yes', you can select the key vault and secret name"*/}
                {/*        update={this.props.toggleSecretUpdate}*/}
                {/*        disabled={this.props.disabled}*/}
                {/*    />*/}
                {/*</div>*/}
                <div
                    className={` ${this.props.displayUpdateSecret ? '' : 'display-none'}`}
                >
                    <SubscriptionDropDown
                        subscriptions={this.props.subscriptions}
                        updateText={this.props.updateText}
                        disabled={this.props.disabled}
                        selectedSubscriptions={this.state.selectedSubscriptions}
                    />
                    <Textbox
                        name="secretUpdateKeyVaultName"
                        type="text"
                        label="Key Vault Name"
                        tooltip="The name of your Key vault"
                        maxlength="24"
                        required="true"
                        errorMessage="Must not be empty"
                        update={this.props.updateText}
                        default={this.props.servicePrincipalName}
                        disabled={this.props.isDisabled}
                        editing={this.props.editing}
                        pattern={/^((?!(spn)).)*$/}
                    />
                    <Textbox
                        name="secretUpdateSecretName"
                        type="text"
                        label="Secret Name"
                        tooltip="The name of your secret"
                        maxlength="24"
                        required="true"
                        errorMessage="Must not be empty"
                        update={this.props.updateText}
                        default={this.props.servicePrincipalName}
                        disabled={this.props.isDisabled}
                        editing={this.props.editing}
                        pattern={/^((?!(spn)).)*$/}
                    />
                </div>
            </div>
        );
    }
}