import React, { Component } from 'react';
import { Dropdown } from '../Dropdown';
import { Textbox } from '../Textbox';
import { Checkbox } from '../Checkbox';
import { KeyvaultNameInput } from './KeyvaultNameInput';
import RegionDropDown from './RegionDropDown';
import SubscriptionDropDown from './SubscriptionDropDown';


export class SpnPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region: null,
            aadGroups: [],
            subscriptions: this.props.selectedSubscriptions ? this.props.selectedSubscriptions : [],
            keyVaultName: null,
            keyvaultAdGroup: null,
            keyVaultErrorMessage: this.props.keyVaultErrorMessage,
            display:false
        };
    }

    componentWillUpdate(newProps) {
        
        if (this.props.keyVaultName != newProps.keyVaultName) {
            this.setState({ keyVaultName: newProps.keyVaultName });
        }
        if (this.props.region != newProps.region) {
            this.setState({ region: newProps.region });
        }

        if (this.state.selectedSubscriptions != newProps.selectedSubscriptions) {
            this.setState({ selectedSubscriptions: newProps.selectedSubscriptions});
        }
    }

    render() {
        return (
            <div>
                <SubscriptionDropDown
                    subscriptions={this.props.subscriptions}
                    updateText={this.props.updateText}
                    disabled={this.props.disabled}
                    selectedSubscriptions={this.state.selectedSubscriptions}

                />
                <RegionDropDown 
                    selectedSubscriptions={this.props.selectedSubscriptions}
                    region={this.props.region}
                    applicationName={this.props.applicationName}
                    updateText={this.props.updateText}
                    default={this.props.region}
                    disabled={this.props.disabled}
                />
                <div className="key-vault">
                    <Checkbox
                        name="isKeyVaultEnabled"
                        label="Key Vault"
                        default={false}
                        tooltip="After selecting 'Yes', key vault will be provisioned with this naming convention: 'ApplicationName-environment-kv'"
                        update={this.props.toggleKeyvault}
                        disabled={this.props.disabled}
                    />
                </div>
                <div
                    className={` ${this.props.displayKeyvault ? '' : 'display-none'}`}
                >
                    <KeyvaultNameInput
                        required={this.props.displayKeyvault}
                        updateText={this.props.updateText}
                        selectedSubscriptions={this.props.selectedSubscriptions}
                        keyvaultName={this.props.keyvaultName}
                    />

                </div>
                <div className="key-vault">
                    <Checkbox
                        name="isAppConfigEnabled"
                        label="App Config Service"
                        tooltip="This will provision an App Configuration Service with this naming convention: 'ApplicationName-Environment-acs'"
                        default={false}
                        update={this.props.toggleAppConfig}
                        disabled={this.props.disabled}
                    />
                </div>
                <div
                    className={` ${this.props.displayAppConfig ? '' : 'display-none'}`}
                >
                    <Textbox
                        name="appConfigName"
                        type="text"
                        label="App Config Service Name"
                        tooltip="The App Config Service name will be the application name concat with -{environment}-acs. Max character for App Config Service name can only be 24"
                        maxlength="12"
                        required={this.props.required}
                        errorMessage={"Must not be empty."}
                        update={this.props.updateText}
                        default={this.props.appConfigName}
                        disabled={this.props.disabled}
                    />
                    
                </div>
                <div
                    className={` ${(this.props.displayAppConfig || this.props.displayKeyvault) ? '' : 'display-none'}`}
                >
                    <Dropdown
                        name="resourceAdGroup"
                        label="Resource Permission"
                        tooltip="The active directory group that will be granted to either key vault or app config service."
                        values={this.props.aadGroups}
                        texts={this.props.aadGroups}
                        required={this.props.displayAdGroup}
                        errorMessage="Active directory group is required"
                        update={this.props.updateText}
                        disabled={this.props.disabled}
                        />
                </div>
            </div>
        );
    }
}
