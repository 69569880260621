import React, { Component } from 'react';
import { RequestTable } from './RequestTable';
import { ShadowedColumnCard } from '../ShadowedColumnCard'
import { constructRequestListAndDictFromNested } from '../../helpers/RequestHelper';
import { RequestDetailsModal } from './RequestDetailsModal'
import { LoadingCloud } from '../LoadingCloud';
import '../../css/Requests.css';
import { auth } from '../../adalConfig';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { ErrorContext } from "../../ErrorContext";

export class AllRequests extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            newRequestList: [],
            approvedRequestList: [],
            failedRequestList: [],
            submittedRequestList: [],
            fetchedRequestDictionary: {},
            isRequestDetailsModalOpen: false,
            requestDetails: null,
            fetching: true,
            userContext: null,
        };

        this.fetchNewData = this.fetchNewData.bind(this);
        this.toggleRequestDetailsModal = this.toggleRequestDetailsModal.bind(this);
        this.showRequestDetails = this.showRequestDetails.bind(this);
        this.setContext = this.setContext.bind(this);
    }

    componentDidMount() {

        auth.then((response) => {
            response.authContext.getUser(this.setContext);

            try {
                this.setState({ fetchInterval: setInterval(this.fetchAllRequests, 5000) });
            } catch (e) {
                console.log(e);
            }
            this.fetchNewData();
        });

       
    }

    setContext(err, context) {
        this.setState({ userContext: context });
    }

    componentWillUnmount() {
        clearInterval(this.state.fetchInterval);
    }

    async fetchNewData() {
        try {
            const allRequestsResponse = await authenticatedApiFetch(`/api/Request/GetAllRequests`, {});
            const allRequests = await allRequestsResponse.json();
            var constructedLists = constructRequestListAndDictFromNested(allRequests);
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }

        this.setState({
            newRequestList: constructedLists.newRequestList,
            approvedRequestList: constructedLists.approvedCompletedRequestList,
            submittedRequestList: constructedLists.submittedRequestList,
            failedRequestList: constructedLists.failedRequestList,
            rejectedRequestList: constructedLists.rejectedList,
            fetchedRequestDictionary: constructedLists.formattedRequestDictionary,
            fetching: false
        });
    }

    toggleRequestDetailsModal() {
        this.setState(prevState => ({
            isRequestDetailsModalOpen: !prevState.isRequestDetailsModalOpen
        }));
    }

    showRequestDetails(clickedRequestDetails) {
        this.setState({ requestDetails: clickedRequestDetails }, () => {
            this.toggleRequestDetailsModal();
        });
    }

    render() {
        return (
            this.state.fetching ?
                <LoadingCloud />
                :
                <div>
                    {this.state.userContext && this.state.userContext.profile.roles && this.state.userContext.profile.roles.includes("SuperUser") &&

                        <div className="request-page-container">

                            <h1 className="request-page-title"> Requests: </h1>
                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="New"
                                    colWidth={12}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.newRequestList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.newRequestList.length > 5}
                                            listClassName="allrequests-table"
                                        />
                                    }
                                />
                            </div>
                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="Approved/Completed"
                                    colWidth={6}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.approvedRequestList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.approvedRequestList.length > 5}
                                            listClassName="allrequests-table"
                                            hideSearch={this.state.approvedRequestList.length === 0}
                                            showCompletedDate={true}
                                        />
                                    }
                                />
                                <ShadowedColumnCard
                                    cardTitle="Failed"
                                    colWidth={6}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.failedRequestList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.failedRequestList.length > 5}
                                            listClassName="allrequests-table"

                                        />
                                    }
                                />
                            </div>


                            <div className="row">
                                <ShadowedColumnCard
                                    cardTitle="Rejected"
                                    colWidth={12}
                                    cardContent={
                                        <RequestTable
                                            requestList={this.state.rejectedRequestList}
                                            fetchedRequestDictionary={this.state.fetchedRequestDictionary}
                                            requestClickAction={this.showRequestDetails}
                                            showPagination={this.state.rejectedRequestList.length > 5}
                                            listClassName="allrequests-table"
                                        />
                                    }
                                />

                            </div>

                            <RequestDetailsModal
                                isRequestDetailsModalOpen={this.state.isRequestDetailsModalOpen}
                                toggleRequestDetailsModal={this.toggleRequestDetailsModal}
                                requestDetails={this.state.requestDetails && this.state.requestDetails}
                                fetchNewData={this.fetchNewData}
                            />
                        </div>
                    }
                </div>
        );
    }
}