import React, { Component } from 'react';
import { ChipModal } from '../ChipModal';
import { CreateSpnPlusDetailsContent } from './CreateSpnPlusDetailsContent';
import { CreateStandaloneSpnDetailsContent } from './CreateStandaloneSpnDetailsContent';
import { NetworkFirewallContent } from './NetworkFirewallContent';
import { ResourcePermissionsContent } from './ResourcePermissionsContent'
import { RunADOPipelineContent } from './RunADOPipelineContent';
import pencilIcon from '../../img/pencil-icon-black.svg';
import cancelIcon from '../../img/delete.svg';
import { Textbox } from '../Textbox';
import { auth } from '../../adalConfig';
import { KubernetesDetailsContent } from './KubernetesDetailsContent';
import { authenticatedApiFetch } from '../../helpers/FetchHelper';
import { LoadingCloud } from '../LoadingCloud'
import { ErrorContext } from "../../ErrorContext";

export class RequestDetailsModal extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            approver: null,
            employeeId: null,
            approvalComments: null,
            submitting: false,
        };

        this.approveRequest = this.approveRequest.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.setContext = this.setContext.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setApprovalComments = this.setApprovalComments.bind(this);
    }

    componentDidMount() {
        auth.then((response) => {
            this.setState({ token: response.getToken() });
            response.authContext.getUser(this.setContext);
            this.getAdGroupsCall();
        });
    }

    setApprover() {
        this.setState({
            approver: {
                "contactInfo": {
                    "name": this.state.userContext.profile.name,
                    "employeeId": this.state.employeeId,
                    "email": this.state.userContext.profile.upn
                },
                "IsApproved": true
            }
        });
    }

    getAdGroupsCall() {
        authenticatedApiFetch("api/Auth/GetAzureAccessAdGroups", {})
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            }).then((data) => {
                let adGroupArray = [];
                data.map((adgroup) => adGroupArray.push(adgroup.displayName));
                this.setState({
                    aadGroups: adGroupArray
                });
            }).catch((err) => {
                this.context.handleError(err);
                throw err;
            });
    }

    setContext(err, context) {
        this.setState({
            userContext: context
        }, () => { this.setUserID(); });
    }

    setUserID() {
        try {
            authenticatedApiFetch('api/Auth/GetUserIdentity?objectId=' + this.state.userContext.profile.oid, {})
                .then(userResponse => userResponse.json())
                .then(user => this.setState({
                    employeeId: user.employeeId,
                }, () => { this.setApprover() }));

            authenticatedApiFetch('api/Auth/GetUserManager?objectId=' + this.state.userContext.profile.oid, {})
                .then(managerResponse => managerResponse.json())
                .then(manager => this.setState({
                    managerId: manager ? manager.employeeId : null,
                    requesterManagerName: manager ? manager.displayName : null,
                    requesterManagerEmail: manager ? manager.mail : null
                }));
        } catch (err) {
            this.context.handleError(err);
            throw err;
        }
    }

    approveRequest(requestType) {

        let updateRequest = {
            applicationId: this.props.requestDetails.applicationId
        };

        var requestEndpoint = '';
        switch (requestType) {
            case 1: {
                updateRequest.createSpnRequest = this.props.requestDetails;
                updateRequest.createSpnRequest.approver = this.state.approver;
                requestEndpoint = 'ApproveSpnPlusRequest';
                break;
            }
            case 2: {
                updateRequest.createStandaloneSpnRequest = this.props.requestDetails;
                updateRequest.createStandaloneSpnRequest.approver = this.state.approver;
                requestEndpoint = 'ApproveStandaloneSpnRequest';
                break;
            }
            case 3: {
                updateRequest.createNetworkFirewallRequest = this.props.requestDetails;
                updateRequest.createNetworkFirewallRequest.approver = this.state.approver;
                updateRequest.createNetworkFirewallRequest.approvalComments = this.state.approvalComments;
                requestEndpoint = 'ApproveNetworkFirewallRequest';
                break;
            }
            case 4: {
                updateRequest.createKubernetesRequest = this.props.requestDetails;
                updateRequest.createKubernetesRequest.approver = this.state.approver;
                requestEndpoint = 'ApproveKubernetesRequest';
                break;
            }
            case 5: {
                updateRequest.createResourcePermissionsRequest = this.props.requestDetails;
                updateRequest.createResourcePermissionsRequest.approver = this.state.approver;
                requestEndpoint = 'ApproveResourcePermissionsRequest';
                break;
            }
            default: {
                break;
            }
        }

        this.setState({ submitting: true });
        this.callApiWithRequestEndpoint(updateRequest, requestEndpoint);
    }

    rejectRequest(requestType) {

        let updateRequest = {
            applicationId: this.props.requestDetails.applicationId
        };

        var requestEndpoint = '';
        switch (requestType) {
            case 1:
            case 2: {
                updateRequest.requestId = this.props.requestDetails.requestId;
                updateRequest.approvalComments = this.state.approvalComments;
                requestEndpoint = 'RejectSpnRequest';
                break;
            }
            case 3: {
                updateRequest.createNetworkFirewallRequest = this.props.requestDetails;
                updateRequest.createNetworkFirewallRequest.approver = this.state.approver;
                updateRequest.createNetworkFirewallRequest.approvalComments = this.state.approvalComments;
                requestEndpoint = 'RejectNetworkFirewallRequest';
                break;
            }
            default: {
                break;
            }
        }

        this.setState({ submitting: true });
        this.callApiWithRequestEndpoint(updateRequest, requestEndpoint);


    }

    updateRequest(updatedRequest) {

        let updateRequest = {
            applicationId: this.props.requestDetails.applicationId,
        };

        var requestEndpoint = '';
        switch (this.props.requestDetails.requestTypes) {
            case 1: {
                updateRequest.createSpnRequest = updatedRequest;
                requestEndpoint = 'UpdateSpnPlusRequest';
                break;
            }
            case 2: {
                updateRequest.createStandaloneSpnRequest = updatedRequest;
                requestEndpoint = 'UpdateStandaloneSpnRequest';
                break;
            }
            case 3: {
                updateRequest.createNetworkFirewallRequest = updatedRequest;
                requestEndpoint = 'UpdateNetworkFirewallRequest';
                break;
            }
            case 4: {
                updateRequest.createKubernetesRequest = updatedRequest;
                requestEndpoint = 'updatekubernetesRequest';
                break;
            }
            case 5: {
                updateRequest.createResourcePermissionsRequest = updatedRequest;
                requestEndpoint = 'UpdateResourcePermissionsRequest'
                break;
            }
            case 6: {
                updateRequest.runADOPipelineRequest = updatedRequest;
                requestEndpoint = 'UpdateRunADOPipelineRequest'
                break;
            }
            default: {
                break;
            }
        }

        this.setState({ submitting: true });
        this.callApiWithRequestEndpoint(updateRequest, requestEndpoint);

    }

    callApiWithRequestEndpoint(updateRequest, requestEndpoint) {
        authenticatedApiFetch(`api/Request/${requestEndpoint}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateRequest)
        }).then((response) => {
            
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response);
            }
        }).then((data) => {
            this.props.toggleRequestDetailsModal();
            this.props.fetchNewData();
            this.setState({
                submitting: false
            });
        }).catch((error) => {
            this.setState({
                submitting: false,
                error: true
            });
            this.context.handleError(error);
            throw error;
        });
    }

    toggleEdit() {
        this.setState({ editing: !this.state.editing });
    }

    setApprovalComments(name, comment) {
        let approverName = this.state.userContext ? this.state.userContext.profile.name : null;
        let detailedComment = approverName ? `${approverName}: ${comment}` : comment;
        this.setState({ [name]: detailedComment });
    }

    render() {

        let superUserCheck = this.props.requestDetails
            && (this.props.requestDetails.requestStatus === 0 || this.props.requestDetails.requestStatus === 2)
            && this.state.userContext
            && this.state.userContext.profile.roles
            && this.state.userContext.profile.roles.includes("SuperUser");

        let networkAdminCheck = this.props.requestDetails
            && (this.props.requestDetails.requestStatus === 5 || this.props.requestDetails.requestStatus === 2)
            && this.state.userContext
            && this.state.userContext.profile.roles
            && (this.state.userContext.profile.roles.includes("NetworkAdmin") || this.state.userContext.profile.roles.includes("SuperUser"));

        let securityAdminCheck = this.props.requestDetails
            && this.props.requestDetails.requestStatus === 6
            && this.state.userContext
            && this.state.userContext.profile.roles
            && (this.state.userContext.profile.roles.includes("SecurityAdmin") || this.state.userContext.profile.roles.includes("SuperUser"));

        let approvalButtonAction = this.props.requestDetails
            && (superUserCheck || networkAdminCheck || securityAdminCheck)
            && !this.state.editing
            && (() => this.approveRequest(this.props.requestDetails.requestTypes));

        let rejectionButton = (this.props.requestDetails &&
            (this.props.requestDetails.requestTypes === 1 ||
                this.props.requestDetails.requestTypes === 2 ||
                this.props.requestDetails.requestTypes === 3) && approvalButtonAction) ? {
            text: "Reject",
            color: "red",
            disabled: this.state.approvalComments == `${this.state.userContext.profile.name}: `,
            action: () => this.rejectRequest(this.props.requestDetails.requestTypes)
        } : null;

        if (this.props.requestDetails) {
            return (
                <ChipModal
                    isOpen={this.props.isRequestDetailsModalOpen}
                    modalClassName="modal-90w"
                    modalTitle={
                        <div>
                            <h5 className="modal-title request-details-modal-title">Request Details</h5>
                            {(this.props.requestDetails.requestStatus === 0
                                || this.props.requestDetails.requestStatus === 4
                                || this.props.requestDetails.requestStatus === 5
                                || this.props.requestDetails.requestStatus === 2) &&
                                (!this.state.editing ?
                                    < a
                                        className="chip-header header-icon"
                                        onClick={this.toggleEdit}>
                                        <img alt='' src={pencilIcon} />
                                    </a>
                                    :
                                    < a
                                        className="chip-header header-icon "
                                        onClick={this.toggleEdit}>
                                        <img alt='' src={cancelIcon} />
                                    </a>
                                )
                            }
                        </ div>}
                    buttonText="Approve"
                    submitting={this.state.submitting}
                    buttonAction={approvalButtonAction}
                    toggleModal={() => {
                        if (this.props.isRequestDetailsModalOpen && this.state.editing) {
                            this.toggleEdit();
                        }
                        this.props.toggleRequestDetailsModal();

                    }}
                    modalBody={
                        this.state.submitting ?
                            <LoadingCloud insideModal={true} />
                            :
                            <div>
                                {this.props.requestDetails.requestTypes === 1 &&
                                    <CreateSpnPlusDetailsContent
                                        requestDetails={this.props.requestDetails}
                                        aadGroups={this.state.aadGroups}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest}
                                    />}

                                {this.props.requestDetails.requestTypes === 2 &&
                                    <CreateStandaloneSpnDetailsContent
                                        requestDetails={this.props.requestDetails}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest}
                                    />}

                                {this.props.requestDetails.requestTypes === 3 &&
                                    <NetworkFirewallContent
                                        requestDetails={this.props.requestDetails}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest}
                                    />
                                }

                                {this.props.requestDetails.requestTypes == 5 &&
                                    <ResourcePermissionsContent
                                        requestDetails={this.props.requestDetails}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest}
                                    />
                                }

                                {this.props.requestDetails.requestTypes == 6 &&
                                    <RunADOPipelineContent
                                        requestDetails={this.props.requestDetails}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest}
                                    />
                                }
                                {(superUserCheck || networkAdminCheck || securityAdminCheck) && rejectionButton && !this.state.editing &&

                                    <div className="approval-comment-container">
                                        <Textbox
                                            name="approvalComments"
                                            type="text"
                                            label="Approval/Rejection Comments"
                                            tooltip="Comments or concerns related to the approval of this request."
                                            maxlength="300"
                                            required="false"
                                            errorMessage="Must be less than 300 characters."
                                            update={this.setApprovalComments}
                                        />
                                    </div>
                                }

                                {this.props.requestDetails.requestTypes === 4 &&
                                    <KubernetesDetailsContent requestDetails={this.props.requestDetails}
                                        editing={this.state.editing}
                                        updateRequest={this.updateRequest} />
                                }

                            </div>
                    }
                    secondaryButton={rejectionButton}

                />
            );
        } else {
            return (<div></div>)
        }
    }
}