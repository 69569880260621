import React, { Component } from 'react';
import '../css/Home.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
                <div className="home-body">
                    <h1 className="home-title">Cloud Hub Information Portal</h1>
                </div>
            )
    }
}
