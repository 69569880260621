import React, { Component } from 'react';
import { Textbox } from './Textbox';
import { Dropdown } from './Dropdown';
import { Checkbox } from './Checkbox';
import { getGithubRepoByOrgs } from '../helpers/GithubHelper';



export class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.updateText = this.updateText.bind(this);
        this.state = {
            appID: this.props.appID,
            loading: true,
            submitting: false,
            error: false,
            isModalOpen: false,
        };
    }

    setStateToPropsFormValues() {
        this.setState({
            applicationName: this.props.formValues.applicationName,
            productName: this.props.formValues.productName,
            description: this.props.formValues.description,
            teamName: this.props.formValues.teamName,
            teamEmail: this.props.formValues.teamEmail,
            productOwnerName: this.props.formValues.productOwnerName,
            productOwnerEmail: this.props.formValues.productOwnerEmail,
            pii: this.props.formValues.pii,
            pci: this.props.formValues.pci,
            disasterRecoveryPlan: this.props.formValues.disasterRecoveryPlan,
            escalation: this.props.formValues.escalation,
            solutionDesign: this.props.formValues.solutionDesign,
            governance: this.props.formValues.governance,
            security: this.props.formValues.security,
            vstsProjectName: this.props.formValues.vstsProjectName,
            vstsPortalUrl: this.props.formValues.vstsPortalUrl,
            vstsProjectNames: this.props.vstsProjects,
            githubRepository: this.props.formValues.githubRepository,
            githubRepositories: this.props.formValues.githubRepositories,
            tiers: this.props.formValues.tiers,
            isAzureDevops: this.props.formValues.isAzureDevops,
            isGithub: this.props.formValues.isGithub

        });
    }

    updateText(name, value) {
        this.setState({
            [name]: value
        });
        if (name === 'vstsProjectName') {
            this.setState({
                vstsPortalUrl: `https://dev.azure.com/itsals/${value}`
            });
        }
        if (name == 'githubOrganization') {
            this.updateRepo(value);
        }

        this.props.updateState(name, value);
    }

    async updateRepo(org) {
        var githubRepos = await getGithubRepoByOrgs(org);
        this.setState({
            githubRepositories: githubRepos
        })
    }

    componentDidMount() {
        this.setStateToPropsFormValues();
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.formValues !== prevProps.formValues) {
            this.setStateToPropsFormValues();
        }

        if (this.props.vstsProjects !== prevProps.vstsProjects) {

            this.setState({
                vstsProjectNames: this.props.vstsProjects,
            });
        }
    }

    render() {
        let tiers = {
            texts: [
                'Tier 0 - Critical Systems like middleware that supports the Tier 1 and 2 systems.',
                'Tier 1 - Critical Systems with immediate (< 1 hour) impact and escalating downstream operation impact.',
                'Tier 2 - Critical Systems with non-immediate impact (> 1 hour to 4 hours) with escalating impact over 24 hours.',
                'Tier 3 - Non-Critical Systems.'
            ],
            values: [
                0,
                1,
                2,
                3
            ]
        };
        return (
          <div className="app-details-card">
            <Textbox
              name="applicationName"
              type="text"
              label="Application Name"
              tooltip="The name of the application"
              maxlength="50"
              pattern="^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$"
              required="true"
              errorMessage="Must not be empty, and only letters and/or numbers and less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.applicationName}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="productName"
              type="text"
              label="Product Name"
              tooltip="The product name of the application"
              maxlength="50"
              required="true"
              errorMessage="Must not be empty, and less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.productName}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="description"
              type="text"
              label="Description"
              tooltip="The description and purpose of the application"
              maxlength="250"
              required="true"
              errorMessage="Must not be empty, and less than 250 characters."
              update={this.updateText}
              default={this.props.formValues.description}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="productOwnerName"
              type="text"
              label="Product Owner's Name"
              tooltip="The product owner of the application"
              maxlength="50"
              required="true"
              errorMessage="Must not be empty, and less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.productOwnerName}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="productOwnerEmail"
              type="email"
              label="Product Owner's Email"
              tooltip="The product owner's email address"
              maxlength="50"
              required="true"
              errorMessage="Must not be empty, and a valid email address less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.productOwnerEmail}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="teamName"
              type="text"
              label="Team Name"
              tooltip="The name of the team that owns/manages the application"
              maxlength="50"
              required="true"
              errorMessage="Must not be empty, and less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.teamName}
              disabled={this.props.isDisabled}
            />
            <Textbox
              name="teamEmail"
              type="email"
              label="Team Email"
              tooltip="The email address of the team that owns/manages the application"
              maxlength="50"
              required="true"
              errorMessage="Must not be empty, and a valid email address less than 50 characters."
              update={this.updateText}
              default={this.props.formValues.teamEmail}
              disabled={this.props.isDisabled}
            />
            <h6 className="new-application-checkbox-header">
              Deployment Pipeline:
            </h6>

            <div className="container new-application-checkbox-container">
              <div className="row">
                <div className="col-md">
                  <Checkbox
                    name="isAzureDevops"
                    label="Azure DevOps"
                    default={this.props.formValues.isAzureDevops}
                    tooltip="Select if deployment pipeline is located in Azure DevOps"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
                <div className="col-md">
                  <Checkbox
                    name="isGithub"
                    label="GitHub"
                    default={this.props.formValues.isGithub}
                    tooltip="Select if deployment pipeline is located in GitHub"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
              </div>
            </div>

            {this.state.isAzureDevops && (
              <>
                <Dropdown
                  name="vstsProjectName"
                  label="Azure DevOps Project Name"
                  tooltip="The Azure DevOps project name. For example AlaskAirCom, AirportCustomerExperience"
                  values={
                    this.props.vstsProjects ? this.props.vstsProjects : ""
                  }
                  texts={this.props.vstsProjects}
                  errorMessage="Azure DevOps project name is required"
                  update={this.updateText}
                  default={this.props.formValues.vstsProjectName}
                  disabled={this.props.isDisabled}
                />
                <Textbox
                  name="vstsPortalUrl"
                  type="url"
                  disabled={true}
                  label="Azure DevOps Portal URL"
                  tooltip="Azure DevOps Portal URL"
                  maxlength="250"
                  errorMessage="Must not be empty, and a valid URL less than 250 characters."
                  update={this.updateText}
                  default={this.state.vstsPortalUrl}
                />
              </>
            )}
            {this.state.isGithub && (
              <>
                <Dropdown
                  name="githubOrganization"
                  label="GitHub Organization"
                  tooltip="GitHub Organization your repository is under."
                  values={this.props.githubOrg ? this.props.githubOrg : ""}
                  texts={this.props.githubOrg ? this.props.githubOrg : ""}
                  disabled={this.props.isDisabled}
                  errorMessage="GitHub Organization name is required"
                  update={this.updateText}
                  default={this.props.formValues.githubOrganization}
                />
                <Dropdown
                  name="githubRepository"
                  label="GitHub Repository"
                  tooltip="The repository associated with your GitHub Actions deployment pipeline"
                  values={this.state.githubRepositories}
                  texts={this.state.githubRepositories}
                  disabled={this.props.isDisabled}
                  errorMessage="GitHub Repository name is required"
                  update={this.updateText}
                  default={this.state.githubRepository}
                />
              </>
            )}
            <Dropdown
              name="tiers"
              label="Tiers"
              tooltip="For more information please review our Tier Docs"
              values={tiers.values}
              texts={tiers.texts}
              required="true"
              errorMessage="Tier is required"
              update={this.updateText}
              default={this.props.formValues.tiers}
              disabled={this.props.isDisabled}
            />

            <h6 className="new-application-checkbox-header">Data Profile:</h6>

            <div className="container new-application-checkbox-container">
              <div className="row">
                <div className="col-md">
                  <Checkbox
                    name="pii"
                    label="PII"
                    default={this.props.formValues.pii}
                    tooltip="Select if application has PII (Personally Identifiable Information) data"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
                <div className="col-md">
                  <Checkbox
                    name="pci"
                    label="PCI"
                    default={this.props.formValues.pci}
                    tooltip="Select if application has PCI (Payment Card) data"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
              </div>
            </div>

            <h6 className="new-application-checkbox-header">
              Select the documents you will provide before going to production:
            </h6>

            <div className="container new-application-checkbox-container">
              <div className="row">
                <div className="col-md">
                  <Checkbox
                    name="disasterRecoveryPlan"
                    label="Disaster Recovery Plan"
                    default={this.props.formValues.disasterRecoveryPlan}
                    tooltip="A documented process, or set of procedures, to recover and protect a business IT infrastructure in the event of a disaster"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                  <Checkbox
                    name="escalation"
                    label="Escalation"
                    default={this.props.formValues.escalation}
                    tooltip="A set of procedures set in place to deal with potential problems in a variety of contexts"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                  <Checkbox
                    name="governance"
                    label="Governance"
                    default={this.props.formValues.governance}
                    tooltip="Follow Alaska naming and tagging standards"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
                <div className="col-md">
                  <Checkbox
                    name="security"
                    label="Security Design"
                    default={this.props.formValues.security}
                    tooltip="Follow the security guidelines for cloud resources"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                  <Checkbox
                    name="solutionDesign"
                    label="Solution Design"
                    default={this.props.formValues.solutionDesign}
                    tooltip="High level overview diagram of the application's architecture"
                    update={this.updateText}
                    disabled={this.props.isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        );

    }
}