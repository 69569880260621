import React, { Component, useState } from 'react';
import { Container, Badge } from 'reactstrap';
import NavMenu from './NavMenu';
import { AlertBanner } from "./AlertBanner";
import { ChipButton } from "./ChipButton";

export function Layout(props) {

    const errorBanner = <div>
        <h4 className="alert-heading">Uh oh! An error occured:</h4>
        <p>
            <Badge color="danger">Error</Badge> {props.error.message}
        </p>
        <p className="error-disclaimer">
            We apologize for the inconvience, this error has been logged and reported. If you would like to provide further details, please contact us at cloudcoreteam@alaskaair.com. Thank you!
        </p>
        <hr />
        <div className="error-buttons-container">
            <ChipButton
                buttonClassName="error-reload-btn"
                color={"primary"}
                action={() => window.location.reload(false)}
                buttonText={"Reload Page"}
            ></ChipButton>
        </div>
    </div>

    return (
        <div>
            <AlertBanner show={props.error} body={errorBanner} />
            <NavMenu />
            <Container>
                {props.children}
            </Container>
        </div>
    );
}
